import {
  IDraftNft,
  useLazyGetDrafAPIQuery
} from 'actions/app/get-draft-nft'
import { MintGateways } from 'actions/enum/gateway-types'
import GlobalHelmetProvider from 'components/GlobalHelmetProvider'
import MintBlockchainOptionsList from 'components/mintNft/MintBlockchainOptionsList'
import NftMediaWrapperCard from 'components/mintNft/NftMediaWrapperCard'
import ChoosePaymentOptions from 'components/payment/ChoosePaymentOptions'
import { useNFTCompressionProgress } from 'hooks'
import useTheme, { THEMETYPES } from 'hooks/useTheme'
import { TransactionInProgress } from 'pages/MintNFTPaymentStatusPage'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import {
  changeStateToRenderComp,
  storeCompressedFileSockerResponse
} from 'store/createNFT/action'
import { IRootReducer } from 'store/root-reducer'
import {
  checkIfRequestIsForSocketListening,
  niceTryBrother,
  toastError
} from 'utils/functions/commonFunctions'
import '../../styles/components/payment.scss'
import '../../styles/pages/createflow.scss'
import NFTLoadingIndicatorCard from '../createNFT/NFTLoadingIndicatorCard'
import MintNftForInvestorCustomUser from './MintNftForInvestorCustomUser'

export const MINT_STEPS = {
  SELECT_BLOCKCHAIN: 1,
  PAYMENT_OPTIONS: 2,
  TRANSACTION_IN_PROGRESS: 3,
  MINT_NFT_DIRECTLY: 4
}

function useForceUpdate() {
  let [value, setState] = useState(true)
  return () => setState(!value)
}

export const MintNftPage = () => {
  let rerenderdForcelly = useForceUpdate()
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()
  const dispatch = useDispatch()

  const { user } = useSelector(
    (state: IRootReducer) => state.userDetailsReducer
  )
  const { deviceId } = useSelector(
    (state: IRootReducer) => state.userDetailsReducer
  )

  const [getDraftNftAPIQuery] = useLazyGetDrafAPIQuery()

  const [mintType, setMintType] = useState(
    MINT_STEPS.SELECT_BLOCKCHAIN
  )
  const [nftData, setNFTData] = useState<IDraftNft>()
  const [isCustomUser, setisCustomUser] = useState(false)
  const [blockChainGateway, setBlockChainGateway] = useState(
    MintGateways.EDEXA
  )

  useEffect(() => {
    dispatch(
      storeCompressedFileSockerResponse(
        nftData?.inProcess?.compressedFile?.key
      )
    )
  }, [nftData?.inProcess?.compressedFile?.key, dispatch])

  useTheme(THEMETYPES.LIGHT, [])

  useEffect(() => {
    if (user?.isCustomUser) {
      setisCustomUser(user.isCustomUser)
    }
  }, [user])

  useEffect(() => {
    getDraftNftData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId])

  const getDraftNftData = () => {
    if (params?.id && deviceId) {
      getDraftNftAPIQuery({ id: params.id }).then((res) => {
        if ('data' in res && res.data && res.data.status === 200) {
          if (res.data.data.walletAddress) setNFTData(res?.data.data)
          else {
            niceTryBrother()
            navigate('/404')
          }
        }
        if ('error' in res && res.error) {
          toastError(
            (res.error as { data: { message: string } })?.data.message
          )
          if ((res.error as { status: number }).status === 404) {
            navigate('/404')
          }
        }
      })
    }
  }
  const [rerenderComponenet, setRerenderComponenet] = useState(true)
  const compressedPercentage = useSelector(
    (state: IRootReducer) => state.createNFT.percentage
  )
  const compressedFileURL = useSelector(
    (state: IRootReducer) =>
      state.normalFileAndCompressedFileReducer.newUrlOfFile
  )
  /* 
  useEffect(() => {
    if (compressedPercentage === "100%") {
      debugger;
      // window.location.reload();
      setRerenderComponenet(!rerenderComponenet);
      rerenderdForcelly();
    }
  }, [compressedPercentage, compressedFileURL]); */

  // Component

  // let timer: any;

  /*   useEffect(() => {
    if (nftData?.fileType === "image" && first === true) {
      setTimeout(() => {
        setanyState(!anyState);
        rerenderdForcelly();
        window.location.reload();
        setFirst(false);
      }, 5000);
    }
  }, []); */

  useEffect(() => {
    return () => {
      dispatch(changeStateToRenderComp(false))
    }
  }, [])
  useNFTCompressionProgress(
    nftData?.inProcess?.compressedFile?.key || ''
  )
  return (
    <>
      <GlobalHelmetProvider title="Create Mint" />
      <p style={{ display: 'none' }}>{rerenderComponenet}</p>
      {mintType !== MINT_STEPS.TRANSACTION_IN_PROGRESS &&
        mintType !== MINT_STEPS.MINT_NFT_DIRECTLY && (
          <div className="create-flow-wrapper">
            <Container>
              <Row className="justify-content-center">
                <>
                  <Col md="12" lg="6" xl="5">
                    {/* {nftData?.compressedFilePath?.includes("nft/") || nftData?.fileType === "file" || compressedPercentage === "100%" ? ( */}
                    {compressedFileURL?.includes('nft/') ||
                    nftData?.compressedFilePath?.includes('nft/') ||
                    nftData?.fileType === 'file' ||
                    compressedPercentage === '100%' ? (
                      <NftMediaWrapperCard
                        thumbnail={
                          compressedFileURL ||
                          nftData?.compressedFilePath ||
                          nftData?.fileThumbPath ||
                          ''
                        }
                        fileUrl={
                          compressedFileURL ||
                          nftData?.compressedFilePath ||
                          nftData?.filePath ||
                          ''
                        }
                        name={nftData?.title || ''}
                        fileType={nftData?.fileType}
                        fileMimeType={nftData?.fileMimeType}
                      />
                    ) : (
                      <NFTLoadingIndicatorCard
                        mediaSocketListening={Boolean(
                          checkIfRequestIsForSocketListening(
                            nftData?.compressedFilePath || ''
                          )
                        )}
                        socketId={
                          checkIfRequestIsForSocketListening(
                            nftData?.compressedFilePath || ''
                          ) as string
                        }
                        getDraftNftData={getDraftNftData}
                      />
                    )}
                  </Col>
                  <Col md="12" lg="6" xl="5">
                    {mintType === MINT_STEPS.SELECT_BLOCKCHAIN &&
                      true && (
                        <MintBlockchainOptionsList
                          compressFilePath={
                            nftData?.compressedFilePath
                          }
                          fileType={nftData?.fileType || ''}
                          isCustomUser={isCustomUser}
                          setMintType={setMintType}
                          setBlockChainGateway={setBlockChainGateway}
                        />
                      )}
                    {mintType === MINT_STEPS.PAYMENT_OPTIONS && (
                      <ChoosePaymentOptions
                        gateway={blockChainGateway}
                        setMintType={setMintType}
                        usdPrice={0.0}
                      />
                    )}
                  </Col>
                </>
              </Row>
            </Container>
          </div>
        )}
      {mintType === MINT_STEPS.TRANSACTION_IN_PROGRESS && (
        <div className="payment-flow-wrapper">
          <TransactionInProgress />
        </div>
      )}
      {mintType === MINT_STEPS.MINT_NFT_DIRECTLY && (
        <div className="payment-flow-wrapper">
          <MintNftForInvestorCustomUser
            gateway={blockChainGateway}
            setMintScreen={setMintType}
          />
        </div>
      )}
    </>
  )
}

/**
 * @note - do not remove this code. It will be used again.
 */

// block user navigation
/**
 * @start
 */
// const [blockPageNavigation, setBlockPageNavigation] = useState(false);
// const handleUserTryingToNavigateAway = () => {
//   if (blockPageNavigation) {
//     enableBeforeUnload("You Cannot navigate away while minting is in progress");
//     dispatch(setTaskInProgress(true));
//   } else {
//     disableBeforeUnload();
//     dispatch(setTaskInProgress(false));
//     dispatch(setTaskInProgressAlert(false));
//   }
// };

// useEffect(() => {
//   if (mintType !== 1 && mintType !== 3) {
//     setBlockPageNavigation(true);
//     // eslint-disable-next-line react-hooks/rules-of-hooks
//   } else {
//     setBlockPageNavigation(false);
//   }
// }, [mintType]);
// // user should not navigate away

// useEffect(() => {
//   if (mintType !== 1) {
//     handleUserTryingToNavigateAway();
//   }
//   return () => {
//     disableBeforeUnload();
//     // set global task in progress flag to false
//     dispatch(setTaskInProgress(false));
//     dispatch(setTaskInProgressAlert(false));
//   };
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [blockPageNavigation]);

// usePrompt("You Cannot navigate away while minting is in progress", blockPageNavigation);
// /**
//  * @end
//  */
