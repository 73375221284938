import React from "react";
import { useRoutes } from "react-router-dom";
import { UnderConstructionsRoutesV6 } from "./Routes";

export const UnderMaintenanceRouteComponent = () => {
  /**
   * @routes_rendring_logic
   * render routes from an array
   */
  const renderPublicRoutes = useRoutes(UnderConstructionsRoutesV6);
  return <React.Fragment>{renderPublicRoutes}</React.Fragment>;
};
