import * as Yup from "yup";
export const ImageValidationSchema = (fieldName: string) =>
  Yup.mixed().when([fieldName.toLowerCase()], {
    is: (value: File) => value?.size,
    then: (schema) =>
      schema
        .test("name", `${fieldName} is required`, (value) => {
          return value !== undefined && value && value?.name !== "";
        })
        .test("type", "Only images are supported", (value) => {
          return value !== undefined && value && value?.type.includes("image");
        }),
    // .test("fileSize", "File must be less than 2MB", (value) => {
    //   return value !== undefined && value && value?.size <= 1000000;
    // })
    otherwise: (schema) => schema.nullable()
  });
