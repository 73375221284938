import { cookieKeys } from "utils/constants/constants";
import { getDecryyptedCookie, handleLogout, removedCookie } from "utils/functions/commonFunctions";
import { ISLOGGEDIN, LOGOUT, ONUSERLOGOUTANDREDIRECT } from "./type";

const getLoginStatus = () => {
  const cookieUser = getDecryyptedCookie(cookieKeys.cookieUser);
  const token = cookieUser?.token;
  return token ? true : false;
};
const initialState = {
  isLoggedIn: getLoginStatus(),
  unAuthorized: false
};

export const authReducer = (state = initialState, action: { type: string }) => {
  const newState = { ...state };
  switch (action.type) {
    case ISLOGGEDIN:
      newState.isLoggedIn = true;
      return newState;

    case LOGOUT:
      newState.isLoggedIn = false;
      handleLogout();
      return newState;

    case ONUSERLOGOUTANDREDIRECT:
      newState.isLoggedIn = false;
      localStorage.clear();
      removedCookie(cookieKeys.cookieUser);
      window.location.href = "/login";
      // ti will never return state as it will be redirected to another page.
      return newState;

    default:
      return newState;
  }
};

export interface IAuthReducer {
  isLoggedIn: boolean;
}
