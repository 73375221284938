import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const getArchiveTemplatesAPI = (params?: ITemplateListRequest): Promise<ITemplateListReturn> => {
//   // @todo - send params in {} as soon as 500 error get fixed
//   // right now sending parameter gives 500 error
//   return HTTPService.get(apiEndPoints.app.getArchiveTemplateList, { params });
// };

const archiveTemplateApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    getArchiveTemplatesAPI: build.query<ITemplateListReturn, ITemplateListRequest>({
      query: (params) => ({
        url: apiEndPoints.app.getArchiveTemplateList,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "getArchiveTemplatesAPI", id: "getArchiveTemplatesAPI" }]
    })
  })
});

export const { useGetArchiveTemplatesAPIQuery, useLazyGetArchiveTemplatesAPIQuery } = archiveTemplateApiSlice;

export interface ITemplateListRequest {
  page?: number;
  limit?: number;
  search?: string;
  key?: string;
}

export interface ITemplateListData {
  description: string;
  filePath: string;
  id: string;
  title: string;
  visibility: number;
  _id: string;
}

export interface ITemplateListReturn {
  status: number;
  message: string;
  count: number;
  data: ITemplateListData[];
}
