import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";
import HTTPService from "utils/httpService";

// export const createNFTAPI = (params: FormData): Promise<ICreateNFTReturn> => {
//   return HTTPService.post(apiEndPoints.app.CreateNft, params);
// };

const nftApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    createNFTAPI: build.mutation<ICreateNFTReturn, FormData>({
      query: (data) => ({
        url: apiEndPoints.app.CreateNft,
        method: "post",
        data: data
      })
    })
  })
});

export const { useCreateNFTAPIMutation } = nftApiSlice;

export interface ICreateNFTReturn {
  status: number;
  message: string;
  data: {
    filePath: string;
    id: string;
    ipfsUrl: string;
  };
}

export const categoryListAPI = (params: any): Promise<ICategoryListReturn> => {
  return HTTPService.get(apiEndPoints.categoryList, { params });
};

export interface ICategoryListReturn {
  status: number;
  message: string;
  count: number;
  data: {
    name: string;
    nftCount: number;
    _id: string;
  }[];
}
