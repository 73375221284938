import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import { setLoginRequiredPopup } from "store/loginRequiredPopup/action";
import { IRootReducer } from "store/root-reducer";
import { localStorageKeys } from "utils/constants/constants";
import { handleTheLanguageTranslation } from "utils/functions/commonFunctions";

const UnauthorizedPopup = () => {
  const dispatch = useDispatch();
  const { unAuthorized } = useSelector((state: IRootReducer) => state.loginRequiredPopupReducer);

  /**
   * @summary
   * 1. If a user is not logged in an want to see the private nft he will be needing to logged in.
   * 2. Once a user is logged in from the page he was shown a popup of unauthorized to see the content.
   * 3. When user click on login and redirected to edexa.account for login and return back to our site.
   * 4. We keep track that on which page he was shown that unauth popup.
   * 5. Now we will send him back to that page again after login.
   * 6. On this page we are creating the history for redirection.
   *
   * @todo - this is not the best approach, needs to do R&D for this.
   */
  useEffect(() => {
    if (unAuthorized) {
      localStorage.setItem(localStorageKeys.unauthContentRedirect, window.location.pathname);
    }
  }, [unAuthorized]);

  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  return (
    <Modal centered isOpen={unAuthorized}>
      <ModalBody>
        <h2 className="text-center c-tx-secondary  ">Login Required</h2>
        <p className="text-center c-tx-forth ">You are not authorized to view the content of this page, Please login first to access this page.</p>
        <div className="d-flex justify-content-center">
          <Button
            className="custom-primary-outline bg-primary mt-3"
            onClick={() => {
              dispatch(setLoginRequiredPopup(false));
              window.location.href = "/login";
            }}>
            {handleTheLanguageTranslation(label?.login, "Login")}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UnauthorizedPopup;
