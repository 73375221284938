import { useLazyGetGlobalSettingAPIQuery } from "actions/app/get-global-setting";
import { useLazyGetUserInfoQuery } from "actions/auth/user-info-rtq";
import { FooterComponent } from "components/common/Footer";
import HeaderComponent from "components/common/Header";
import DemoRequestPopup from "components/global/DemoRequestPopUP";
import useAuthCookieWatcher from "hooks/useCookieWatcher";
import useLocalhostCookieSetupOnRedirect from "hooks/useLocalhostCookieSetupOnRedirect";
import { Suspense, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { onUserLogIN, onUserLogout } from "store/auth/action";
import { setGlobalSettingObject } from "store/globalSettings/action";
import { changeLanguage, setLanguagesJson } from "store/internationalization/action";
import { INTERNATIONALLANGUAGESENUM } from "store/internationalization/type";
import { setLoading } from "store/loader/action";
import { IRootReducer } from "store/root-reducer";
import { onUserDetailsUpdate, setIsAccess, setUserIdAndUserName, setUserProfileWalletAddress } from "store/user/action";
import { cookieKeys, localStorageKeys } from "utils/constants/constants";
import { getDecryptedLocalStorage, getDecryyptedCookie, handleErrors, setEncryptedLocalStorage } from "utils/functions/commonFunctions";
import { socket } from "utils/socket";
import { ProtectedRouteComponent } from "./ProtectedRouteComponent";
import { PublicRouteComponent } from "./PublicRouteComponent";
import { RequestDemoRouteComponent } from "./RequestDemoRouteComponent";
import { UnderMaintenanceRouteComponent } from "./UnderMaintenanceRouteComponent";

function RoutingComponent() {
  // prevent console logs and errors
  handleErrors();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state: IRootReducer) => state.auth);
  const isLoading = useSelector((state: IRootReducer) => state.loading.isLoading);
  const { requestDemo } = useSelector((state: IRootReducer) => state.globalSettingReducer);
  const user = useSelector((state: IRootReducer) => state.userDetailsReducer);
  const [getUserInfo] = useLazyGetUserInfoQuery();
  const [getGlobalSettingAPIQueryTrigger] = useLazyGetGlobalSettingAPIQuery();

  // Temporary given the static under maintenance true due to service issue in NFT
  const underMaintenance = true;

  const getProfileData = () => {
    dispatch(setLoading(true));
    getUserInfo()
      .then((result) => {
        if (result.data) {
          let response = result.data;
          dispatch(setIsAccess(response.data.isAccess));
          setEncryptedLocalStorage(localStorageKeys.isLoggedIN, response.data);

          dispatch(changeLanguage(response.data.language));
          // this id is used to fetch the profile information of user.
          // it will be set to link tag so when user click on profile link he will be redirect to right page.
          // otherwise the userId value we receive from backend is the unique id.
          // but to fetch nft details of a particular user we need to send his _id.
          // that's why we are setting it in localstorage so it will always be available to use after login.
          setEncryptedLocalStorage(localStorageKeys.user_id, response.data?._id);
          setEncryptedLocalStorage(localStorageKeys.username, response.data?.username);
          // set user wallet address
          setEncryptedLocalStorage(localStorageKeys.wallet, response.data.walletAddress);
          dispatch(setUserProfileWalletAddress(response.data.walletAddress));

          dispatch(setLoading(false));
          dispatch(onUserLogIN());
          dispatch(onUserDetailsUpdate(response.data));
          dispatch(
            setUserIdAndUserName({
              userid: response.data?._id,
              username: response.data?.username
            })
          );
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(onUserLogout());
      });
  };

  const GetGlobalSettingAction = () => {
    dispatch(setLoading(true));
    getGlobalSettingAPIQueryTrigger().then((res) => {
      if (res.data && "data" in res.data) {
        setEncryptedLocalStorage(localStorageKeys.globalSetting, res.data?.data);
        dispatch(setGlobalSettingObject(res.data?.data));
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    // first check if undermaintenance is true // this will be usefull when user is revisiting the page.
    // if we do not check this then the user will keep seeing this undermaintenance until he clears localstorage.
    if (underMaintenance) {
      GetGlobalSettingAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // implementing sockets listning to our app
  useEffect(() => {
    // listen for global settings change
    socket.on("settings", (data) => {
      if (data?.requestDemo === 1) {
        localStorage.clear();
      } else {
        localStorage.clear(); //clear all localstorage
      }
      setEncryptedLocalStorage(localStorageKeys.globalSetting, data);
      dispatch(setGlobalSettingObject(data));
    });

    // listen for language json update
    socket.on("language_labels", (data) => {
      let languagesJson = { DE: data.DE, EN: data.EN };
      dispatch(setLanguagesJson(languagesJson));
      dispatch(changeLanguage(getDecryptedLocalStorage(localStorageKeys.languageType) || INTERNATIONALLANGUAGESENUM.ENGLISH));
      // store data in lcoalstorage to save api calls every time app reloads
      setEncryptedLocalStorage(localStorageKeys.internationalization, languagesJson);
      setEncryptedLocalStorage(localStorageKeys.languageType, getDecryptedLocalStorage(localStorageKeys.languageType) || INTERNATIONALLANGUAGESENUM.ENGLISH);
    });

    // listen for user profile data change
    const cookieUser = getDecryyptedCookie(cookieKeys.cookieUser);
    cookieUser &&
      socket.on(`user_info_${cookieUser.userId}`, (data) => {
        getProfileData();
      });

    if (!getDecryptedLocalStorage(localStorageKeys.globalSetting)) {
      GetGlobalSettingAction();
    }

    // call get profile data only if we have user cookies stored in localstorage
    // if we call without the tocken getprofile will return 401 unauthorized and will redirect user to /login page
    cookieUser && !getDecryptedLocalStorage(localStorageKeys.isLoggedIN) && getProfileData();
    return () => {
      cookieUser && socket.off(`user_info_${cookieUser.userId}`);
      socket.off("language_labels");
      socket.off("settings");
      socket.removeAllListeners();
      socket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRouteRender = () => {
    // if site is under maintenance then render only maintenance route
    if (underMaintenance) {
      return <UnderMaintenanceRouteComponent />;

      // until the data is not loaded from api do not render anything just the loading indicators
    } else if (underMaintenance === undefined || requestDemo === undefined) {
      return <></>;
    }

    if (requestDemo === true) {
      // if user has access and he is logged in despite the demo is on then render procted routes
      if (user.isAccess && isLoggedIn) return <ProtectedRouteComponent />;

      // otherwise render demo routes
      return <RequestDemoRouteComponent />;
    } else {
      // if user is logged in and the demo is off
      if (isLoggedIn) return <ProtectedRouteComponent />;

      // if demo is off and user is not logged in
      return <PublicRouteComponent />;
    }
  };

  // use login hook on localhost
  // it will set login cookeis and storage automatically without any need of manually dong it
  useLocalhostCookieSetupOnRedirect();

  // check if user auth cookie changes/deleted then logout him from current app.
  // ⚠ Be cautious while using this hook because it can keep your user logged-out in loop
  const { exist } = useAuthCookieWatcher(`${cookieKeys.cookieInitial}-${cookieKeys.cookieUser}`, 1000);
  useEffect(() => {
    // if the user is logged in but the auth cookie dosen't exist that means he is logged out from some other subdomains so logout him from current app too.
    if (!exist && isLoggedIn) {
      dispatch(onUserLogout());
    }
  }, [exist, isLoggedIn]);

  return (
    <>
      {/* full page loader */}
      {isLoading ? (
        <div className="loader-wrapper">
          <Spinner type="grow" color="light" />
        </div>
      ) : null}
      <Toaster />
      <Suspense fallback={<p>Loading...</p>}>
        {/* remove the jerk from ui with min-height when the header becomes fixed on scroll */}
        {!underMaintenance && underMaintenance !== undefined ? (
          <div style={{ minHeight: 52 }}>
            <HeaderComponent />
          </div>
        ) : null}
        {handleRouteRender()}
        {/* {isLoggedIn ? <ProtectedRouteComponent /> : <PublicRouteComponent />} */}
        {!underMaintenance && underMaintenance !== undefined ? <FooterComponent /> : null}
      </Suspense>
      {requestDemo ? <DemoRequestPopup /> : null}
    </>
  );
}

export default RoutingComponent;
