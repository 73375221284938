import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TRANSACTION_TYPE, useGetWalletTransactionsQuery } from "actions/wallet/get-wallet-transactions";
import GlobalLoadMoreButton from "components/GlobalLoadMoreButton";
import { useDebouncedEffect } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Input, Row, Spinner, Table } from "reactstrap";
import { setUserProfileWalletAddress } from "store/user/action";
import { paginationKeysObject } from "utils/constants/constants";
import { capitalizeFirstLetter, getFormattedDateWithMonthName } from "utils/functions/commonFunctions";

const WalletHistory = () => {
  const filter = <FontAwesomeIcon icon={faFilter} />;
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const [apiLoading, setApiLoading] = useState(false);
  const [walletHistoryPagination, setWalletHistoryPagination] = useState({
    ...paginationKeysObject,
    type: ""
  });
  const { data, isLoading, isError, status } = useGetWalletTransactionsQuery(walletHistoryPagination);

  const loadMore = () => {
    if (data?.data && walletHistoryPagination.limit >= data?.data.count) return;
    setApiLoading(true);
    setWalletHistoryPagination({
      ...walletHistoryPagination,
      limit: walletHistoryPagination.limit + paginationKeysObject.limit
    });
  };
  useEffect(() => {
    if (status === "fulfilled") setApiLoading(false);
  }, [status]);

  useEffect(() => {
    // @ts-ignore
    dispatch(setUserProfileWalletAddress(data?.data?.userWallet));
  }, [data?.data?.userWallet]);

  // debounce search when user filtring by tx title
  useDebouncedEffect(
    () =>
      setWalletHistoryPagination({
        ...walletHistoryPagination,
        search: search
      }),
    1000,
    [search]
  );
  return (
    <Col sm="12" md="6">
      <div className="credits-right c-br-3 c-bg-lightgray">
        <Row className="justify-content-between align-items-center cb-20">
          <Col>
            <p className="h3 fw-bold mb-0">Credit history</p>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button className="py-0 credits-right-filter" onClick={() => setVisible((prevState) => !prevState)}>
              {filter}
            </Button>
          </Col>
        </Row>
        {/* filters */}
        {visible ? (
          <Row>
            <Col>
              <input
                type="text"
                name="search-title"
                id=""
                className="form-control mb-2 mb-md-0"
                placeholder="Search by title"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Col>
            <Col>
              <Input
                type="select"
                name="credit"
                id=""
                className="form-control mb-2 mb-md-0"
                placeholder="Search by type"
                value={walletHistoryPagination.type}
                onChange={(e) => {
                  setWalletHistoryPagination({
                    ...walletHistoryPagination,
                    type: e.target.value
                  });
                }}>
                <option value="">All</option>
                <option value="debit">Debit</option>
                <option value="credit">Credit</option>
              </Input>
            </Col>
          </Row>
        ) : null}
        {/* data table */}
        <div className="credits-right-table">
          <Table className="">
            <thead>
              <tr className="">
                <th>Credit title</th>
                <th>Date</th>
                <th>Credit amount</th>
              </tr>
            </thead>
            <tbody>
              {data?.data.transactionData.map((transaction) => {
                return (
                  <tr className="c-tx-forth" key={transaction._id}>
                    <td>{capitalizeFirstLetter(transaction.type)}</td>
                    <td>{getFormattedDateWithMonthName(transaction.createdAt)}</td>
                    <td className={transaction.type === TRANSACTION_TYPE.CREDIT ? "credit-up" : "credit-down"}>
                      {transaction.type === TRANSACTION_TYPE.CREDIT ? "" : "-"}
                      {transaction.price} USD
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {!isLoading && data?.data?.transactionData?.length === 0 ? (
            <p className="text-center mt-4">
              No transactions are made yet,{" "}
              <Link to={"/create-nft"} className="text-decoration-none">
                mint your first NFT
              </Link>{" "}
              to see the transaction history
            </p>
          ) : null}
          {isLoading ? (
            <div className="text-center">
              <Spinner size={"sm"} />
            </div>
          ) : null}
          {isError ? <p className="text-center text-primary mt-4">Failed to load the transactions.</p> : null}
        </div>
        <div className="text-center mb-5">
          <GlobalLoadMoreButton loadMoreData={loadMore} isLoading={apiLoading} dataCount={data?.data.count} classNames="dark-text" listData={data?.data.transactionData} />
        </div>
      </div>
    </Col>
  );
};

export default WalletHistory;
