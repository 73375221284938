import { localStorageKeys } from "utils/constants/constants";
import { getDecryptedLocalStorage } from "utils/functions/commonFunctions";
import { ILanguageJson, ILanguagesJson } from "./module";
import { CHANGELANGUAGE, INTERNATIONALLANGUAGESENUM, SETLANGUAGESJSON } from "./type";

const languageSelect = () => {
  const lang = getDecryptedLocalStorage(localStorageKeys.languageType);
  if (lang) {
    return lang;
  } else {
    return INTERNATIONALLANGUAGESENUM.ENGLISH;
  }
};

const initialLanguagesJsonState: ILanguagesJson = {
  languagesJson: {
    [INTERNATIONALLANGUAGESENUM.ENGLISH]: {},
    [INTERNATIONALLANGUAGESENUM.GERMAN]: {}
  },
  currentLanguage: {
    languageType: languageSelect(),
    json: {}
  }
};

export const LanguagesJsonReducer = (state = initialLanguagesJsonState, action: { type: string; payload: INTERNATIONALLANGUAGESENUM | ILanguageJson }) => {
  let newState = { ...state };
  switch (action.type) {
    case CHANGELANGUAGE:
      // set current selected languages json
      if ((action.payload as INTERNATIONALLANGUAGESENUM) in newState.languagesJson) {
        newState = {
          ...newState,
          currentLanguage: {
            languageType: action.payload as INTERNATIONALLANGUAGESENUM,
            json: newState.languagesJson[action.payload as INTERNATIONALLANGUAGESENUM]
          }
        };
      }
      break;
    // set the whole languages json
    case SETLANGUAGESJSON:
      newState = {
        ...newState,
        languagesJson: action.payload as ILanguageJson
      };
      break;
  }
  return newState;
};
