import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const appGetCollectionList = (params: ICollectionListRequest): Promise<ICollectionListReturn> => {
//   return HTTPService.get(apiEndPoints.app.getCollection, { params });
// };
const collectionListApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appGetCollectionList: build.query<ICollectionListReturn, ICollectionListRequest>({
      query: (params) => ({
        url: apiEndPoints.app.getCollection,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "appGetCollectionList", id: "appGetCollectionList" }]
    })
  })
});

export const { useAppGetCollectionListQuery, useLazyAppGetCollectionListQuery } = collectionListApiSlice;

export interface ICollectionListRequest {
  page: number;
  limit: number;
  search: string;
  url?: string;
  isPublicCollection?: boolean;
}

export interface ICollection {
  _id: string;
  featuredImage: string;
  description: string;
  logoImage: string;
  name: string;
  NFTs: number;
  username: string;
  customUrl: string;
  visibility: number;
}

export interface ICollectionListReturn {
  status: number;
  message: string;
  count: number;
  data: ICollection[];
}
