import { makeFullS3ObjectUrl } from "assets/images";
import CubeAnimation from "components/CubeAnimation";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { handleTheLanguageTranslation } from "utils/functions/commonFunctions";
const ArbitrumLogoSmall = makeFullS3ObjectUrl("arbitrum-logo-only.svg");
const ArbitrumLogo = makeFullS3ObjectUrl("arbitrum.svg");
const BinanceLogo_1 = makeFullS3ObjectUrl("binance-1.png");
const BinanceLogo = makeFullS3ObjectUrl("binance-logo.png");
const EthereumLogo_1 = makeFullS3ObjectUrl("ethereum-1.png");
const EthereumLogo = makeFullS3ObjectUrl("ethereum-logo.png");
const OptimismLogoSmall = makeFullS3ObjectUrl("optimism-logo-only.svg");
const OptimismLogo = makeFullS3ObjectUrl("optimism-logo-white.svg");
const PolygonLogo = makeFullS3ObjectUrl("palygon.png");
const PolygonLogoSmall = makeFullS3ObjectUrl("polygon-logo.png");
const Universe_1 = makeFullS3ObjectUrl("universe-1.png");
const Universe = makeFullS3ObjectUrl("universe.png");

const BannerSection = () => {
  const swiperRef = useRef<HTMLDivElement>(null);
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  const [swipingValues, setSwipingValues] = useState<string[]>([]);

  useEffect(() => {
    setSwipingValues([
      handleTheLanguageTranslation(label.by_edexa, "By edeXa"),
      handleTheLanguageTranslation(label.for_business, "For Business"),
      handleTheLanguageTranslation(label.on_edexa_universe, "On edeXa Universe")
    ]);
  }, [label]);

  useEffect(() => {
    let counter = 0;
    const swipingInterval = setInterval(() => {
      if (counter >= swipingValues.length) counter = 0;
      if (swiperRef.current) {
        swiperRef.current.textContent = swipingValues[counter];
        counter++;
      }
    }, 2000);
    return () => {
      clearInterval(swipingInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swipingValues]);

  return (
    <section className="banner-wrapper banner-wrapper-new mt-xl-auto">
      <div className="banner-section_1 text-center text-sm-start">
        <div className="inner_wrapper">
          <Row>
            <Col xs={{ size: 12, order: 2 }} lg="6" xl="6" xxl="6">
              <div className="banner-content-wrapper banner-content-wrapper-new">
                <div className="banner-inner-wrapper">
                  <h1 className="text-white f-400 mb-0 banner-title main_tl span">
                    {handleTheLanguageTranslation(label?.state_of_digital_asset_platform, "State of Digital Asset platform")}
                    <div ref={swiperRef}></div>
                  </h1>
                  <div className="welcome-content">
                    <h3 className="c-tx-third lh-32 mb-0 f-400">
                      {handleTheLanguageTranslation(
                        label?.welcome_home_p_1,
                        "Concentrate on your project rather than creating a new smart contract. The edeXa Universe NFT Engine is highly customizable to speed your daily business processes."
                      )}
                    </h3>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xs={{ size: 12, order: 1 }}
              lg={{ size: 6, offset: 0, order: 2 }}
              xl={{ size: 6, offset: 0, order: 2 }}
              xxl={{
                size: 5,
                offset: 1,
                order: 2
              }}>
              <div className="banner-image-wrapper ">
                <CubeAnimation />
                {/* <Link to={`assets/${item.id}`}>
                  <div className="banner-item image-loader-light">
                    <div className="banner-item-first">
                      <RenderRespectiveMediaTag
                        mediaUri={item.src}
                        nftId={item.id}
                        artName={item.altText}
                        thumbnailUri={item.fileThumbPath?.includes("gif") ? item.src : item.fileThumbPath}
                        fileMimeType={item.fileMimeType || ""}
                      />
                    </div>
                    <div className="banner-caption">
                      <h3 className="h3">{item.altText}</h3>
                      <p>{item.caption}</p>
                    </div>
                  </div>
                </Link> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Row>
        <Col xs={{ size: 12 }} lg={{ size: 12 }} xl={{ size: 12 }} xxl={{ size: 12 }}>
          <div className=" logo-portion-list">
            <h3 className="text-white cb-20 d-none d-md-block mb-0">{handleTheLanguageTranslation(label?.blockchain_supported, "Blockchain supported")}</h3>

            <ul className="support-list d-none d-lg-flex">
              <li className="d-inline-flex d-md-none ">
                <h3 className="text-white mb-0">{handleTheLanguageTranslation(label?.blockchain_supported, "Blockchain supported")}</h3>
              </li>
              <li>
                <img src={Universe} className="img-fluid" alt="edexa universe blockchain logo" />
              </li>
              <li>
                <img src={EthereumLogo} className="img-fluid" alt="Ethereum blockchain logo" />
              </li>
              <li>
                <img src={BinanceLogo} className="img-fluid" alt="Binance blockchain logo" />
              </li>

              <li>
                <img src={PolygonLogoSmall} className="img-fluid" alt="Polygon blockchain logo" />
              </li>
              <li>
                <img src={OptimismLogo} className="img-fluid" alt="optimism blockchain logo" />
              </li>
              <li>
                <img src={ArbitrumLogo} className="img-fluid" alt="arbitrum blockchain logo" />
              </li>
            </ul>
            <ul className="support-list bl-chain d-lg-none d-flex">
              <li className="d-inline-flex d-md-none justify-content-center">
                <h3 className="text-white mb-0 text-center">{handleTheLanguageTranslation(label?.blockchain_supported, "Blockchain supported")}</h3>
              </li>
              <li>
                <img src={Universe_1} className="img-fluid" alt="edexa universe blockchain logo" />
              </li>
              <li>
                <img src={BinanceLogo_1} className="img-fluid" alt="Binance blockchain logo" />
              </li>
              <li>
                <img src={EthereumLogo_1} className="img-fluid" alt="Ethereum blockchain logo" />
              </li>
              <li>
                <img src={PolygonLogo} className="img-fluid" alt="Polygon blockchain logo" />
              </li>
              <li>
                <img src={OptimismLogoSmall} className="img-fluid" alt="Polygon blockchain logo" />
              </li>
              <li>
                <img src={ArbitrumLogoSmall} className="img-fluid" alt="Polygon blockchain logo" />
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default BannerSection;
