import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const FavouriteNftListAPI = (params: { page: number; limit: number }): Promise<IFavouriteNftReturn> => {
//   return HTTPService.get(apiEndPoints.app.favouriteList, { params });
// };

const favouriteSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    FavouriteNftListAPI: build.query<IFavouriteNftReturn, { page: number; limit: number }>({
      query: (params) => ({
        url: apiEndPoints.app.favouriteList,
        params: params,
        method: "get"
      }),
      providesTags: [{ type: "FavouriteNftListAPI", id: "FavouriteNftListAPI" }]
    })
  })
});

export const { useFavouriteNftListAPIQuery, useLazyFavouriteNftListAPIQuery } = favouriteSlice;

export interface IGetFavouriteList {
  fileThumbPath: string;
  fileMimeType: string;
  createdAt: string;
  createdBy: string;
  description: string;
  compressedFilePath: string;
  filePath: string;
  isCustom: number;
  id: string;
  nftId: number;
  tags: string[];
  title: string;
  totalLikes: number;
  totalViews: number;
  _id: string;
}

export interface IFavouriteNftReturn {
  message: string;
  status: number;
  count: number;
  data: IGetFavouriteList[];
}
