export const apiEndPoints = {
  api: {
    CreateNft: "/nft",
    MintNft: "/mint",
    AuthenticateUser: "https://api-edexagw.io-world.com/bstamp/authenticate",
    GetSingleNft: "/nft?id=",
    GetNftList: "/nft",
    ExploreNfts: "/explore"
  },
  admin: {
    GetLabels: "/app/labels"
  },
  app: {
    CreateCustomNft: "/app/custom-nft",
    CreateNft: "/app/nft",
    MintNft: "/app/mint",
    GetSingleNft: "/app/nft?id=",
    GetNftList: "/app/nft",
    ExploreNfts: "/app/explore",
    GetDraft: "/app/get-draft",
    UpdateDraft: "/app/update-draft",
    DeleteDraft: "/app/delete-draft",
    LandingPageData: "/app/landing-page",
    FavouriteUnfavourite: "/app/favourite",
    GetProfile: "/app/profile",
    UpdateProfile: "/app/profile",
    favouriteList: "/app/get-favourite",
    search: "/app/suggestions",
    ChangeAPIVisibility: "/app/visibility",
    getTemplateList: "/app/get-template",
    getArchiveTemplateList: "/app/get-archive",
    updateArchive: "/app/archive",
    updateCustomDraft: "/app/update-custom-draft",
    deleteTemplate: "app/delete-template",
    getCollection: "/app/get-collection",
    getGlobalSetting: "/app/settings",
    RequestDemo: "/app/request-demo",
    shiftNft: "/app/shift-nft",
    createCollection: "/app/collection",
    updateCollection: "/app/collection",
    deleteCollection: "/app/delete-collection",
    removeNftsFromCollection: "/app/remove-nft",
    validateCustomUrl: "/app/collection-url-exists",
    blockchainMintPrice: "/pay/price-list"
  },
  wallet: {
    AddCredit: "/wallet/credit",
    GetPlans: "/wallet/plans",
    Transactions: "/wallet/transactions"
  },
  createPayment: "/pay/create-payment",
  listInvoices: "/pay/invoice-list",
  downloadInvoice: "/pay/download-invoice",
  SingleBillInfo: "/pay/bill-info?billingId=",
  UserPlan: "/pay/user-plan",
  PricingPlanList: "/pay/plans",
  userInfo: "/app/user-info",
  categoryList: "/category/list",
  topUsers: "/app/top-users",
  cardList: "/pay/card-type",
  auth: {
    logout: "auth/logout"
  }
};
