import { MintGateways } from "actions/enum/gateway-types";
import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

const mintNftPriceApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    mintPriceList: build.query<IMintPriceListApiResponse, { blockchainList?: boolean }>({
      query: (params) => ({
        url: apiEndPoints.app.blockchainMintPrice,
        method: "get",
        params: params ?? ""
      }),
      providesTags: [{ type: "mintPriceList", id: "mintPriceList" }]
    }),
    gatewayMintPrice: build.query<IMintPriceSingleGatewayApiResponse, { gateway: number }>({
      query: (params) => ({
        url: apiEndPoints.app.blockchainMintPrice,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "gatewayMintPrice", id: "gatewayMintPrice" }]
    })
  })
});

export const { useGatewayMintPriceQuery, useLazyGatewayMintPriceQuery, useLazyMintPriceListQuery, useMintPriceListQuery } = mintNftPriceApiSlice;

export interface IMintBlockchainList {
  gateway: MintGateways;
  totalPrice: number;
  isEnable: number;
  isLive: boolean;
  order: number;
  gatewayLogo: string;
  gatewayLogoBlack: string;
  gatewayLogoWhite: string;
}

export interface IMintBlockchainListIcons {
  icon: string;
  iconBlack: string;
  iconWhite: string;
  gateway: MintGateways;
  isEnable: number;
  isLive: boolean;
  order: number;
}

export interface IMintPriceListApiResponse {
  status: number;
  message: string;
  data: IMintBlockchainList[] | IMintBlockchainListIcons[];
}

export interface IMintPriceSingleGatewayApiResponse {
  status: number;
  message: string;
  data: {
    commissionPrice: number;
    price: number;
    totalPrice: number;
    isLive: boolean;
  };
}
