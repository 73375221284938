import { GlobalSettingType } from "./type";

export interface IGlobalSettingObject {
  requestDemo: number;
  underMaintenance: number;
  _id: string;
}

export const setGlobalSettingObject = (payload: IGlobalSettingObject) => {
  return { type: GlobalSettingType.SET_GLOBAL_SETTING_OBJECT, payload };
};

export const setGlobalSetting = (data: boolean) => {
  return { type: GlobalSettingType.SET_GLOBAL_SETTING_OBJECT, payload: data };
};
