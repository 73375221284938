import { useDispatch } from "react-redux";
import { onUserLogIN } from "store/auth/action";
import { cookieKeys } from "utils/constants/constants";
import { getDecryyptedCookie, setEdexaCookieForLocalhost } from "utils/functions/commonFunctions";
/**
 * @param {}
 * @returns isCookieSetOnLocal: true | false
 */
const useLocalhostCookieSetupOnRedirect = () => {
  const dispatch = useDispatch();

  // if local development then use this approach to set cookies
  // we do not have to go to application tab and change cookie domain manually
  let isCookieSetOnLocal = false;

  if (process.env.REACT_APP_ENV === "DEVELOPMENT" && !getDecryyptedCookie(cookieKeys.cookieUser)) {
    const urlParams = new URLSearchParams(window.location.search);
    const userData: string = urlParams.get("use") || "";
    if (userData) {
      setEdexaCookieForLocalhost(cookieKeys.cookieUser, decodeURIComponent(userData));
      // update redux store that user is logged in right now
      dispatch(onUserLogIN());
      // remove query string
      window.history.pushState({}, "", window.location.origin);
      // const cookieUser = getEncryptedCookie(cookieKeys.cookieUser);
      // const token = getDecryptedLocalStorage(localStorageKeys.userToken);
      // if (token !== cookieUser?.token) {
      //   setEncryptedLocalStorage(localStorageKeys.userToken, cookieUser.token);
      //   // cookie do not have user data info
      //   //  setEncryptedLocalStorage(localStorageKeys.isLoggedIN, cookieUser.data);
      //   isCookieSetOnLocal = true;
      //   // redirect to root and remove query string of user=
      //   // window.location.href = window.location.href.split("?")?.[0];
      // }
    }
  }
  // if cookie is set successfully then return true
  return { isCookieSetOnLocal };
};

export default useLocalhostCookieSetupOnRedirect;

/**
 * @how_to_use
 * if you pass true then you do not have to call the getProfile data function seperately
 * useLocalhostCookieSetupOnRedirect();
 */
