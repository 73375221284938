/* eslint-disable react-hooks/exhaustive-deps */
import { useCreateCustomNftMutation } from "actions/app/create-custome-nft";
import { useLazyGetDrafAPIQuery } from "actions/app/get-draft-nft";
import { useLazyGetTemplateAPIQuery } from "actions/app/get-tamplate";
import { useUpdateCustomDraftNftAPIMutation } from "actions/app/update-Custom-Draft";
import GlobalHelmetProvider from "components/GlobalHelmetProvider";
import { useFormik } from "formik";
import useTheme, { THEMETYPES } from "hooks/useTheme";
import Page404 from "pages/404Page";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { setLoading } from "store/loader/action";
import { IRootReducer } from "store/root-reducer";
import { handleDate, handleTheLanguageTranslation, toastError } from "utils/functions/commonFunctions";
import * as Yup from "yup";
import "../../styles/pages/createcustomnft.scss";
import CurrentSteupComponent from "./CurrentSteupComponent";
import NftAttribute from "./NftAttribute";
import NftInformation from "./NftInformation";
import ReviewNft from "./ReviewNft";

// address validation with yup
Yup.addMethod(Yup.string, "validateEtherAddress", function (errorMessage) {
  return this.test("validate-eth-wallet-address", errorMessage, function (value) {
    const { path, createError } = this;
    // @ts-ignore
    return (
      // @ts-ignore
      WAValidator.validate(value, "eth") || createError({ path, message: errorMessage })
    );
  });
});
export const CreatCustomNFT = () => {
  useTheme(THEMETYPES.LIGHT, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { templateID } = useParams();
  const [step, setStep] = useState(1);
  const [draft, setDraft] = useState(false);
  const [validationAtribute, setValidationAtribute] = useState(false);
  const [createCustomNftMutation] = useCreateCustomNftMutation();
  const [getDraftNftAPIQuery] = useLazyGetDrafAPIQuery();
  const [getTemplateAPIQuery] = useLazyGetTemplateAPIQuery();
  const [updateCustomDraftMutation] = useUpdateCustomDraftNftAPIMutation();

  useEffect(() => {
    const footerBlockElements = document.getElementsByClassName("footer-second-block");

    // Use conditional check to ensure elements are found before accessing
    if (footerBlockElements.length > 0) {
      const footerBlockElement = footerBlockElements[0] as HTMLElement | undefined;

      // Use type assertion to HTMLElement, or undefined if not found
      if (footerBlockElement) {
        footerBlockElement.style.display = "none";
      }
    }

    if (location?.pathname.includes("draft-nft")) {
      setDraft(true);
      getDraftAction();
    } else {
      templateID && getTamplateAction();
    }

    // Cleanup function
    return () => {
      if (footerBlockElements.length > 0) {
        const footerBlockElement = footerBlockElements[0] as HTMLElement | undefined;

        if (footerBlockElement) {
          footerBlockElement.style.display = "";
        }
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nftInfo = useFormik({
    initialValues: {
      title: "",
      description: "",
      image: "",
      logo: "",
      fileType: "custom",
      attributes: [
        {
          trait_type: "",
          type: "",
          value: "",
          mimeType: ""
        }
      ],
      walletAddress: "",
      updateTemplate: "",
      templateId: "",
      visibility: false,
      currentStep: 1,
      imageCount: 0,
      videoCount: 0,
      pdfCount: 0,
      docCount: 0
    },
    validationSchema: Yup.object().shape({
      // @ts-ignore
      title:
        step === 1
          ? Yup.string()
              .required("Title is required")
              .min(2, "Title should be minimum 4 character long")
              .max(150, "Maximum 46 character are allowed")
          : null,
      // @ts-ignore
      description:
        step === 1
          ? Yup.string()
              .required("Description is required")
              .min(4, "Description should be minimum 4 character long")
              .max(250, "Maximum 250 character are allowed")
          : null,
      // @ts-ignore
      logo: step === 1 ? Yup.string().required("Logo is required") : null,
      // @ts-ignore
      attributes:
        step === 2
          ? Yup.array().of(
              Yup.object().shape({
                trait_type: Yup.string().required(),
                type: Yup.string().required(),
                // value: Yup.string().required(),
                value: Yup.mixed().required("File is required")
              })
            )
          : "",
      // @ts-ignore
      walletAddress:
        step === 3
          ? Yup.string()
              .required("Wallet address is required")
              // @ts-ignore
              .validateEtherAddress("This Address is not a valid Ethereum wallet address")
          : null
    }),
    onSubmit: (value) => {
      if (value.currentStep === 1) {
        setFieldValue("currentStep", 2);
      } else if (value.currentStep === 2) {
        setFieldValue("currentStep", 3);
      } else {
        const { title, description, fileType, logo, walletAddress, attributes, visibility, templateId } = values;

        dispatch(setLoading(true));
        const formData = new FormData();
        formData.append("title", title.trim());
        formData.append("description", description.trim());
        formData.append("fileType", fileType);
        let tmp = typeof values.logo;
        if (tmp === "object") {
          // @ts-ignore
          formData.append("attachments", logo);
        }
        formData.append("walletAddress", walletAddress);
        formData.append("visibility", visibility ? "1" : "0");
        if (attributes.length !== 0) {
          attributes.forEach((data: any, index: number) => {
            formData.append(`attributes[${index}].type`, data.type);
            formData.append(`attributes[${index}].trait_type`, data.trait_type.trim());
            if (data.type === "file") {
              if (typeof data.value === "string") {
                formData.append(`attributes[${index}].value`, data.value);
                formData.append(`attributes[${index}].mimeType`, data.mimeType);
                formData.append(`attributes[${index}].fileName`, data.fileName);
                if (data?.videoDuration) {
                  formData.append(`attributes[${index}].videoDuration`, data.videoDuration);
                }
              } else {
                formData.append(`attributes[${index}].value`, data.value[0]);
                formData.append(`attributes[${index}].mimeType`, data.value[0].type);
              }
            } else if (data.type === "text") {
              formData.append(`attributes[${index}].value`, data.value.trim());
              formData.append(`attributes[${index}].mimeType`, "");
            } else {
              formData.append(`attributes[${index}].value`, data.value);
              formData.append(`attributes[${index}].mimeType`, "");
            }
          });
        }
        if (draft) {
          if (templateID) {
            formData.append("id", templateID);
            updateCustomDraftMutation(formData).then((res) => {
              if ("data" in res && res.data && res.data.status === 200) {
                navigate(`/custom-mint/${templateID}`);
              }
              dispatch(setLoading(false));
            });
          }
        } else {
          if (templateId) {
            formData.append("templateId", templateId);
          }
          createCustomNftMutation(formData).then((res) => {
            if ("data" in res && res.data.status === 200) {
              navigate(`/custom-mint/${res.data.data._id}`, {
                state: { fromCreatePage: true }
              });
            }
            dispatch(setLoading(false));
          });
        }
      }
    }
  });
  const { setFieldValue, values, handleSubmit } = nftInfo;

  const { walletAddress: userProfileWalletAddress, user } = useSelector((state: IRootReducer) => state.userDetailsReducer) || {};
  const { IsCustomNftDisabled } = useSelector((state: IRootReducer) => state.globalSettingReducer) || {};
  // set the wallet address initially from the user profile data from localstorage
  useEffect(() => {
    if (userProfileWalletAddress) {
      setFieldValue("walletAddress", userProfileWalletAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileWalletAddress]);

  const handleImage = (attributes: any) => {
    let loopStatus = false;
    // eslint-disable-next-line
    attributes.map((item: any) => {
      if (item.type === "file") {
        loopStatus = true;
      }
    });
    if (loopStatus) {
      let imageCount = 0;
      let videoCount = 0;
      let pdfCount = 0;
      let docCount = 0;
      attributes.forEach((data: any) => {
        if (data.type === "file" && data.value !== undefined && Object.keys(data?.value).length !== 0) {
          if (typeof data.value === "string") {
            if (data.value !== "") {
              if (data?.mimeType.includes("image")) {
                imageCount = imageCount + 1;
              } else if (data?.mimeType.includes("video")) {
                videoCount = videoCount + 1;
              } else if (data?.mimeType.includes("pdf")) {
                pdfCount = pdfCount + 1;
              } else {
                docCount = docCount + 1;
              }
            }
          } else {
            // @ts-ignore
            if (data?.value[0]?.type?.includes("image")) {
              imageCount = imageCount + 1;
              // @ts-ignore
            } else if (data?.value[0]?.type?.includes("video")) {
              videoCount = videoCount + 1;
              // @ts-ignore
            } else if (data?.value[0]?.type?.includes("pdf")) {
              pdfCount = pdfCount + 1;
              // @ts-ignore
            } else if (data?.value[0]?.type?.includes("document")) {
              docCount = docCount + 1;
            } else {
              docCount = docCount + 1;
            }
          }
        }
      });
      setFieldValue("imageCount", imageCount);
      setFieldValue("videoCount", videoCount);
      setFieldValue("pdfCount", pdfCount);
      setFieldValue("docCount", docCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (values.currentStep !== step) {
      setStep(values.currentStep);
    }
  }, [values.currentStep]);

  const getTamplateAction = () => {
    if (templateID) {
      dispatch(setLoading(true));
      getTemplateAPIQuery({ id: templateID })
        .then((res) => {
          if ("data" in res && res.data && res.data.status === 200) {
            nftInfo.setFieldValue("title", res.data.data.title);
            nftInfo.setFieldValue("description", res.data.data.description);
            let temp: any = [];
            res.data.data.attributes.map((data: any, index: number) => {
              let item = {
                trait_type: data.trait_type,
                type: data.type,
                value: data.type === "date" ? handleDate(new Date()) : "",
                mimeType: ""
              };
              return temp.push(item);
            });
            nftInfo.setFieldValue("attributes", temp);
            nftInfo.setFieldValue("image", res.data.data.filePath);
            nftInfo.setFieldValue("logo", res.data.data.filePath);
            nftInfo.setFieldValue("templateId", res.data.data._id);
            handleImage(res.data?.data?.attributes);
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          if (err) {
            err?.data?.message && toastError(err.data.message);
          }
          navigate("/explore-nfts");
        });
    }
  };

  const getDraftAction = () => {
    dispatch(setLoading(true));
    templateID &&
      getDraftNftAPIQuery({ id: templateID })
        .then((res) => {
          if ("data" in res && res.data && res.data.status === 200) {
            nftInfo.setFieldValue("title", res.data.data.title);
            nftInfo.setFieldValue("description", res.data.data.description);
            let temp: any = [];
            res.data.data.attributes.map((data: any, index: number) => {
              let item = {
                trait_type: data.trait_type,
                type: data.type,
                value: data.value,
                mimeType: data.mimeType,
                fileName: data.fileName ? data.fileName : "",
                videoDuration: data?.videoDuration ? data.videoDuration : ""
              };
              return temp.push(item);
            });
            nftInfo.setFieldValue("attributes", temp);
            nftInfo.setFieldValue("image", res.data.data.filePath);
            nftInfo.setFieldValue("logo", res.data.data.filePath);
            nftInfo.setFieldValue("templateId", res.data.data.templateId);
            nftInfo.setFieldValue("walletAddress", res.data.data.walletAddress);

            if (res.data.data.visibility === 1) {
              nftInfo.setFieldValue("visibility", true);
            } else {
              nftInfo.setFieldValue("visibility", false);
            }
            handleImage(res.data?.data?.attributes);
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
  };

  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);

  const files: any = values?.attributes?.filter((obj: any) => {
    if (obj.type == "file") {
      return obj;
    }
  });

  const totalFileSize =
    files.reduce((accumulator: any, fileData: any) => {
      if (fileData.value && fileData.value.length > 0) {
        const file = fileData.value[0];
        const fileSize = file.size;
        if (!isNaN(fileSize)) {
          return accumulator + fileSize;
        }
      }
      return accumulator;
    }, 0) /
    (1024 * 1024);

  return !user?.isCustomUser && !IsCustomNftDisabled ? (
    <>
      {/* add wallet address validator script to dom */}
      <GlobalHelmetProvider title="Create Custom-NFT" script="https://cdn.jsdelivr.net/npm/wallet-address-validator@0.2.4/dist/wallet-address-validator.min.js" />
      <div className="create-custom-nft-wrapper  mb-0">
        <Container>
          <h2 className="title-lr c-tx-secondary f-700 text-center main-title">{handleTheLanguageTranslation(label?.create_custom_nft, "Create Custom NFT")} </h2>
          <Row className="justify-content-center">
            <Col md="8" xl="8">
              <div className="stepper-link-wrapper">
                <ul className="stepsblock">
                  <CurrentSteupComponent currentStep={values.currentStep} title={handleTheLanguageTranslation(label?.nft_information, "NFT Information")} step={0} />
                  <CurrentSteupComponent currentStep={values.currentStep} title={handleTheLanguageTranslation(label?.nft_attributes, "NFT Attributes")} step={1} />
                  <CurrentSteupComponent currentStep={values.currentStep} title={handleTheLanguageTranslation(label?.review_nft, "Review NFT")} step={2} />
                </ul>
              </div>
            </Col>
          </Row>
          <div className="tab">
            {values.currentStep === 1 && <NftInformation formik={nftInfo} />}
            {values.currentStep === 2 && <NftAttribute formik={nftInfo} validationAtribute={validationAtribute} />}
            {values.currentStep === 3 && <ReviewNft formik={nftInfo} />}
          </div>
          <Row className="justify-content-center">
            <Col md="8" xl="8">
              <div className="footer-step">
                <div className=" d-flex align-items-center  justify-content-between ">
                  {values.currentStep === 1 ? (
                    <div></div>
                  ) : (
                    <Button className="custom-grey dart-text" onClick={() => setFieldValue("currentStep", values.currentStep - 1)}>
                      {handleTheLanguageTranslation(label?.back, "Back")}
                    </Button>
                  )}

                  <div>
                    <span style={{ color: "red" }}>{totalFileSize >= 50 && values.currentStep === 2 ? "The total size of all uploaded files combined should not exceed 50MB" : ""} </span>
                    <Button
                      disabled={totalFileSize >= 50 && values.currentStep === 2}
                      className="custom-primary"
                      onClick={() => {
                        if (values.currentStep === 2) {
                          setValidationAtribute(true);
                          handleSubmit();
                        } else {
                          handleSubmit();
                          setValidationAtribute(false);
                        }
                      }}>
                      {handleTheLanguageTranslation(label?.next, "Next")}
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  ) : (
    <Page404 />
  );
};
