import { INftOwner } from "actions/app/get-nft";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { getFormattedDateWithMonthName, handleTheLanguageTranslation, toastSuccess } from "utils/functions/commonFunctions";
import { socialSites } from "utils/socialSites";

const UserProfileOverview = ({ userProfile }: { userProfile: INftOwner | undefined }) => {
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  return (
    <>
      <Row className="">
        {userProfile?.bio && (
          <Col md="12">
            <div className="profile-content-sm-wrapper c-mb-30">
              <div className="title">
                <h4 className="text-white">{handleTheLanguageTranslation(label?.bio, "Bio")}</h4>
                <p className="c-tx-third mb-0">{userProfile?.bio}</p>
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        {userProfile?.walletAddress && (
          <Col md="6" lg="6" xl="4">
            <div className="profile-content-sm-wrapper c-mb-30">
              <div className="title">
                <h4 className="text-white">{handleTheLanguageTranslation(label?.wallet_address, "Wallet address")}</h4>
                <p
                  className={`c-tx-third mb-0 text-break ${userProfile?.walletAddress && "cursor-pointer"}`}
                  onClick={() => {
                    userProfile?.walletAddress &&
                      navigator.clipboard.writeText(userProfile?.walletAddress).then((res) => {
                        toastSuccess("Wallet address copied to clipboard");
                      });
                  }}>
                  {userProfile?.walletAddress || "Add your wallet address to see your NFTs"}
                </p>
              </div>
            </div>
          </Col>
        )}
        
        {userProfile?.links?.website && (
          <Col md="6" lg="6" xl="4">
            <div className="profile-content-sm-wrapper c-mb-30">
              <div className="title">
                <h4 className="text-white">{handleTheLanguageTranslation(label?.website, "website")}</h4>
                <a className="c-tx-primary mb-0 text-decoration-none no-hover" href={userProfile.links.website} target="_blank" rel="noreferrer">
                  {userProfile?.links?.website}
                </a>
              </div>
            </div>
          </Col>
        )}

        {userProfile?.createdAt && (
          <Col md="6" lg="6" xl="2">
            <div className="profile-content-sm-wrapper c-mb-30">
              <div className="title">
                <h4 className="text-white">{handleTheLanguageTranslation(label?.joined, "Joined")}</h4>
                <p className="c-tx-third mb-0">{getFormattedDateWithMonthName(userProfile?.createdAt || "")}</p>
              </div>
            </div>
          </Col>
        )}
        {Object.entries(userProfile?.links || {}).filter(([key, value]) => Boolean(value) && key !== "website").length > 0 && (
          <Col md="6" lg="6" xl="4">
            <div className="profile-content-sm-wrapper c-mb-30">
              <div className="title">
                <h4 className="text-white">{handleTheLanguageTranslation(label?.social_links, "Social links")}</h4>
                {Object.entries(userProfile?.links || {}).map((item) => {
                  if (!Boolean(item[1]) || item[0] === "website") return null;
                  return (
                    <div className="c-tx-third d-flex" key={item[0]}>
                      <p className="me-2 f-700 " style={{ width: 80 }}>
                        {item[0]}:{" "}
                      </p>
                      <p className="c-tx-primary text-truncate" style={{ maxWidth: 200 }}>
                        <a href={`${socialSites[item[0]]}${item[1]}`} target="_blank" rel="noreferrer" className="text-decoration-none">
                          {item[1]}
                        </a>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};

export default UserProfileOverview;
