import { COMPRESSED_FILE_URL, FILE_URL } from "./type";

export const fileUrls = (payload: any) => {
  return {
    type: FILE_URL,
    payload
  };
};

export const compressedFileUrls = (payload: any) => {
  return {
    type: COMPRESSED_FILE_URL,
    payload
  };
};
