import { MintGateways } from "actions/enum/gateway-types";
import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const appGetSingleNft = (params: ISingleNftRequest): Promise<ISingleNFTReturn> => {
//   return HTTPService.get(apiEndPoints.app.GetNftList, { params });
// };

const appGetNftlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appGetNft: build.query<ISingleNFTReturn, ISingleNftRequest>({
      query: (params) => ({
        url: apiEndPoints.app.GetNftList,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "appGetNft", id: "appGetNft" }]
    })
  })
});

export const { useAppGetNftQuery, useLazyAppGetNftQuery } = appGetNftlice;

export interface ISingleNftRequest {
  id: string;
  deviceId: string;
  userId?: string;
}

export interface IRelatedNFT {
  fileMimeType: string;
  isLiked: boolean;
  title: string;
  description: string;
  nftId: number;
  tags: string[];
  _id: string;
  createdBy: string;
  filePath: string;
  fileThumbPath: string;
  createdAt: string;
  totalViews: number;
  totalLikes: number;
  isCustom: number;
  id: string;
}
export interface ISingleNftAttributes {
  trait_type: string;
  value: string;
  type: string;
  mimeType: string;
}
export interface ISingleNFTDATA {
  gatewayLogo: string;
  gatewayLogoBlack: string;
  gatewayLogoWhite: string;
  gatewayScan: String;
  fileThumbPath: string;
  attributes: ISingleNftAttributes[];
  title: string;
  description: string;
  nftId: number;
  tokenId: number;
  gateway: number;
  tags: string[];
  isLiked: boolean;
  isCustom: number;
  _id: string;
  filePath: string;
  fileMimeType: string;
  createdAt: string;
  totalViews: number;
  totalLikes: number;
  visibility: number;
  isBanned: boolean;
  reasonToBlock: string;
  collection?: {
    customUrl: string;
    featuredImage: string;
    logoImage: string;
    name: string;
    _id: string;
  };
  history: [
    {
      _id: string;
      event: string;
      fileId: string;
      from: string;
      to: string;
      gateway: MintGateways;
      createdAt: string;
      updatedAt: string;
    }
  ];
  relatedNFTs: IRelatedNFT[];
  user: INftOwner;
  txUrl: string;
  ipfsFileHash: string;
  ipfsJsonHash: string;
}

export interface INftOwner {
  status: number;
  totalNFTs: number;
  usedNFTs: number;
  _id: "string";
  profilePicture?: string;
  cropProfilePicture?: string;
  bannerImage: string;
  name: string;
  clientId: string;
  username: string;
  email: string;
  loginType: string;
  createdAt: string;
  updatedAt: string;
  bio: string;
  walletAddress: string;
  isVerified?: number;
  links: {
    twitter: string;
    facebook: string;
    instagram: string;
    youtube: string;
    website: string;
  };
}

export interface ISingleNFTReturn {
  data: ISingleNFTDATA;
  message: string;
  status: number;
}
