// logo images
import { IMintBlockchainListIcons } from "actions/app/mint-nft-price";
import { gateGatewayNames } from "actions/enum/gateway-types";
import { makeFullS3ObjectUrl } from "assets/images";

const CustomNFT = makeFullS3ObjectUrl("customNFT.png");
const folderImage = makeFullS3ObjectUrl("folder.png");
const photoLogoImage = makeFullS3ObjectUrl("photo.png");
const playLogoImage = makeFullS3ObjectUrl("play.png");
// const EdexaLogo = makeFullS3ObjectUrl("edexaLogo.png");
// const ArbitrumLogo = makeFullS3ObjectUrl("arbitrum.svg");
// const BinanceLogo = makeFullS3ObjectUrl("binance-logo.png");
// const EthereumLogo = makeFullS3ObjectUrl("ethereum-logo.png");
// const OptimismLogo = makeFullS3ObjectUrl("optimism-logo-white.svg");
// const PolygonLogo = makeFullS3ObjectUrl("polygon-logo.png");

export const getChainSelectOptions = (blockchainList: IMintBlockchainListIcons[]) => {
  let blockChainListFilterd = blockchainList.filter((val) => val.isEnable !== 2);

  return blockChainListFilterd.map((blockchain) => ({
    label: <img src={blockchain.iconWhite} className="chain-logo" alt="binance" title={gateGatewayNames(blockchain.gateway as number) ?? ""} />,
    value: blockchain.gateway
  }));
};

// export const chainSelectOptions = [
//   {
//     label: <img src={EdexaLogo} className="chain-logo" alt="binance" title="binance" />,
//     value: MintGateways.EDEXA
//   },
//   {
//     label: <img src={BinanceLogo} className="chain-logo" alt="binance" title="binance" />,
//     value: MintGateways.BINANCE_BLOCKCHAIN
//   },
//   {
//     label: <img src={PolygonLogo} className="chain-logo" alt="polygon" title="polygon" />,
//     value: MintGateways.POLYGON_BLOCKCHAIN
//   },
//   {
//     label: <img src={EthereumLogo} className="chain-logo" alt="ethereum" title="ethereum" />,
//     value: MintGateways.ETHEREUM_BLOCKCHAIN
//   },
//   {
//     label: <img src={OptimismLogo} className="chain-logo" alt="Optimism" title="Optimism" />,
//     value: MintGateways.Optimism
//   },
//   {
//     label: <img src={ArbitrumLogo} className="chain-logo" alt="Arbitrum" title="Arbitrum" />,
//     value: MintGateways.Arbitrum
//   }
// ];
export const NftTypeSelectOptionsNft = [
  {
    label: (
      <div>
        <img src={photoLogoImage} className="nft-type-image" alt="logo" title="Image" />
        <p className="mt-2"> Image </p>
      </div>
    ),
    value: "image"
  },
  {
    label: (
      <div>
        <img src={folderImage} className="nft-type-image" alt="File" title="File" />
        <p className="mt-2"> File </p>
      </div>
    ),
    value: "file",
    placeholder: "File"
  },
  {
    label: (
      <div>
        <img src={playLogoImage} className="nft-type-image" alt="video" title="Video" />
        <p className="mt-2"> Video </p>
      </div>
    ),
    value: "video"
  },
  {
    label: (
      <div className="text-center">
        <img src={CustomNFT} className="nft-type-image" alt="Custom NFT" title="Custom NFT" />
        <p className="mt-2"> Custom NFT </p>
      </div>
    ),
    value: "custom",
    placeholder: "Custom NFT"
  }
];

export const TagsOptions = [
  { value: "crypto", label: "Cryptocurrency" },
  { value: "nft", label: "Digital Asset" }
];
