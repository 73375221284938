import { INftListReturn, useLazyAppGetNftListQuery } from "actions/app/get-nft-list";
import { IUserPlan, useGetUserPlanInfoQuery } from "actions/user-plan";
import { makeFullS3ObjectUrl } from "assets/images";
import GlobalHelmetProvider from "components/GlobalHelmetProvider";
import UserProfileCounters from "components/profile/UserProfileCounters";
import UserProfileDetailCard from "components/profile/UserProfileDetailCard";
import UserProfileNFTsListing from "components/profile/UserProfileNFTsListing";
import UserProfileNFTsListingLoadMoreButtons from "components/profile/UserProfileNFTsListingLoadMoreButtons";
import UserProfileNFTsTabs from "components/profile/UserProfileNFTsTabs";
import WithErrorBoundaryHandler from "core/error/WithErrorBoundaryHandler";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { paginationKeysObject } from "utils/constants/constants";
import { handleTheLanguageTranslation } from "utils/functions/commonFunctions";
import "../styles/pages/profile.scss";
const ProfileBanner = makeFullS3ObjectUrl("profile-banner.png");

const UserProfilePage = () => {
  const { userId: currentProfileId } = useParams();
  const { deviceId, user_id: loggedInUserId } = useSelector((state: IRootReducer) => state.userDetailsReducer);
  const { isLoggedIn } = useSelector((state: IRootReducer) => state.auth);
  const [userNftsList, setUserNftsLIst] = useState<INftListReturn>();
  const [loading, setLoading] = useState(true);
  const [isAPILoading, setIsAPILoading] = useState(false);
  const [getNftListQuery] = useLazyAppGetNftListQuery();

  // query
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: userPricingPlan } = isLoggedIn ? useGetUserPlanInfoQuery() : { data: { data: {} as IUserPlan } };

  // nft pagination
  const [nftPagination, setNftPagination] = useState({
    ...paginationKeysObject,
    limit: 6
  });

  const checkIfRequestIsForCurrentUser = useCallback(() => {
    // if current profile id is not equal to loggedin userid then
    // return the currentProfile id and request data for that profile.
    if (currentProfileId !== loggedInUserId) {
      return currentProfileId;
    }
    // but if user is loggedIn then return empty string;
    // and request from the api for the current user profile.
    return isLoggedIn ? "" : currentProfileId;
  }, [currentProfileId, isLoggedIn, loggedInUserId]);

  const getUserDetailandHisNftListing = () => {
    if (deviceId !== "" && currentProfileId) {
      // setLoading(true);
      setIsAPILoading(true);
      getNftListQuery({
        userId: checkIfRequestIsForCurrentUser(),
        deviceId: deviceId,
        ...nftPagination
      })
        .then((response) => {
          setUserNftsLIst(response.data);
          setLoading(false);
          setIsAPILoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setIsAPILoading(false);
        });
    }
  };

  useEffect(() => {
    getUserDetailandHisNftListing();
    // window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProfileId, deviceId, nftPagination]);

  const loadMoreData = useCallback(() => {
    setNftPagination({
      ...nftPagination,
      limit: nftPagination.limit + paginationKeysObject.limit
    });
  }, [nftPagination]);

  // nft tab buttons
  const [activeTab, setActiveTab] = useState({
    mintedByMe: false,
    ownedByMe: false,
    overview: true
  });
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);

  return (
    <>
      <GlobalHelmetProvider title={`${userNftsList?.user?.name}` || "Account"} thumbnail={userNftsList?.user?.cropProfilePicture} description={userNftsList?.user?.bio} />
      <div className="profile-wrapper">
        <div className="profile-banner image-loader">
          {!loading ? userNftsList?.user?.bannerImage ? <img src={userNftsList?.user?.bannerImage} alt="profile-banner" /> : <img src={ProfileBanner} alt="profile-banner" /> : ""}
          {!isAPILoading && isLoggedIn && !checkIfRequestIsForCurrentUser() && (
            <Link to={"edit"} className="capsual-block edit-bl text-decoration-none" role={"button"} state={{ modalOpen: true }}>
              {handleTheLanguageTranslation(label?.manage_profile, "Manage Profile")}
            </Link>
          )}
        </div>
        <Container>
          <div className="profile-detail-section">
            <Row className="justify-content-center">
              <Col md="12" sm="12" lg="6">
                <UserProfileDetailCard
                  userNftsList={userNftsList}
                  isLoggedIn={isLoggedIn}
                  loading={loading}
                  isAPILoading={isAPILoading}
                  toggleBioModal={() => {}}
                  checkIfRequestIsForCurrentUser={checkIfRequestIsForCurrentUser}
                />
              </Col>
              <Col md="12" sm="12" lg="6" className="justify-content-end">
                <UserProfileCounters
                  userNftsList={userNftsList}
                  isLoggedIn={isLoggedIn}
                  loading={loading}
                  checkIfRequestIsForCurrentUser={checkIfRequestIsForCurrentUser}
                  userPlan={userPricingPlan?.data}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="12" sm="12" lg="12">
                <UserProfileNFTsTabs
                  userNftsList={userNftsList}
                  isLoggedIn={isLoggedIn}
                  loading={loading}
                  checkIfRequestIsForCurrentUser={checkIfRequestIsForCurrentUser}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                />
                <UserProfileNFTsListing userNftsList={userNftsList} loading={loading} activeTab={activeTab} />

                <UserProfileNFTsListingLoadMoreButtons
                  userNftsList={userNftsList}
                  checkIfRequestIsForCurrentUser={checkIfRequestIsForCurrentUser}
                  activeTab={activeTab}
                  loadMoreData={loadMoreData}
                  isAPILoading={isAPILoading}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* {bioUpdateModalIsOpen && <EditUserProfileModal modalVisibilityState={bioUpdateModalIsOpen} toggleBioModal={toggleBioModal} toggleRefreshUserProfile={toggleRefreshUserProfile} />} */}
    </>
  );
};

export default React.memo(WithErrorBoundaryHandler(UserProfilePage));
