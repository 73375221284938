import { MintGateways } from "actions/enum/gateway-types";
import { makeFullS3ObjectUrl } from "assets/images";
import GlobalHelmetProvider from "components/GlobalHelmetProvider";
import useTheme, { THEMETYPES } from "hooks/useTheme";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Row, Spinner } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { PAYMENT_STATUS, cookieKeys } from "utils/constants/constants";
import { getDecryyptedCookie, handleTheLanguageTranslation, removedCookie } from "utils/functions/commonFunctions";
import { getQueryStringParams } from "utils/getQueryParamsFromString";
import { socket } from "utils/socket";
import Page404 from "./404Page";
const SuccessLogo = makeFullS3ObjectUrl("check-logo.png");
const WrongLogo = makeFullS3ObjectUrl("wrong.png");

const TransactionSuccess = ({ gateway, nftId, customNftTemplate }: { gateway?: MintGateways; nftId: string; customNftTemplate?: number }) => {
  const [mintStatus, setMintStatus] = useState({
    isLoading: true,
    isError: false,
    mintedNftId: ""
  });
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  const navigate = useNavigate();

  useEffect(() => {
    if (nftId) {
      // listen for nft mint after payment success
      socket.on(`nft_minted_${nftId}`, (data) => {
        if (data?.status === 200) {
          setMintStatus({
            isLoading: false,
            isError: false,
            mintedNftId: data?.nftId
          });
        }
      });
    }
    return () => {
      if (nftId) {
        socket.off(`nft_minted_${nftId}`);
      }
    };
  }, [nftId]);

  return (
    <Row>
      {
        <Col sm="12" className="text-center payment">
          <img src={SuccessLogo} style={{ width: 60 }} alt="check" />
          <h2 className="payment-heading cy-payment-success">{handleTheLanguageTranslation(label?.your_transaction_is_successful, "Your transaction is Successful")}</h2>
          {/* <p>Your transaction ID is 2546-8754-5123</p> */}
          <p>
            {nftId ? (
              mintStatus.isLoading ? (
                "Minting your NFT..."
              ) : mintStatus.isError ? (
                <span className="c-tx-primary">Failed to mint the nft</span>
              ) : (
                <span>
                  {handleTheLanguageTranslation(label?.NFT_minted_successfully_visit, "NFT minted successfully, visit")}{" "}
                  <Link to={`../../assets/${mintStatus.mintedNftId}`} className="cypress-nft-detail">
                    {handleTheLanguageTranslation(label?.NFT_detail_page, "NFT Detail Page")}
                  </Link>
                </span>
              )
            ) : (
              ""
            )}
          </p>
          <Button
            onClick={() => {
              navigate(nftId ? "/invoice" : "/my-credits", { replace: true });
            }}
            className="custom-primary-outline dark-text btn btn-secondary mt-0">
            {nftId ? "Go to Invoice Page" : "Go to My Credits Page"}
          </Button>
        </Col>
      }
    </Row>
  );
};

const TransactionFailed = () => {
  const navigate = useNavigate();
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  return (
    <Row>
      {
        <Col sm="12" className="text-center payment">
          <img src={WrongLogo} style={{ width: 60 }} alt="check" />
          <h2 className="payment-heading cy-payment-failed">{handleTheLanguageTranslation(label?.your_transaction_is_failed, "Your transaction is Failed")}</h2>
          <Button
            onClick={(e) => {
              navigate(-1);
            }}
            className="custom-primary-outline dark-text btn btn-secondary mt-0">
            {handleTheLanguageTranslation(label?.try_again, "Try Again")}
          </Button>
        </Col>
      }
    </Row>
  );
};
const TransactionCancelled = () => {
  const navigate = useNavigate();
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  return (
    <Row>
      {
        <Col sm="12" className="text-center payment">
          <img src={WrongLogo} style={{ width: 60 }} alt="check" />
          <h2 className="payment-heading cy-payment-failed">{handleTheLanguageTranslation(label?.your_transaction_is_cancelled, "Transaction is cancelled by user")}</h2>
          <Button
            onClick={(e) => {
              navigate(-1);
            }}
            className="custom-primary-outline dark-text btn btn-secondary mt-0">
            {handleTheLanguageTranslation(label?.try_again, "Try Again")}
          </Button>
        </Col>
      }
    </Row>
  );
};

export const TransactionInProgress = () => {
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  return (
    <Row>
      <Col sm="12" className="text-center payment">
        <Spinner className="c-tx-third" />
        <h2 className="payment-heading">
          {handleTheLanguageTranslation(label?.your_transaction_is_in_progress, "Your transaction is in progress")}
          ...
        </h2>
        <p className="c-tx-third">{handleTheLanguageTranslation(label?.dont_hit_refresh_or_reload_the_page, "Don't hit refresh or reload the page.")}</p>
      </Col>
    </Row>
  );
};

/**
 * @abstract
 * For Stripe payment we are listening for the stripe success event to show the user respective status page.
 *
 * @How_paypal_Payment_Validation_Flow_Works
 * For paypal, we have to redirect user to paypal's site so to keep a track of payment :-
 *  - we set a local `paypal payment initiated` cookie when user select paypal and click on pay now.
 *  - now after the payment is success the user will be redirected back to current site with ?success flag.
 *    We are validating that if the localStorage cookie present and success flag is true then shwo payment success page.
 *  - Otherwise if no localStorage cookie or the payment is failed show him/her the payment failed.
 *
 * @returns {JSX}
 */
const MintNFTPaymentStatusPage = () => {
  useTheme(THEMETYPES.LIGHT, []);

  // manage payment status state
  // this state is usefull in the case of payment done by third party service and return as back to callback url page.
  const [paymentSuccess, setPaymentSuccess] = useState<PAYMENT_STATUS>(PAYMENT_STATUS.PENDING);

  // get page state when payment is done by stripe
  const location = useLocation();
  const pageState = (location.state as { paymentStatus: boolean; nftId: string }) || {};
  const { paymentStatus, nftId } = pageState || {};
  const [paypalPaymentNftId] = useState(getDecryyptedCookie(cookieKeys.nftId));

  const [notFoundROute, setNotFOundROute] = useState<null | JSX.Element>(null);

  /**
   * @description -
   * We check if there is any search params/query strings present in the url
   *    - Because in the case of paypal payment, paypal is going to redirect us with query string
   * The query string will be like this - `?gateway=paypal&status=success`
   *    - If its success then we show payment success page
   *
   * If not any search params then we the payment is from stripe and we will receive page state.
   *   - If the page state has no nftId that means user visit the page directly so -
   *   - Redirect him back to home page.
   *
   */
  const navigate = useNavigate();

  const removePaypalCookies = () => {
    // remove paypal cookies which was set to validate the payment
    removedCookie(cookieKeys.nftId);
    removedCookie(cookieKeys.paypalPayment);
  };

  useEffect(() => {
    // Check if request is for paypal
    if (location.search) {
      /**
       * Check if the payment is genuinly initiated by user or not. If user is visiting the page directly that means he is visiting the page with query string directly so redirect him to root page.
       */
      if (getDecryyptedCookie(cookieKeys.paypalPayment)) {
        let params = getQueryStringParams<{
          status?: string;
          gateway?: string;
        }>(location.search);
        if (
          // if we need to validate gateway also then activate this code
          // params.gateway === PAYMENT_GATEWAY.PAYPAL &&
          params.status === PAYMENT_STATUS.SUCCESS
        ) {
          setPaymentSuccess(PAYMENT_STATUS.SUCCESS);
        } else if (params.status === PAYMENT_STATUS.FAILED) {
          setPaymentSuccess(PAYMENT_STATUS.FAILED);
        } else if (params.status === PAYMENT_STATUS.CANCELLED) {
          setPaymentSuccess(PAYMENT_STATUS.CANCELLED);
        }
        removePaypalCookies();
      } else {
        removePaypalCookies();
        setPaymentSuccess(PAYMENT_STATUS.SUCCESS);
        setNotFOundROute(<Page404 />);
      }

      // if the request is for stripe and the nft id does not present then show 404 page;
    } else if (!nftId) {
      removePaypalCookies();
      setPaymentSuccess(PAYMENT_STATUS.FAILED);
      setNotFOundROute(<Page404 />);
    }
  }, [navigate, nftId, location.search]);

  // if the route is not valid [user is trying to visit it directly without any token/cookie] then render 404 page.
  return paymentStatus === false ? (
    <>
      <GlobalHelmetProvider title="Mint Payment" />
      <div className="payment-flow-wrapper">
        <TransactionFailed />
      </div>
    </>
  ) : (
    notFoundROute ||
      (nftId || paypalPaymentNftId || location.search ? (
        <>
          <GlobalHelmetProvider title="Mint Payment" />
          <div className="payment-flow-wrapper">
            {/* <TransactionInProgress /> */}
            {paymentStatus === true || paymentSuccess === PAYMENT_STATUS.SUCCESS ? (
              <TransactionSuccess nftId={nftId || paypalPaymentNftId} />
            ) : paymentSuccess === PAYMENT_STATUS.CANCELLED ? (
              <TransactionCancelled />
            ) : (
              <TransactionFailed />
            )}
          </div>
        </>
      ) : (
        <></>
      ))
  );
};

export default MintNFTPaymentStatusPage;
