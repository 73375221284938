export const USERDATA = "USERDATA";

export const DEVICEID = "DEVICEID";

export const USERIDANDNAME = "USERIDANDNAME";

export const NOTIFICATION_ON = "NOTIFICATION_ON";

export const SET_IS_ACCESS = "SET_IS_ACCESS";

export const SET_WALLETADDRESS = "SET_WALLETADDRESS";
