import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";
import { paginationKeysObject } from "utils/constants/constants";

const invoiceListApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    getInvoiceListAPI: build.query<IGetUserInvoiceAPIResponse, typeof paginationKeysObject>({
      query: (params) => ({
        url: apiEndPoints.listInvoices,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "getInvoiceListAPIQuery", id: "getInvoiceListAPIQuery" }]
    })
  })
});

export const { useGetInvoiceListAPIQuery, useLazyGetInvoiceListAPIQuery } = invoiceListApiSlice;

export interface IUserInvoice {
  nftId: null;
  purchasePlanType: string;
  transactionId: null;
  invoiceId: string;
  nftTitle: string;
  created: null;
}

export interface IGetUserInvoiceAPIResponse {
  status: number;
  message: string;
  data: { transactionData: IUserInvoice[]; count: number };
}
