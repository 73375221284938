import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const appUpdateUserProfile = (params: IProfileUpdateRequest | FormData): Promise<IUserProfileUpdateResult> => {
//   return HTTPService.put(apiEndPoints.app.UpdateProfile, params);
// };

const updateUserProfileApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appUpdateUserProfile: build.mutation<IUserProfileUpdateResult, IProfileUpdateRequest | FormData>({
      query: (data) => ({
        url: apiEndPoints.app.UpdateProfile,
        data: data,
        method: "put"
      })
    })
  })
});

export const { useAppUpdateUserProfileMutation } = updateUserProfileApiSlice;

interface IProfileUpdateRequest {
  bio?: string;
  walletAddress?: string;
  language?: string;
}

export interface IUserProfileUpdateResult {
  status: number;
  message: string;
}
