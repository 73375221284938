import { motion } from "framer-motion";
import React from "react";
import Skeleton from "react-loading-skeleton";

const CardLoadingSkelton = () => {
  return (
    <motion.div className="detail-collection-wrapper " initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
      <div className="img-wrapper">
        <Skeleton count={1} baseColor="#f8f8f8" highlightColor="#ffffff" height="100%" width="100%" />
      </div>
      <div className="content-block">
        <div className="visual-block ">
          <h3 className="c-tx-secondary mb-0 f-700 lh-26 nft-card-title">
            <Skeleton count={1} baseColor="#f8f8f8" highlightColor="#ffffff" height={15} width="100%" />
          </h3>
          <ul className="counter-list">
            <li>
              <Skeleton count={1} baseColor="#f8f8f8" highlightColor="#ffffff" height={15} width={100} />
            </li>
          </ul>
          <div className="date-block">
            <Skeleton count={1} baseColor="#f8f8f8" highlightColor="#ffffff" height={15} width={150} />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default CardLoadingSkelton;
