import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const appGetLandingPageData = (): Promise<ILandingPageAPIReturns> => {
//   return HTTPService.get(apiEndPoints.app.LandingPageData);
// };

const appGetLandingPageDataslice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appGetLandingPageData: build.query<ILandingPageAPIReturns, void>({
      query: () => ({ url: apiEndPoints.app.LandingPageData, method: "get" })
    })
  })
});

export const { useAppGetLandingPageDataQuery, useLazyAppGetLandingPageDataQuery } = appGetLandingPageDataslice;

export interface ISingleNftDataFromLandingPage {
  title: string;
  nftId: number;
  _id: string;
  compressedFilePath: string;
  fileThumbPath: string;
  fileMimeType: string;
  description: string;
  username: string;
}

export interface INotableNftData extends ISingleNftDataFromLandingPage {
  totalViews: number;
  totalLikes: number;
}

export interface ILandingPageAPIReturns {
  status: number;
  message: string;
  nftData: ISingleNftDataFromLandingPage;
  notableNftData: INotableNftData[];
}
