import React, { ComponentType, MemoExoticComponent } from "react";
import ComponentErrorFallBackUi from "./ComponentErrorFallBackUI";

/**
 *
 * @param {React.FunctionComponent} WrappedComponent the actual component
 * @param {string} name the name of the component for better UX
 * @returns a jsx element wrapped with error boundary
 *
 * If the component has an error it will show an alert card with related error message
 * like - There was an error loading the Top Users Reload this page
 *
 * How to use -
 *
 * `export default WithErrorBoundaryHandler(TopUserSection, "Top Users");`
 */
const WithErrorBoundaryHandler = (WrappedComponent: React.FunctionComponent | MemoExoticComponent<any>, name?: string) => {
  const Component = <Type, K extends ComponentType>(props: Type | K) => {
    return (
      <ComponentErrorFallBackUi name={name || "Component"}>
        <WrappedComponent {...props} />
      </ComponentErrorFallBackUi>
    );
  };
  return Component;
};

export default WithErrorBoundaryHandler;
