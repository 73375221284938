import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";
import { INftOwner } from "./get-nft";

// export const appGetNftList = (params: INftListRequest): Promise<INftListReturn> => {
//   return HTTPService.get(apiEndPoints.app.GetNftList, { params });
// };

const appGetNftListlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appGetNftList: build.query<INftListReturn, INftListRequest>({
      query: (params) => ({
        url: apiEndPoints.app.GetNftList,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "appGetNftList", id: "appGetNftList" }]
    })
  })
});

export const { useAppGetNftListQuery, useLazyAppGetNftListQuery } = appGetNftListlice;

export interface INftListRequest {
  page?: number;
  limit?: number;
  search?: string;
  deviceId?: string;
  userId?: string;
  collectionId?: string;
}

export interface ISingleNftDataFromNftLIst {
  visibility: number;
  fileThumbPath: string;
  filePath: string;
  fileMimeType: string;
  title: string;
  nftId: number;
  _id: string;
  createdBy: string;
  compressedFilePath: string;
  createdAt: string;
  isLiked: boolean;
  totalViews: number;
  totalLikes: number;
  isCustom: number;
  collectionId: string;
  isBanned: boolean;
}

export interface INftListReturn {
  status: number;
  message: string;
  count: {
    NFTs?: number;
    drafts: number;
    mintedByMeCount: number;
    ownByMeCount: number;
  };
  data: {
    mintedByMe: ISingleNftDataFromNftLIst[];
    ownByMe: ISingleNftDataFromNftLIst[];
  };
  user?: INftOwner;
}
