import { Component, ErrorInfo, ReactNode } from "react";
import { Alert } from "reactstrap";

interface Props {
  children: ReactNode;
  name: string;
}

interface State {
  hasError: boolean;
  error: Error;
  errorInfo?: ErrorInfo | null;
}

class ComponentErrorFallBackUi extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: new Error(),
    errorInfo: null
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ errorInfo });
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Alert color="danger">
          <p className="text-center mb-0">
            There was an error loading the {this.props.name}{" "}
            <span
              onClick={() => {
                window.location.reload();
              }}
              className="c-tx-primary"
              role={"button"}>
              Reload this page
            </span>
          </p>

          {process.env.REACT_APP_ENV !== "PRODUCTION" && (
            <details className="error-details">
              <summary style={{ fontSize: 16, fontWeight: "bold" }}>Click for error details</summary>
              <p className="mb-0 mt-3">{this.state.error.toString()}</p>
              <pre>{this.state.errorInfo && this.state?.errorInfo?.componentStack.toString()}</pre>
            </details>
          )}
        </Alert>
      );
    }

    return this.props.children;
  }
}

export default ComponentErrorFallBackUi;
