import { faEye, faHeart as Heartb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BannedNFTIcon from "components/BannedNFTIcon";
import CustomNFTIcon from "components/CustomNFTIcon";
import FavouriteIcon from "components/FavouriteIcon";
import RenderRespectiveMediaTag from "components/global/RenderRespectiveMediaTag";
import PrivateNFTIcon from "components/PrivateNFTIcon";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IRootReducer } from "store/root-reducer";
import { getFormattedDateWithMonthName, handleTheLanguageTranslation } from "utils/functions/commonFunctions";

export interface ILikestateState {
  likes: number | undefined;
  isFavourite: boolean | undefined;
}

const NftDetailCardComponent = ({
  createdDate,
  artName,
  views,
  likes,
  nftArtURL,
  nftId,
  isFavourite,
  thumbnailUri,
  fileMimeType,
  visibility,
  isCustom,
  videoDuration,
  isBanned
}: ICollectionListItem) => {
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  const [likeState, setLikeState] = useState<ILikestateState>({
    likes,
    isFavourite
  });

  return (
    <div className={"detail-collection-wrapper "} id={nftId}>
      <div className="text-decoration-none c-tx-secondary ">
        <Link to={`/assets/${nftId}`} className="text-decoration-none c-tx-secondary ">
          <div className={`img-wrapper ${nftArtURL ? "bg-dark" : "image-loader"}`}>
            {/* fetch respective media type tag */}
            <RenderRespectiveMediaTag
              mediaUri={nftArtURL}
              nftId={nftId}
              artName={artName}
              thumbnailUri={fileMimeType?.includes("gif") ? nftArtURL : thumbnailUri}
              fileMimeType={fileMimeType || ""}
              videoDuration={videoDuration}
            />
            <div className="d-flex flex-column nft-card-action-container">
              {nftId && <FavouriteIcon isFavourite={likeState?.isFavourite} nftId={nftId} setLikeState={setLikeState} />}
              <PrivateNFTIcon visibility={visibility} />
              <CustomNFTIcon visibility={isCustom} />
              <BannedNFTIcon isBanned={isBanned} />
            </div>
          </div>
          <div className="content-block">
            <div className="visual-block ">
              <h3
                className="c-tx-secondary mb-0 f-700 lh-26 nft-card-title"
                role={"button"}
                onClick={() => {
                  setTimeout(() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth"
                    });
                  }, 0);
                }}
                title={artName}>
                {artName}
              </h3>
              <ul className="counter-list">
                {nftId && (
                  <>
                    <li>
                      <h4 className="c-tx-forth mb-0">
                        <span className="icon mr-1">{<FontAwesomeIcon icon={faEye} />}</span>
                        <span className="ct-count text-normal">{views}</span>
                      </h4>
                    </li>
                    <li>
                      <h4 className="c-tx-forth mb-0">
                        <span className="icon mr-1">{<FontAwesomeIcon icon={Heartb} />}</span>
                        <span className="ct-count text-normal">{likeState?.likes && likeState?.likes >= 0 ? likeState?.likes : 0}</span>
                      </h4>
                    </li>
                  </>
                )}
              </ul>
              <div className="date-block">
                {nftId && (
                  <>
                    <h4 className="draft-date">{handleTheLanguageTranslation(label?.created_date, "Created date")}</h4>
                    <h4 className="c-tx-secondary mb-0  f-700">{getFormattedDateWithMonthName(createdDate || "")}</h4>
                  </>
                )}
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export const NftDetailCard = React.memo(NftDetailCardComponent);
export interface ICollectionListItem {
  createdDate?: string;
  artName?: string;
  views?: number;
  likes?: number;
  nftArtURL?: string;
  nftId?: string;
  isFavourite?: boolean;
  thumbnailUri?: string;
  index?: string | number;
  visibility?: number;
  fileMimeType: string;
  isCustom: number;
  videoDuration?: number;
  isBanned?: boolean;
}
