import { FC, useState } from "react";

const UserImage: FC<IUserImage> = ({ src, ...rest }) => {
  const [state, setState] = useState({
    error: false,
    defultSRC: "https://edexa-portal.s3.eu-central-1.amazonaws.com/users/defaultImg.png"
  });
  return <img width="100%" height="100%" src={state.error ? state.defultSRC : src} alt="User Profile" onError={() => setState({ ...state, error: true })} {...rest} />;
};

export default UserImage;

interface IUserImage {
  src?: string;
  className?: string;
}
