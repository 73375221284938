import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementLocale } from "@stripe/stripe-js";
import React, { ComponentType, MemoExoticComponent } from "react";
import { useSelector } from "react-redux";
import { IRootReducer } from "store/root-reducer";

// create stripe promise with stripe key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

const WithStripeProvider = (WrappedComponent: React.FunctionComponent<any> | MemoExoticComponent<any>) => {
  const Component = <Type, K extends ComponentType>(props: Type | K) => {
    const currentLanguage = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.languageType) || {};
    return (
      <Elements
        stripe={stripePromise}
        options={{
          locale: currentLanguage.toLowerCase() as StripeElementLocale
        }}>
        <WrappedComponent {...props} />
      </Elements>
    );
  };
  return Component;
};

export default WithStripeProvider;
