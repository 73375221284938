import { useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardNumberElement } from "@stripe/stripe-js";
import { PAYMENT_GATEWAY } from "actions/enum/payment-type";
import { useGetPricingPlanLIstQuery } from "actions/pricing-plan-list";
import { useAddWalletCreditsMutation, WALLET_PAYMENT_PLATFORM } from "actions/wallet/add-wallet-credits";
import { useFormik } from "formik";
import useLoadStripe from "hooks/useLoadStripe";
import useScrollTheReference from "hooks/useScrollTheReference";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardDetailsFormYupValidationSchema } from "schema/CardDetailsFormYupValidationSchema";
import { IRootReducer } from "store/root-reducer";
import { cookieKeys } from "utils/constants/constants";
import { setEncryptedCookie, toastError, toastSuccess } from "utils/functions/commonFunctions";

const useWalletAddCreditsPaymentModel = (amount: number, paymentGateway: PAYMENT_GATEWAY) => {
  const [tokenLoading, setTokenLoading] = useState(false);
  const navigate = useNavigate();
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  const { data: pricingPlanLists } = useGetPricingPlanLIstQuery();

  // scroll the mintwrapper form when user move mouse wheel
  const mintWrapperRef = useRef<HTMLDivElement>(null);
  useScrollTheReference(mintWrapperRef);

  const paymentFormik = useFormik({
    initialValues: {
      nameOnCard: ""
    },
    validationSchema: CardDetailsFormYupValidationSchema(paymentGateway, label),
    onSubmit: (values) => {
      // if amount is not > 0 then return
      if (amount <= 0) return;
      if (paymentGateway === PAYMENT_GATEWAY.STRIPE) {
        createPaymentWithStripe();
      } else if (paymentGateway === PAYMENT_GATEWAY.PAYPAL) {
        /**
         * If we are making payment for nft mint then the planId will be 5th only.
         *
         * But if we are on wallet screen and adding credits to wallet then wallet Id will be 4 for paypal
         */
        let myPlanId = pricingPlanLists?.data.find((plan) => plan.type === 4)?._id;

        let paymentAPIParams = {
          price: amount,
          type: WALLET_PAYMENT_PLATFORM.WEB,
          planId: myPlanId || ""
        };
        callCreatePaymentMutation(paymentAPIParams)
          .then((result: any) => {
            if ("data" in result && result.data.status === 200) {
              // set cookie to keep track that payment is initiated
              // in case if user directly visits the payment page with query params and we do not find cookie then the user is not legit.
              setEncryptedCookie(cookieKeys.paypalPayment, true);
              // redirect to paypal payment page.
              window.location.href = result.data.data;
            } else {
              throw new Error(result.error.data.message);
            }
          })
          .catch((err) => {
            navigate("/payment", { state: { paymentStatus: false } });
            // toastError(err?.message);
          });
      }
    }
  });
  const { values } = paymentFormik;

  // stripe and create payment operations
  const [callCreatePaymentMutation, { status: apiLoadingStatus }] = useAddWalletCreditsMutation();
  useLoadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");
  const elements = useElements();
  const stripe = useStripe();
  //  first create stripe token
  // then call the payment api in backend with this token
  const createPaymentWithStripe = async () => {
    setTokenLoading(true);
    function callPaymentApi(stripeToken: string) {
      /**
       * If we are making payment for nft mint then the planId will be 5th only.
       *
       * But if we are on wallet screen and adding credits to wallet then wallet Id will be 3 for stripe
       */
      let myPlanId = pricingPlanLists?.data.find((plan) => plan.type === 3)?._id;

      let paymentAPIParams = {
        price: amount,
        type: WALLET_PAYMENT_PLATFORM.WEB,
        // sending the default plan id this is just a placeholder
        planId: myPlanId || "",
        token: stripeToken
      };
      callCreatePaymentMutation(paymentAPIParams)
        .then((result: any) => {
          if ("data" in result && result.data.status === 200) {
            toastSuccess(`${amount} USD credited to your wallet successfully`);
          } else {
            throw new Error(result.error.data.message);
          }
        })
        .catch((err) => {
          toastError(err?.message);
        });
    }

    // if stripe integration is not correct it will throw an error
    // handling that error and setting loading to false
    try {
      stripe?.createToken(elements?.getElement("cardNumber") as StripeCardNumberElement, { name: values.nameOnCard }).then((result) => {
        if ("token" in result && result.token) {
          setTokenLoading(false);
          callPaymentApi(result.token.id);
        } else if ("error" in result && result.error.message) {
          setTokenLoading(false);
          toastError(result.error.message);
        }
      });
    } catch (error) {
      setTokenLoading(false);
      alert(error);
    }
  };
  return { tokenLoading, paymentFormik, apiLoadingStatus };
};

export default useWalletAddCreditsPaymentModel;
