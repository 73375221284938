import { INftListReturn } from "actions/app/get-nft-list";
import CardLoadingSkelton from "components/CardLoadingSkelton";
import { NftDetailCard } from "components/nftdetail/NftDetailCard";
import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { handleTheLanguageTranslation } from "utils/functions/commonFunctions";
import UserProfileOverview from "./UserProfileOverview";

export interface IUserProfileNFTsListingPropsType {
  loading: boolean;

  userNftsList: INftListReturn | undefined;
  activeTab: {
    mintedByMe: boolean;
    ownedByMe: boolean;
    overview: boolean;
  };
}

const UserProfileNFTsListing = ({ userNftsList, activeTab, loading }: IUserProfileNFTsListingPropsType) => {
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  return (
    <>
      <Row>
        {/* show public user profile data [not current logged in user]*/}
        {!activeTab.overview &&
          Array.isArray(userNftsList?.data) &&
          userNftsList?.data?.map((nftItem, index) => {
            return (
              <Col md="6" lg="4" sm="6" key={nftItem._id}>
                <NftDetailCard
                  artName={nftItem.title}
                  views={nftItem.totalViews}
                  likes={nftItem.totalLikes}
                  createdDate={nftItem.createdAt}
                  nftArtURL={nftItem.isCustom ? nftItem.filePath : nftItem.compressedFilePath}
                  nftId={nftItem._id}
                  isFavourite={nftItem.isLiked}
                  thumbnailUri={nftItem.isCustom ? nftItem.filePath : nftItem.fileThumbPath}
                  fileMimeType={nftItem.fileMimeType}
                  isCustom={nftItem.isCustom}
                />
              </Col>
            );
          })}
        {/* show owned by me nfts only */}
        {activeTab.ownedByMe &&
          userNftsList?.data?.ownByMe?.map((nftItem, index) => {
            return (
              <Col md="6" lg="4" sm="6" key={nftItem._id}>
                <NftDetailCard
                  artName={nftItem.title}
                  views={nftItem.totalViews}
                  likes={nftItem.totalLikes}
                  createdDate={nftItem.createdAt}
                  nftArtURL={nftItem.isCustom ? nftItem.filePath : nftItem.compressedFilePath}
                  nftId={nftItem._id}
                  isFavourite={nftItem.isLiked}
                  thumbnailUri={nftItem.isCustom ? nftItem.filePath : nftItem.fileThumbPath}
                  visibility={nftItem.visibility}
                  fileMimeType={nftItem.fileMimeType}
                  isCustom={nftItem.isCustom}
                  isBanned={nftItem.isBanned}
                />
              </Col>
            );
          })}
        {/* show minted by me nfts only */}
        {activeTab.mintedByMe &&
          userNftsList?.data?.mintedByMe?.map((nftItem, index) => {
            return (
              <Col md="6" lg="4" sm="6" key={nftItem._id}>
                <NftDetailCard
                  artName={nftItem.title}
                  views={nftItem.totalViews}
                  likes={nftItem.totalLikes}
                  createdDate={nftItem.createdAt}
                  nftArtURL={nftItem.isCustom ? nftItem.filePath : nftItem.compressedFilePath}
                  nftId={nftItem._id}
                  isFavourite={nftItem.isLiked}
                  thumbnailUri={nftItem.isCustom ? nftItem.filePath : nftItem.fileThumbPath}
                  visibility={nftItem.visibility}
                  fileMimeType={nftItem.fileMimeType}
                  isCustom={nftItem.isCustom}
                  isBanned={nftItem.isBanned}
                />
              </Col>
            );
          })}

        {loading &&
          [0, 0, 0].map((nftItem, index) => {
            return (
              // generate unique key with +500 for skelton
              <Col md="6" lg="4" sm="6" key={index + 500}>
                <CardLoadingSkelton />
              </Col>
            );
          })}
        {activeTab.overview && Array.isArray(userNftsList?.data) && userNftsList?.count?.NFTs! <= 0 && (
          <Col>
            <p className="text-center c-tx-third">
              {handleTheLanguageTranslation(label?.no_nfts_found_make_sure_you_have_added_a_correct_wallet_address, "No NFTs found, make sure you have added a correct wallet address")}
            </p>
          </Col>
        )}
        {/* User profile overview */}
        {activeTab.overview && <UserProfileOverview userProfile={userNftsList?.user} />}
        {/* if current tab is owned by me and userNftList have no data in ownByMe array then show this */}
        {activeTab.ownedByMe && userNftsList?.count?.ownByMeCount! <= 0 && (
          <Col>
            <p className="text-center c-tx-third">
              {handleTheLanguageTranslation(label?.no_nfts_found_make_sure_you_have_added_a_correct_wallet_address, "No NFTs found, make sure you have added a correct wallet address")}
            </p>
          </Col>
        )}
        {/* if current tab is minted by me and userNftList have no data in minted by me array then show this */}
        {activeTab.mintedByMe && userNftsList?.count?.mintedByMeCount! <= 0 && (
          <Col>
            <p className="text-center c-tx-third">{handleTheLanguageTranslation(label?.no_nfts_minted_yet, "No NFTs minted yet")}</p>
          </Col>
        )}
      </Row>
    </>
  );
};

export default React.memo(UserProfileNFTsListing);
