import { ISVISIBLE, RESETCONFIRMATIONPOPUP } from "./type";

export interface IConfirmationPopup {
  isVisible: boolean;
  title?: string;
  paragraph?: string;
  deleteIcon?: boolean;
  successButtonText?: string;
  cancelButtonText?: string;
  actionFunction?: Function;
}
const initialStateOfConfirmationPopup: IConfirmationPopup = {
  isVisible: false,
  title: "Confirmation",
  paragraph: "Are you sure you want to delete NFT draft?",
  deleteIcon: false,
  successButtonText: "Confirm",
  cancelButtonText: "Cancel",
  actionFunction: () => {}
};

export const ConfimationPopupReducer = (state = initialStateOfConfirmationPopup, action: { type: string; payload: IConfirmationPopup }) => {
  let newState = { ...state };

  switch (action.type) {
    case ISVISIBLE:
      newState = { ...newState, ...action.payload };
      break;
    case RESETCONFIRMATIONPOPUP:
      newState = { ...initialStateOfConfirmationPopup };
      break;
  }

  return newState;
};
