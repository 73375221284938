export const SETSTEP = "SETSTEP";

export const SETATTACHMENTS = "SETATTACHMENTS";
export const SETTHUMBNAIL = "SETTHUMBNAIL";

export const METAMASK = "METAMASK";

export const REMOVEMETADATAATTRIBUTE = "REMOVEMETADATAATTRIBUTE";

export const FILETYPE = "FILETYPE";

export const RESETNFT = "RESETNFT";

export const DRAFT = "DRAFT";

export const NFT_TITLE = "NFT_TITLE";
export const COMPRESSED_FILE_RESPONSE = "COMPRESSED_FILE_RESPONSE";
export const PERCENTAGE_OF_COMPRESSED_FILE = "PERCENTAGE_OF_COMPRESSED_FILE";
export const CHANGE_STATE_TO_RENDER_COMPONENET = "CHANGE_STATE_TO_RENDER_COMPONENET";
