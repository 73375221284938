import * as Yup from "yup";
const charLimit = {
  title: {
    max: 46,
    min: 4
  },
  description: {
    max: 512,
    min: 4
  }
};
// address validation with yup
Yup.addMethod(Yup.string, "validateEtherAddress", function (errorMessage) {
  return this.test("validate-eth-wallet-address", errorMessage, function (value) {
    const { path, createError } = this;
    // @ts-ignore
    return WAValidator?.validate(value, "eth") || createError({ path, message: errorMessage });
  });
});

export const CreateNFTYupValidationSchema = Yup.object({
  title: Yup.string().required("Title is required").min(charLimit.title.min, "Title should be minimum 4 character long").max(charLimit.title.max, "Maximum 46 character are allowed"),
  description: Yup.string()
    .required("Description is required")
    .min(charLimit.description.min, "Description should be minimum 4 character long")
    .max(charLimit.description.max, "Maximum 150 character are allowed"),
  category: Yup.string().required("Category is required"),
  // @ts-ignore
  walletAddress: Yup.string().required("Wallet address is required").validateEtherAddress("Invalid ethereum wallet address")
});
