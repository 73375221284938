import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";
import HTTPService from "utils/httpService";

// this action is getting used in common module so do not remove it.
export const appSetFavouriteOrUnfavouriteNFT = (params: { id: string }): Promise<IFavouriteNFTReturnResult> => {
  return HTTPService.get(apiEndPoints.app.FavouriteUnfavourite, { params });
};

const favouriteApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appSetFavouriteOrUnfavouriteNFT: build.query<IFavouriteNFTReturnResult, { id: string }>({
      query: (params) => ({
        url: apiEndPoints.app.FavouriteUnfavourite,
        method: "get",
        params: params
      }),
      providesTags: [
        {
          type: "appSetFavouriteOrUnfavouriteNFT",
          id: "appSetFavouriteOrUnfavouriteNFT"
        }
      ]
    })
  })
});

export const { useAppSetFavouriteOrUnfavouriteNFTQuery, useLazyAppSetFavouriteOrUnfavouriteNFTQuery } = favouriteApiSlice;

interface IFavouriteNFTReturnResult {
  status: 200;
  message: "Updated successfully";
}
