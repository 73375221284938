import { DEVICEID, NOTIFICATION_ON, SET_IS_ACCESS, SET_WALLETADDRESS, USERDATA, USERIDANDNAME } from "./type";

export const onUserDetailsUpdate = (payload: any) => {
  return { type: USERDATA, payload };
};

export const setUserDeviceId = (payload: any) => {
  return { type: DEVICEID, payload };
};

export const setUserIdAndUserName = (payload: { username: string; userid: string }) => {
  return { type: USERIDANDNAME, payload };
};

export const onNotifcationOn = (payload: any) => {
  return { type: NOTIFICATION_ON, payload };
};

export const setIsAccess = (payload: number) => {
  return { type: SET_IS_ACCESS, payload };
};

export const setUserProfileWalletAddress = (payload: string) => {
  return { type: SET_WALLETADDRESS, payload };
};
