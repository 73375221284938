import { INftListReturn } from "actions/app/get-nft-list";
import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { handleTheLanguageTranslation } from "utils/functions/commonFunctions";

export interface IUserProfileNFTsTabsPropsType {
  loading: boolean;
  isLoggedIn: boolean;
  userNftsList: INftListReturn | undefined;
  checkIfRequestIsForCurrentUser: Function;
  activeTab: {
    mintedByMe: boolean;
    ownedByMe: boolean;
    overview: boolean;
  };
  setActiveTab: React.Dispatch<
    React.SetStateAction<{
      mintedByMe: boolean;
      ownedByMe: boolean;
      overview: boolean;
    }>
  >;
}

const UserProfileNFTsTabs = ({ userNftsList, isLoggedIn, setActiveTab, activeTab, loading, checkIfRequestIsForCurrentUser }: IUserProfileNFTsTabsPropsType) => {
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  const gliderRef = useRef<HTMLSpanElement>(null);
  const buttonsRef = useRef<HTMLButtonElement[]>([]);
  const [currentButtonRefIndex, setCurrentButtonRefIndex] = useState(0);

  // this function will move the glider position by calculating the current position and width of its respective element.
  const handleThePositionOfWhiteGlider = (e: React.MouseEvent) => {
    if (gliderRef.current) {
      gliderRef.current.style.width = `${Math.floor((e.target as Element)?.getBoundingClientRect().width)}px`;
      gliderRef.current.style.transform = `translateX(${Math.floor((e.target as HTMLElement)?.offsetLeft - 10)}px)`;
    }
  };

  // Why we have this useEffect
  /**
   * @origin - it is an bugfix of - issue `#268`
   *
   * @Problem - when user switch the language then some words get bigger in german, and that cause white glider on tab buttons to behavie odly. [the size of the white glider will be less then the content]
   *
   * @Solution - to fix this I created a Ref of all the buttons and performing following operations
   * 1. Get the current selected button Ref by Its index. [let's say its button no 2]
   * 2. Now if user switch the language the below effect will run and it will -
   *    I.  Add an event listener to current selected button.
   *    II. It will trigger a `click()` event and the `position` of white glider will be calculated again.
   *
   * If you are still confused please ask to respective person. In this case its @rajesh-edx
   */
  useEffect(() => {
    if (buttonsRef.current.length > 0) {
      try {
        if (buttonsRef.current[currentButtonRefIndex] !== null) {
          const triggerClickEvent = (e: MouseEvent) => {
            handleThePositionOfWhiteGlider(e as unknown as React.MouseEvent);
          };
          buttonsRef.current[currentButtonRefIndex]?.addEventListener("click", (e) => triggerClickEvent(e));
          buttonsRef.current[currentButtonRefIndex]?.click();

          setTimeout(() => {
            buttonsRef.current[currentButtonRefIndex]?.removeEventListener("click", (e) => triggerClickEvent(e));
          }, 3000);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);

  return (
    <Row>
      <Col sm={12}>
        {/* for curent user only */}
        {userNftsList?.data && isLoggedIn && !checkIfRequestIsForCurrentUser() && (
          <div className="tab-container d-flex align-items-center">
            <button
              ref={(e) => (buttonsRef.current![2] = e as unknown as HTMLButtonElement)}
              onClick={(e) => {
                setActiveTab({
                  ownedByMe: false,
                  mintedByMe: false,
                  overview: true
                });
                setCurrentButtonRefIndex(2);
                handleThePositionOfWhiteGlider(e);
              }}
              className={`${activeTab.overview && "active"} btn3 btn btn-secondary`}>
              {handleTheLanguageTranslation(label?.overview, "Overview")}
            </button>
            <button
              ref={(e) => (buttonsRef.current![0] = e as unknown as HTMLButtonElement)}
              onClick={(e) => {
                setActiveTab({
                  ownedByMe: true,
                  mintedByMe: false,
                  overview: false
                });
                setCurrentButtonRefIndex(0);
                handleThePositionOfWhiteGlider(e);
              }}
              className={`${activeTab.ownedByMe && "active"} btn1 btn btn-secondary`}>
              {handleTheLanguageTranslation(label?.own_by_me, "Own by me")} ({userNftsList.count.ownByMeCount})
            </button>
            <button
              ref={(e) => (buttonsRef.current![1] = e as unknown as HTMLButtonElement)}
              onClick={(e) => {
                setActiveTab({
                  ownedByMe: false,
                  mintedByMe: true,
                  overview: false
                });
                setCurrentButtonRefIndex(1);
                handleThePositionOfWhiteGlider(e);
              }}
              className={`${activeTab.mintedByMe && "active"} btn2 btn btn-secondary`}>
              {handleTheLanguageTranslation(label?.minted_nft, "Minted NFT")} ({userNftsList.count.mintedByMeCount})
            </button>
            <span className="glider" ref={gliderRef}></span>
          </div>
        )}

        {/* for public users */}
        {userNftsList?.data && checkIfRequestIsForCurrentUser() && (
          <div className="tab-container d-flex align-items-center">
            <button
              ref={(e) => (buttonsRef.current![1] = e as unknown as HTMLButtonElement)}
              onClick={(e) => {
                setActiveTab({
                  ownedByMe: false,
                  mintedByMe: false,
                  overview: true
                });
                handleThePositionOfWhiteGlider(e);
                setCurrentButtonRefIndex(1);
              }}
              style={{ minWidth: 128 }}
              className={`${activeTab.overview && "active"} btn2 btn btn-secondary`}>
              {handleTheLanguageTranslation(label?.overview, "Overview")}
            </button>
            <button
              ref={(e) => (buttonsRef.current![0] = e as HTMLButtonElement)}
              onClick={(e) => {
                setActiveTab({
                  ownedByMe: true,
                  mintedByMe: false,
                  overview: false
                });
                handleThePositionOfWhiteGlider(e);
                setCurrentButtonRefIndex(0);
              }}
              style={{ minWidth: 130 }}
              className={`${activeTab.ownedByMe && "active"} btn1 btn btn-secondary`}>
              NFT ({userNftsList.count?.NFTs || (userNftsList.data as unknown as [])?.length})
            </button>

            <span className="glider" ref={gliderRef}></span>
          </div>
        )}
        {loading && <Skeleton height={60} width={"100%"} style={{ marginBottom: 40 }} />}
      </Col>
    </Row>
  );
};

export default React.memo(UserProfileNFTsTabs);
