import { ISSCROLLING } from "./type";

const initialState: IScrollingReducer = {
  isScrolling: false
};

const scrollingReducer = (state = initialState, action: { type: string; payload: boolean }) => {
  const newState = { ...state };
  switch (action.type) {
    case ISSCROLLING:
      newState.isScrolling = action.payload;
      break;
  }
  return newState;
};

export { scrollingReducer };

export interface IScrollingReducer {
  isScrolling: boolean;
}
