import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const sendRequestDemoAPI = (data: any): Promise<ISendRequestDemoResponse> => {
//   return HTTPService.post(apiEndPoints.app.RequestDemo, data);
// };

const requestDemoApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    sendRequestDemoAPI: build.mutation<ISendRequestDemoResponse, any>({
      query: (data) => ({
        url: apiEndPoints.app.RequestDemo,
        data: data,
        method: "post"
      })
    })
  })
});

export const { useSendRequestDemoAPIMutation } = requestDemoApiSlice;

export interface IrequestDemo {
  fullName: string;
  phoneNumber: string;
  email: string;
  description: string;
  reference: string;
}

export interface ISendRequestDemoResponse {
  status: number;
  message: string;
  data: any;
}
