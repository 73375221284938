import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const getDraftListAPI = (params: IDraftNftsRequest): Promise<IDraftListReturn> => {
//   return HTTPService.get(apiEndPoints.app.GetDraft, { params });
// };

const creditCardListApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    getCreditCardListAPI: build.query<ICreditCardList, void>({
      query: (params) => ({
        url: apiEndPoints.cardList,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "getCreditCardListAPI", id: "getCreditCardListAPI" }]
    })
  })
});

export const { useGetCreditCardListAPIQuery, useLazyGetCreditCardListAPIQuery } = creditCardListApiSlice;

// Generated by https://quicktype.io

interface ICreditCardListData {
  image: string;
  name: string;
}

export interface ICreditCardList {
  status: number;
  message: string;
  data: ICreditCardListData[];
}
