import { ICategoryListResult } from 'actions/get-category-list'
import { CloseButtonSvg } from 'assets/images/close-button'
import React, { useEffect } from 'react'
import Masonry from 'react-masonry-css'
import { Col } from 'reactstrap'
import '../../styles/components/moreCategoriesPenel.scss'

interface IMoreCategoriesPanelProps {
  isOpen: boolean
  toggleCategoriesPanelVisible: Function
  setCategorySearch: React.Dispatch<React.SetStateAction<string>>
  categorySearch: string
  setSelectedLetter: React.Dispatch<React.SetStateAction<string>>
  selectedLetter: string
  categoriesList: ICategoryListResult | undefined
  setSelectedCategory: React.Dispatch<React.SetStateAction<string[]>>
  selectedCategory: string[]
  resetPagination: Function
}
const MoreCategoriesPanel = ({
  isOpen,
  toggleCategoriesPanelVisible,
  categorySearch,
  setCategorySearch,
  selectedLetter,
  setSelectedLetter,
  categoriesList,
  setSelectedCategory,
  selectedCategory,
  resetPagination
}: IMoreCategoriesPanelProps) => {
  useEffect(() => {
    const detectCLicksWhichIsOUtsideTheWrapper = (e: MouseEvent) => {
      // when checking outside click make sure the target click is not the filter button itself which falls outside the categories panel
      if (
        document
          .getElementById('categories-panel')
          ?.contains(e.target as Node) === false &&
        isOpen === true &&
        !(e.target as HTMLButtonElement).classList.contains(
          'filter-btn'
        )
      ) {
        toggleCategoriesPanelVisible('from here')
      }
    }
    window.addEventListener(
      'click',
      detectCLicksWhichIsOUtsideTheWrapper
    )
    return () => {
      window.removeEventListener(
        'click',
        detectCLicksWhichIsOUtsideTheWrapper
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

 
  return (
    <Col sm="12">
      <div
        className={`more-categories-panel ${isOpen ? 'open' : ''}`}
        id="categories-panel"
      >
        <div className="categories-header">
          <input
            type={'text'}
            placeholder="Search category"
            value={categorySearch}
            onChange={(e) => {
              setCategorySearch(e.target.value)
            }}
          />
          <div className="latters">
            {'#abcdefghijklmnopqrstuvwxyz'.split('').map((item) => {
              return (
                <button
                  key={item}
                  className={`text-uppercase bg-transparent ${
                    selectedLetter === item ? 'active' : ''
                  }`}
                  onClick={() => {
                    if (selectedLetter === item) {
                      setSelectedLetter('')
                      return null
                    }
                    setSelectedLetter(item)
                  }}
                >
                  {item}
                </button>
              )
            })}
          </div>
          <div
            className="close"
            role={'button'}
            onClick={() => {
              toggleCategoriesPanelVisible()
            }}
          >
            {CloseButtonSvg}
          </div>
        </div>
        <div className="divider"></div>
        <div className="categories-body">
          <Masonry
            breakpointCols={{ default: 5, 800: 3, 500: 2 }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {'#ABCDEFGHIJKLMNOPQRSTUVWXYZ'
              .split('')
              .map((letter, index) => {
                let filteredCategories = categoriesList?.data?.filter(
                  (item) => item.name.startsWith(letter)
                )
                // if a letter is selected to show only the categories of that letter then filter out those categories only
                // when letter is selected
                //  then check if current letter loop is for that letter
                // if yes then set filtered categories for that letter
                // otherwise do not render any char
                if (selectedLetter) {
                  if (
                    selectedLetter.toLowerCase() ===
                    letter.toLowerCase()
                  ) {
                    if (letter === '#') {
                      filteredCategories =
                        categoriesList?.data?.slice(0, 6)
                    } else {
                      filteredCategories =
                        categoriesList?.data.filter((item) =>
                          item.name.startsWith(letter)
                        )
                    }
                  } else {
                    return null
                  }
                }
                // render # categories every time
                if (letter === '#') {
                  filteredCategories = categoriesList?.data?.slice(
                    0,
                    6
                  )
                }
                // if user has searched something
                if (categorySearch) {
                  filteredCategories = filteredCategories?.filter(
                    (item) =>
                      item.name
                        .toLowerCase()
                        .includes(categorySearch.toLowerCase())
                  )
                  if (filteredCategories?.length === 0) {
                    return null
                  }
                }
                // if there is no categories in a particular letter then return null
                if (
                  filteredCategories?.length === 0 &&
                  letter !== '#'
                ) {
                  return null
                }

                // render the list of categories inside a letter
                return (
                  <div className="category-container" key={index}>
                    <p className="mb-0 heading">{letter}</p>
                    <div className="category">
                      {filteredCategories?.map((category, index) => {
                        return (
                          <label
                            role={'button'}
                            key={category._id + index}
                            onClick={(e) => {
                              e.preventDefault()
                              if (
                                selectedCategory?.indexOf(
                                  category._id
                                ) > -1
                              ) {
                                const modifiedCategories =
                                  selectedCategory?.filter(
                                    (item) => item !== category._id
                                  ) || []
                                setSelectedCategory([
                                  ...modifiedCategories
                                ])
                                return null
                              }
                              setSelectedCategory([
                                ...selectedCategory,
                                category._id
                              ])
                              resetPagination()
                              return null
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={
                                selectedCategory.includes(
                                  category._id
                                ) || false
                              }
                              readOnly
                            />
                            <p
                              className="label text-truncate mb-0"
                              title="Trending"
                            >
                              {category.name}{' '}
                              {category.nftCount
                                ? `(${category.nftCount})`
                                : '(0)'}
                            </p>
                          </label>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
          </Masonry>
          {categoriesList?.data?.filter((item) =>
            item.name
              .toLowerCase()
              .includes(categorySearch.toLowerCase())
          ).length === 0 && (
            <p className="text-center c-tx-third">
              No matching categories found!
            </p>
          )}
        </div>
      </div>
    </Col>
  )
}

export default React.memo(MoreCategoriesPanel)
