import { faCheckCircle, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ITopUsers, useLazyAppGetTopUsersQuery } from "actions/app/get-top-users";
import { makeFullS3ObjectUrl } from "assets/images";
import GlobalHelmetProvider from "components/GlobalHelmetProvider";
import GlobalLoadMoreButton from "components/GlobalLoadMoreButton";
import ImageComponent from "components/ImageComponent";
import UserProfilesCardLoadingSkeleton from "components/UserProfilesCardLoadingSkeleton";
import useInfiniteLoading from "hooks/useInfiniteLoading";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Input, Row } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { debounceTimeInMilliseconds, paginationKeysObject } from "utils/constants/constants";
import { handleTheLanguageTranslation } from "utils/functions/commonFunctions";
import "../styles/components/TopUsers.scss";
import TopsUserImageComponent from "./TopsUserImageComponent";
const defaultBannerImage = makeFullS3ObjectUrl("profile-banner.png");

export const TopUsersPage = () => {
  const [loadMoreRef] = useInfiniteLoading();
  const [getTopUserQuery, { isError }] = useLazyAppGetTopUsersQuery();
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  const [isAPILoading, setIsAPILoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allTopUsers, setAllTopUsers] = useState<ITopUsers>();
  const [topuserPagination, setTopuserPagination] = useState({
    ...paginationKeysObject,
    limit: 10
  });

  const getTopUsers = (value?: string) => {
    setIsAPILoading(true);
    let parameter: any = { ...topuserPagination };
    if (value) {
      parameter.search = value;
      parameter.page = 1;
      setTopuserPagination({
        ...topuserPagination,
        search: value,
        page: 1
      });
    }
    getTopUserQuery(parameter)
      .then((data: any) => {
        if (topuserPagination.page === 1) {
          setAllTopUsers(data.data);
        } else {
          //@ts-ignore
          setAllTopUsers({
            ...allTopUsers,
            count: data.data.count,
            // @ts-ignore
            data: [...allTopUsers?.data, ...data.data.data]
          });
        }
        setIsLoading(false);
        setIsAPILoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsAPILoading(false);
      });
  };

  useEffect(() => {
    getTopUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topuserPagination.page]);

  const loadMoreData = useCallback(() => {
    setTopuserPagination({
      ...topuserPagination,
      page: topuserPagination.page + 1
    });
  }, [topuserPagination]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(debounce(getTopUsers, debounceTimeInMilliseconds), []);

  return (
    <>
      <GlobalHelmetProvider title={handleTheLanguageTranslation(label?.explore, "Explore")} />
      <div className="explore-collection-wrapper section-margin-y">
        <Container>
          <Row className="align-items-center">
            <Col sm="12" className="d-lg-flex justify-content-around align-items-center">
              <div className="position-relative w-100">
                <h2 className="title-lr text-center text-white f-700 main-title w-100">{handleTheLanguageTranslation(label?.top_projects, "Top Projects")}</h2>
                <div className="welcome-content">
                  <h3 className="c-tx-third lh-32 mb-0 f-400 text-center" style={{ fontWeight: "600" }}>
                    {handleTheLanguageTranslation(label?.thefastestmostaffordablenftforbusinessecosystemformarketplaces, "The fastest & most affordable NFT for Business ecosystem for marketplaces.")}
                  </h3>
                  <h3 className="c-tx-third f-400 lh-32 mb-0 text-center">
                    {handleTheLanguageTranslation(
                      label?.thenftengineprotocolallowyoutocustomizeyourownnftforyourbusiness,
                      "the NFT engine Protocol allow you to customize your own NFT for your Business."
                    )}
                  </h3>
                </div>
                <div style={{ margin: "40px 0 50px 0" }}>
                  <div
                    style={{
                      position: "relative",
                      width: "80%",
                      margin: "0 auto"
                    }}>
                    <Input
                      className="top-user-search"
                      type="text"
                      onChange={(e) => {
                        delayedSearch(e.target.value);
                      }}
                      placeholder={handleTheLanguageTranslation(label?.search_user_by_name, "Search user by name")}
                      style={{
                        margin: "auto",
                        display: "block",
                        width: "100%"
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faLongArrowAltRight}
                      style={{
                        color: "#F71137",
                        fontSize: "18px",
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "30px"
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <section className="top-user ">
            <div className="top-user-main-control-wrapper">
              <Row className="row-cols-xl-5 row-cols-lg-3 row-cols-sm-2 row-cols-1 ">
                {!isLoading
                  ? allTopUsers?.data?.map((topUser) => {
                      return (
                        <Col key={topUser._id}>
                          <Link to={`/account/@${topUser._id}`} className="text-decoration-none">
                            <div className="top-user-wrapper cursor">
                              <div className="img-wrapper image-loader">
                                <TopsUserImageComponent bannerImage={topUser.bannerImage ? topUser.bannerImage : defaultBannerImage} />
                              </div>
                              <div className="bg-white content-info">
                                <div className="user-block">
                                  <div className="user-img-wrapper">
                                    <ImageComponent src={topUser.cropProfilePicture ? topUser?.cropProfilePicture : topUser?.profilePicture} name={topUser.username} />
                                  </div>
                                </div>
                                <div className="capsual-block" data-title={topUser.name || topUser.username}>
                                  <span className="animate-bl text-truncate" style={{ maxWidth: 100 }}>
                                    {topUser.name || topUser.username}
                                  </span>
                                </div>
                                <div className="username-block text-center">
                                  <div className="d-flex align-items-center justify-content-center">
                                    <h3 className="mb-0 lh-26 c-tx-secondary f-700">{topUser.username} </h3>
                                    {topUser.isVerified === 1 && (
                                      <span className="checkmark-block">
                                        <FontAwesomeIcon icon={faCheckCircle} />
                                      </span>
                                    )}
                                  </div>
                                  <p className="c-tx-forth nft-ct">{topUser.NFTs}</p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </Col>
                      );
                    })
                  : Array(10)
                      .fill(5000)
                      .map((item, index) => (
                        <div
                          key={item + index}
                          style={{
                            height: 306,
                            overflow: "hidden",
                            marginBottom: 25,
                            borderRadius: 10
                          }}>
                          <UserProfilesCardLoadingSkeleton />
                        </div>
                      ))}
                {isError && <p className="my-5 text-center c-tx-third w-100">No top projects</p>}
                {allTopUsers?.count === 0 && <p className="my-5 text-center c-tx-third w-100">No top projects found</p>}
              </Row>
            </div>
          </section>
          <Row>
            <Col className="d-flex justify-content-center align-items-center mt-5">
              <div ref={loadMoreRef}>
                <GlobalLoadMoreButton dataCount={allTopUsers?.count} isLoading={isAPILoading} listData={allTopUsers?.data as []} loadMoreData={loadMoreData} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
