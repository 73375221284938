import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeFullS3ObjectUrl } from "assets/images";
import VideoModal from "components/home/VideoModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { handleTheLanguageTranslation } from "utils/functions";
const TextRound = makeFullS3ObjectUrl("get_started.png");

const DigitalAssets = () => {
  const Play = <FontAwesomeIcon icon={faPlay} />;
  const navigate = useNavigate();
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  const [videoPlay, setVideoPlay] = useState(false);

  return (
    <section className="digital-assets dark-block ct-90 cb-100">
      <Row className="justify-content-center">
        <Col md="10">
          <h2 className="text-white text-center h1">
            {handleTheLanguageTranslation(label?.digital_assets_on, "Digital assets on")}
            <br /> {handleTheLanguageTranslation(label?.edexa_universe_blockchain, "edeXa Universe blockchain")}
          </h2>
          <h3 className="c-tx-third text-center welcome-title f-400">
            {handleTheLanguageTranslation(
              label?.welcome_home_p_2,
              "WELCOME to Non-fungible tokens (NFT) digital asset which can be used to represent ownership of a unique object. They are different from commodities and other fungible tokens because they have an intrinsic, digital scarcity and now its all possible with no code required, thanks to edeXa Blockchain ecosystem."
            )}
          </h3>
          <div className="exploring-block text-center align-items-center">
            <div className="btn-block-first">
              <Button className="custom-primary" onClick={() => navigate("/explore")}>
                {handleTheLanguageTranslation(label?.start_exploring, "Start exploring")}
              </Button>
            </div>
            <div className="img-wrapper-first">
              <svg xmlns="http://www.w3.org/2000/svg" width="112.727" height="45" viewBox="0 0 112.727 45">
                <g id="Group_36" data-name="Group 36" transform="translate(-852.112 -3365.998)" opacity="0.5">
                  <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M879.308,3712.58s13.447-26.03,32.1-24.714,15.266,41.093,29.391,42.848,16.372-19.23,25.253-24.714,23.255-1.024,23.255-1.024"
                    transform="translate(-26.308 -320.817)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeDasharray="5 5"
                  />
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M-3.75-2.474a.781.781,0,0,0,1.334.552L2.61-6.948a.781.781,0,0,0,0-1.1l-5.026-5.026a.781.781,0,0,0-1.334.552Z"
                    transform="translate(962 3391)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </div>
            <div className="img-wrapper-second" onClick={() => setVideoPlay(true)}>
              <img src={TextRound} className="img-fluid" alt="round text" />
              <div className="play-link">
                <h4 className="mb-0 text-white">{Play}</h4>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <VideoModal setVideoPlay={setVideoPlay} videoPlay={videoPlay} />
    </section>
  );
};

export default DigitalAssets;
