import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

const downloadInvoice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    downloadInvoiceAPI: build.query<IGetUserInvoiceDownloadAPIResponse, { id: string }>({
      query: (params) => ({
        url: apiEndPoints.downloadInvoice,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "downloadInvoiceAPIQuery", id: "downloadInvoiceAPIQuery" }]
    })
  })
});

export const { useLazyDownloadInvoiceAPIQuery, useDownloadInvoiceAPIQuery } = downloadInvoice;

export interface IUserInvoiceDownload {}

export interface IGetUserInvoiceDownloadAPIResponse {
  status: number;
  message: string;
  data: string;
}
