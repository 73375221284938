import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IsearchNfts, ISearchParams, ISearchReturn, ISearchUsers, useLazyGetSearchApiQuery } from "actions/app/serach";
import { makeFullS3ObjectUrl } from "assets/images";
import { motion } from "framer-motion";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Input, Row, Spinner } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import "styles/components/searchBox.scss";
import { debounceTimeInMilliseconds } from "utils/constants/constants";
import { handleEmail, handleTheLanguageTranslation } from "utils/functions/commonFunctions";
import UserImage from "../components/common/Image";
const errorImage = makeFullS3ObjectUrl("art.png");

const SearchBox = ({ search, setSearch }: { search: boolean; setSearch: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const navigate = useNavigate();
  const [getSearchResultsQuery] = useLazyGetSearchApiQuery();
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  const [icon, setIcon] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [suggestionRes, setSuggestionRes] = useState<ISearchReturn>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [suggestion, setSuggestion] = useState<boolean>(true);
  const [imgState, setImgState] = useState({
    isError: false
  });

  const getSearchAction = (val?: string) => {
    setLoading(true);
    let data: ISearchParams = {
      search: val || searchValue
    };
    getSearchResultsQuery(data).then((res) => {
      setSuggestionRes(res.data);
      setLoading(false);
    });
  };

  const handleError = () => setImgState({ isError: true });
  const scrollEventListener = (e: Event) => setSearch(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(debounce(getSearchAction, debounceTimeInMilliseconds), []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    e.target.value ? setSuggestion(true) : setSuggestion(false);
    delayedSearch(e.target.value);
  };

  const clickOutsideEventListener = (e: MouseEvent) => {
    if (document.getElementById("clickbox")?.contains(e.target as Node)) {
    } else if (document.getElementById("click-button")?.contains(e.target as Node)) {
    } else if (document.getElementById("suggestion-box-id")?.contains(e.target as Node)) {
    } else {
      setSearch(false);
    }
  };

  useEffect(() => {
    if (suggestionRes) {
      setSuggestion(true);
    }
  }, [suggestionRes]);

  useEffect(() => {
    if (!search) {
      setSuggestion(false);
      setSearchValue("");
    } else {
      document.getElementById("nft-search")?.focus();
      getSearchAction();
      setSuggestion(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    window.addEventListener("click", clickOutsideEventListener);
    window.addEventListener("scroll", scrollEventListener);
    return () => {
      window.removeEventListener("click", clickOutsideEventListener);
      window.removeEventListener("scroll", scrollEventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {search && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }} className="explore-collection-wrapper section-margin-y search-bar-main">
          <div className="search-box" id="clickbox">
            {/* @ts-ignore */}
            <Input
              id="nft-search"
              placeholder={handleTheLanguageTranslation(label?.search_nfts_by_Platform_id_and_name, "Search NFTs by Platform Id and Name...")}
              style={{ marginLeft: "20px" }}
              autoComplete="off"
              onChange={(e) => handleChange(e)}
              name="search"
              value={searchValue}
            />
            <div
              style={{
                opacity: `${suggestion ? 1 : 0}`,
                marginRight: "10px",
                cursor: "pointer"
              }}
              onClick={() => {
                setSearchValue("");
                setSuggestion(false);
                document.getElementById("nft-search")?.focus();
              }}
              onMouseEnter={() => setIcon(true)}
              onMouseLeave={() => setIcon(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                <path
                  id="noun-cross-3869881"
                  d="M104.334,23.332a11.013,11.013,0,1,0,7.774,3.226A11,11,0,0,0,104.334,23.332Zm4.242,13.829h0a1,1,0,1,1-1.414,1.414l-2.828-2.829-2.828,2.829a1,1,0,0,1-1.414-1.414l2.828-2.829L100.092,31.5a1,1,0,1,1,1.414-1.414l2.828,2.829,2.828-2.829a1,1,0,1,1,1.414,1.414l-2.828,2.829Z"
                  transform="translate(-93.334 -23.332)"
                  fill={`${icon ? "#f71137" : "#dadce0"}`}
                />
              </svg>
            </div>
          </div>
        </motion.div>
      )}
      {suggestion && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }} className="explore-collection-wrapper section-margin-y  search-bar-main">
          <div className="suggestion-box p-4" id="suggestion-box-id">
            {isLoading ? (
              <div className="suggestion-Box-spinner">
                <Spinner />
              </div>
            ) : (
              <>
                {suggestionRes?.nfts.length === 0 && suggestionRes?.users.length === 0 ? (
                  <div className="suggestion-Box-spinner">
                    <h4 style={{ display: "contents" }}>{handleTheLanguageTranslation(label?.no_matches_found, "No matches Found")}</h4>
                  </div>
                ) : (
                  <Row>
                    {suggestionRes?.nfts.length === 0 ? null : (
                      <Col sm={5} md={4}>
                        <h6> {handleTheLanguageTranslation(label?.nfts, "NFTs")} </h6>
                        {suggestionRes?.nfts.map((data: IsearchNfts, index: number) => (
                          <Row
                            key={index}
                            onClick={() => {
                              navigate(`/assets/${data?._id}`);
                              setSearch(false);
                            }}
                            style={{ cursor: "pointer" }}>
                            <Col xs={2}>
                              <img
                                style={{ background: "#191919" }}
                                src={imgState?.isError ? errorImage : data?.fileThumbPath ? data?.fileThumbPath : errorImage}
                                alt="NFTs fileThumbPath"
                                width="100%"
                                height="100%"
                                onError={handleError}
                              />
                            </Col>
                            <Col xs={10}>
                              <h4 title={data?.title}>{data?.title}</h4>
                              <p className="text-primary text-bold paragraph">
                                @{data?.username} <FontAwesomeIcon icon={faCheckCircle} className="pt-25 ms-1 f-700" style={{ color: "#828282" }} />
                              </p>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    )}

                    {suggestionRes?.users.length === 0 ? null : (
                      <Col sm={5} md={4}>
                        <h6>{handleTheLanguageTranslation(label?.users, "Users")}</h6>
                        {suggestionRes?.users.map((data: ISearchUsers, index: number) => (
                          <Row
                            key={index}
                            onClick={() => {
                              navigate(`/account/@${data?._id}`);
                              setSearch(false);
                            }}
                            style={{ cursor: "pointer" }}>
                            <Col xs={2}>
                              <UserImage src={data?.cropProfilePicture || data?.profilePicture || "https://edexa-portal.s3.eu-central-1.amazonaws.com/users/defaultImg.png"} />
                            </Col>
                            <Col sx={10}>
                              <h4 title={data?.name}>{data?.name ? data?.name : data?.email && handleEmail(data?.email)}</h4>
                              <p className="text-primary text-bold paragraph">
                                @{data?.username} <FontAwesomeIcon icon={faCheckCircle} className="pt-25 ms-1 f-700" style={{ color: "#828282" }} />
                              </p>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    )}
                  </Row>
                )}
              </>
            )}
          </div>
        </motion.div>
      )}
    </>
  );
};

export default SearchBox;
