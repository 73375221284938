import { makeFullS3ObjectUrl } from "assets/images";
import { useState } from "react";
import { Link } from "react-router-dom";
import "styles/components/collectionblock.scss";
const defualtBannerImage = makeFullS3ObjectUrl("profile-banner.png");
const defaultImg = "https://edexa-portal-beta.s3.ap-south-1.amazonaws.com/users/defaultImg.png";

export const CollectionListCardPage = ({ title, logo, bannerImage, description, collectionUrl }: ICollectionListCardPropTypes) => {
  const [imageError, setImageError] = useState({
    banner: false,
    profile: false
  });
  return (
    <div className="collectionblock bg-white overflow-hidden c-br-3" style={{ height: "355px" }}>
      <Link to={`/collection/${collectionUrl}`} className="text-decoration-none">
        <div className="poster-img-wrapper">
          <div className="img-wrapper2">
            <img
              src={imageError?.banner ? defualtBannerImage : bannerImage ? bannerImage : defualtBannerImage}
              className="img-fluid poster-img"
              alt="poster-1"
              onError={(e) => {
                setImageError((prev) => {
                  return { ...prev, banner: true };
                });
              }}
            />
          </div>
          <div className="profile-img-wrapper">
            <img
              src={imageError.profile ? defaultImg : logo ? logo : defaultImg}
              className="img-fluid profile-img"
              alt="profile-1"
              onError={(e) => {
                setImageError((prev) => {
                  return { ...prev, profile: true };
                });
              }}
            />
          </div>
        </div>
        <div className="username-block text-center px-30">
          <h3 className="mb-0 lh-26 c-tx-secondary f-700 text-truncate">{title}</h3>
          <p className="c-tx-forth mt-0">{description}</p>
        </div>
      </Link>
    </div>
  );
};

export interface ICollectionListCardPropTypes {
  title: string;
  logo: string;
  bannerImage: string;
  description: string;
  collectionUrl: string;
  visibility?: number;
}
