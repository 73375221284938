import { ICategoryListResult, useGetNftCategoryListQuery } from "actions/get-category-list";
import WithErrorBoundaryHandler from "core/error/WithErrorBoundaryHandler";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { handleTheLanguageTranslation } from "utils/functions/commonFunctions";
import MoreCategoriesPanel from "./MoreCategoriesPanel";

// @todo - get a better type for set functions
interface ExplorerCategoriesPropsTypes {
  setFilterVisible: Function;
  filterVisible: boolean;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCategory: string[];
  resetPagination: Function;
}

const ExplorerCategories = ({ setFilterVisible, filterVisible, setSelectedCategory, selectedCategory, resetPagination }: ExplorerCategoriesPropsTypes) => {
  const [categoriesList, setCategoriesList] = useState<ICategoryListResult>();
  const { data: nftCategoryListAPIResponse } = useGetNftCategoryListQuery();

  useEffect(() => {
    if (nftCategoryListAPIResponse) {
      setCategoriesList(nftCategoryListAPIResponse);
    }
  }, [nftCategoryListAPIResponse]);

  // find if a category exist
  const ifCategoryExist = (catId: string) => {
    return selectedCategory?.find((selectedArraycatId) => selectedArraycatId === catId);
  };

  // ********  more categories panel logic **********

  const [isMoreCategoriesPanelVisible, setIsMoreCategoriesPanelVisible] = useState(false);
  const [categorySearch, setCategorySearch] = useState("");
  const [selectedLetter, setSelectedLetter] = useState("");

  const toggleCategoriesPanelVisible = useCallback(() => {
    setIsMoreCategoriesPanelVisible(!isMoreCategoriesPanelVisible);
  }, [isMoreCategoriesPanelVisible]);

  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);

  // set default category as utility
  const [pageInitialization, setPageInitialization] = useState(false);
  useEffect(() => {
    // if page loads first time then utility category should be selected by default otherwise not
    if (categoriesList && pageInitialization === false) {
      setPageInitialization(true);
      let defaultCategory = categoriesList.data.filter((cat) => cat.highlight === true)?.[0]?._id;
      defaultCategory && setSelectedCategory([defaultCategory]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesList]);
  return (
    <Col sm="12">
      <div className="filter-wrapper">
        <div className="services-list">
          {categoriesList ? (
            <>
              <Button
                className={`filter-btn btn ${!selectedCategory.length && "active"}`}
                type="button"
                onClick={() => {
                  setSelectedCategory([]);
                }}>
                {handleTheLanguageTranslation(label?.all, "All")} {`(${categoriesList.totalNft})`}
              </Button>
              {categoriesList?.data?.slice(0, 7).map((category, index: number) => {
                return (
                  <button
                    // initial={{ scaleX: 0.7, transformOrigin: "left" }}
                    // animate={{ scaleX: 1 }}
                    // transition={{
                    //   duration: 0.3,
                    //   type: "spring",
                    //   stiffness: 150
                    // }}
                    key={index}
                    className={`filter-btn ${ifCategoryExist(category._id) === category._id ? "active" : ""} `}
                    type="button"
                    // ref={(el) => (buttonRefs.current[index] = el)}
                    onClick={() => {
                      // in future we may set multiple categories then:
                      // spread the previous categories and to remove remove the particular index.
                      if (selectedCategory?.indexOf(category._id) > -1) {
                        const modifiedCategories = selectedCategory?.filter((item) => item !== category._id) || [];
                        setSelectedCategory([...modifiedCategories]);
                        return null;
                      }
                      setSelectedCategory([...selectedCategory, category._id]);
                      resetPagination();
                    }}>
                    {category.name} {category.nftCount ? `(${category.nftCount})` : "(0)"}
                  </button>
                );
              })}
              <Button
                className={`filter-btn btn ${isMoreCategoriesPanelVisible && "active"}`}
                type="button"
                onClick={() => {
                  toggleCategoriesPanelVisible();
                }}>
                {handleTheLanguageTranslation(label?.more, "More")}
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="seond-wrap">
          <Button className={`filter-btn btn ${filterVisible ? "active" : ""}`} type="button" onClick={() => setFilterVisible(!filterVisible)}>
            {handleTheLanguageTranslation(label?.filter, "Filter")}
          </Button>
        </div>
      </div>
      {isMoreCategoriesPanelVisible && (
        <MoreCategoriesPanel
          isOpen={isMoreCategoriesPanelVisible}
          toggleCategoriesPanelVisible={toggleCategoriesPanelVisible}
          categorySearch={categorySearch}
          setCategorySearch={setCategorySearch}
          selectedLetter={selectedLetter}
          setSelectedLetter={setSelectedLetter}
          categoriesList={categoriesList}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          resetPagination={resetPagination}
        />
      )}
    </Col>
  );
};

export default WithErrorBoundaryHandler(React.memo(ExplorerCategories), "Categories");
