// const stripe = require("stripe")("sk_test_4eC39HqLyjWDarjtT1zdp7dc");
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardNumberElement } from "@stripe/stripe-js";
import { IMintBlockchainList, useMintPriceListQuery } from "actions/app/mint-nft-price";
import { useCreateNewPaymentMutation } from "actions/app/pay-mint-price";
import { MintGateways } from "actions/enum/gateway-types";
import { PAYMENT_GATEWAY } from "actions/enum/payment-type";
import { useGetPricingPlanLIstQuery } from "actions/pricing-plan-list";
import { makeFullS3ObjectUrl } from "assets/images";
import { StripePaymentCardUI } from "components/stripe/StripePaymentCardUI";
import WithStripeProvider from "components/stripe/WithStripeProvider";
import { useFormik } from "formik";
import useLoadStripe from "hooks/useLoadStripe";
import useScrollTheReference from "hooks/useScrollTheReference";
import { MINT_STEPS } from "pages/mintNFT/MintNFTPage";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, FormGroup, Spinner } from "reactstrap";
import { CardDetailsFormYupValidationSchema } from "schema/CardDetailsFormYupValidationSchema";
import { IRootReducer } from "store/root-reducer";
import "styles/components/ChoosePaymentOptions.scss";
import { cookieKeys } from "utils/constants/constants";
import { handleTheLanguageTranslation, setEncryptedCookie, toastError } from "utils/functions/commonFunctions";
const PaypalLogo = makeFullS3ObjectUrl("paypal-logo.svg");

export interface IPaymentFormikValues {
  nameOnCard: string;
}

export const PaymentOptionListItem = ({
  isDisabled,
  paymentGateway,
  setPaymentGateway,
  gatewayItemName,
  gatewayLogo
}: {
  setPaymentGateway: React.Dispatch<React.SetStateAction<PAYMENT_GATEWAY>>;
  gatewayLogo: string;
  paymentGateway: PAYMENT_GATEWAY;
  gatewayItemName: PAYMENT_GATEWAY;
  isDisabled: boolean;
}) => {
  return (
    <FormGroup className={"form-input-mb "}>
      <div className="sm-wrap">
        <label id={`cypress-${gatewayItemName}`}>
          <input
            type="radio"
            name="getway"
            className="card-input-element"
            disabled={isDisabled}
            id={gatewayItemName}
            value={gatewayItemName}
            checked={paymentGateway === gatewayItemName}
            onChange={(e) => setPaymentGateway(e.target.value as PAYMENT_GATEWAY)}
          />
          <div className="panel panel-default card-input">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="text-bl">{gatewayItemName === PAYMENT_GATEWAY.STRIPE ? <h3>{gatewayLogo}</h3> : <img src={gatewayLogo} className="img-fluid" alt="" style={{ maxWidth: 200 }} />}</div>
              <div className="custom-radio-btn"></div>
            </div>
          </div>
        </label>
      </div>
    </FormGroup>
  );
};

const ChoosePaymentOptions = ({ gateway, setMintType, customNftTemplate }: { gateway: MintGateways; setMintType: React.Dispatch<React.SetStateAction<number>>; customNftTemplate?: number }) => {
  const [paymentGateway, setPaymentGateway] = useState(PAYMENT_GATEWAY.WALLET);
  const [tokenLoading, setTokenLoading] = useState(false);
  const { id: nftId } = useParams() || {};
  const navigate = useNavigate();
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  const { userWallet } = useSelector((state: IRootReducer) => state.userDetailsReducer.user);
  // let userWallet = 10;
  const { data: pricingPlanLists } = useGetPricingPlanLIstQuery();

  // scroll the mintwrapper form when user move mouse wheel
  const mintWrapperRef = useRef<HTMLDivElement>(null);
  useScrollTheReference(mintWrapperRef);

  // if user is a investor or not credits available
  const { isCustomUser } = useSelector((state: IRootReducer) => state.userDetailsReducer.user) || {};
  useEffect(() => {
    if (isCustomUser) {
      setPaymentGateway(PAYMENT_GATEWAY.STRIPE);
    } else if (userWallet <= 0) {
      setPaymentGateway(PAYMENT_GATEWAY.PAYPAL);
    }
  }, [isCustomUser, userWallet]);

  const paymentFormik = useFormik({
    initialValues: {
      nameOnCard: ""
    },
    validationSchema: CardDetailsFormYupValidationSchema(paymentGateway, label),
    onSubmit: (values) => {
      // default it will be current selected payment gateway.
      // otherwise it will become a hybrid payment gateway if user has not sufficient amount in wallet.
      let finalMintPaymentGateway = paymentGateway;
      if (paymentGateway !== PAYMENT_GATEWAY.WALLET && amountOfMintingAnNft > userWallet && userWallet > 0) {
        // make gateway as paypal and wallet both combination
        // api will receive wallet_paypal to make the payment on backend side.
        if (paymentGateway === PAYMENT_GATEWAY.PAYPAL) {
          finalMintPaymentGateway = PAYMENT_GATEWAY.WALLET_PAYPAL;
        } else {
          finalMintPaymentGateway = PAYMENT_GATEWAY.WALLET_STRIPE;
        }
      }
      if (paymentGateway === PAYMENT_GATEWAY.STRIPE) {
        createPaymentWithStripe(finalMintPaymentGateway);
      } else if (paymentGateway === PAYMENT_GATEWAY.PAYPAL) {
        /**
         * If we are making payment for nft mint then the planId will be 5th only.
         *
         * But if we are on wallet screen and adding credits to wallet then wallet Id will be 4 for paypal
         */
        let myPlanId = pricingPlanLists?.data.find((plan) => plan.type === 5)?._id;

        let paymentAPIParams = {
          nftId: nftId || "",
          gateway: gateway,
          purchaseFrom: "web",
          purchasePlanType: finalMintPaymentGateway,
          // sending the default plan id this is just a placeholder
          planId: myPlanId || "",
          saveTemplate: customNftTemplate || 0
        };

        setMintType(MINT_STEPS.TRANSACTION_IN_PROGRESS);

        callCreatePaymentMutation(paymentAPIParams)
          .then((result: any) => {
            if ("data" in result && result.data.status === 200) {
              // set cookie to keep track that payment is initiated
              // in case if user directly visits the payment page with query params and we do not find cookie then the user is not legit.
              setEncryptedCookie(cookieKeys.paypalPayment, true);
              nftId && setEncryptedCookie(cookieKeys.nftId, nftId);
              // redirect to paypal payment page.
              window.location.href = result.data.data;
            } else {
              throw new Error(result.error.data.message);
            }
          })
          .catch((err) => {
            navigate("/payment", { state: { paymentStatus: false } });
            // toastError(err?.message);
          });
      } else if (paymentGateway === PAYMENT_GATEWAY.WALLET) {
        /**
         * If we are making payment for nft mint then the planId will be 5th only.
         *
         * But if we are on wallet screen and adding credits to wallet then wallet Id will be 4 for paypal
         */
        let myPlanId = pricingPlanLists?.data.find((plan) => plan.type === 5)?._id;

        let paymentAPIParams = {
          nftId: nftId || "",
          gateway: gateway,
          purchaseFrom: "web",
          purchasePlanType: PAYMENT_GATEWAY.WALLET,
          // sending the default plan id this is just a placeholder
          planId: myPlanId || "",
          saveTemplate: customNftTemplate || 0
        };

        setMintType(MINT_STEPS.TRANSACTION_IN_PROGRESS);

        callCreatePaymentMutation(paymentAPIParams)
          .then((result: any) => {
            if ("data" in result && result.data.status === 200) {
              navigate("/payment", {
                state: {
                  paymentStatus: true,
                  gateway: gateway,
                  nftId: nftId,
                  customNftTemplate: customNftTemplate
                }
              });
            } else {
              throw new Error(result.error.data.message);
            }
          })
          .catch((err) => {
            navigate("/payment", { state: { paymentStatus: false } });
            // toastError(err?.message);
          });
      }
    }
  });
  const { handleSubmit, values } = paymentFormik;

  // stripe and create payment operations
  const [callCreatePaymentMutation] = useCreateNewPaymentMutation();
  useLoadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");
  const elements = useElements();
  const stripe = useStripe();
  //  first create stripe token
  // then call the payment api in backend with this token
  const createPaymentWithStripe = async (finalMintPaymentGateway: PAYMENT_GATEWAY) => {
    setTokenLoading(true);
    function callPaymentApi(stripeToken: string) {
      /**
       * If we are making payment for nft mint then the planId will be 5th only.
       *
       * But if we are on wallet screen and adding credits to wallet then wallet Id will be 3 for stripe
       */
      let myPlanId = pricingPlanLists?.data.find((plan) => plan.type === 5)?._id;

      let paymentAPIParams = {
        nftId: nftId || "",
        gateway: gateway,
        purchaseFrom: "web",
        purchasePlanType: finalMintPaymentGateway,
        // sending the default plan id this is just a placeholder
        planId: myPlanId || "",
        token: stripeToken,
        saveTemplate: customNftTemplate || 0
      };
      setMintType(3);

      callCreatePaymentMutation(paymentAPIParams)
        .then((result: any) => {
          if ("data" in result && result.data.status === 200) {
            navigate("/payment", {
              state: {
                paymentStatus: true,
                gateway: gateway,
                nftId: nftId,
                customNftTemplate: customNftTemplate
              }
            });
          } else {
            throw new Error(result.error.data.message);
          }
        })
        .catch((err) => {
          navigate("/payment", { state: { paymentStatus: false } });
          // toastError(err?.message);
        });
    }

    // if stripe integration iis not correct it will throw an error
    // handling that error and setting loading to false
    try {
      stripe?.createToken(elements?.getElement("cardNumber") as StripeCardNumberElement, { name: values.nameOnCard }).then((result) => {
        if ("token" in result && result.token) {
          callPaymentApi(result.token.id);
        } else if ("error" in result && result.error.message) {
          setTokenLoading(false);
          toastError(result.error.message);
        }
      });
    } catch (error) {
      setTokenLoading(false);
      alert(error);
    }
  };

  // set curretn blockchain price to send it to parent component.
  // get current gateway prce first
  // calculate the current nft minting amount value.
  const [amountOfMintingAnNft, setAmountOfMintingAnNft] = useState(0.0);
  const { data: mintGatewayPrices } = useMintPriceListQuery({});
  useEffect(() => {
    if (Array.isArray(mintGatewayPrices?.data)) {
      // eslint-disable-next-line eqeqeq
      let currentPrice = (mintGatewayPrices?.data as IMintBlockchainList[])?.find((currChain) => currChain.gateway == gateway)?.totalPrice || 0.0;
      setAmountOfMintingAnNft(currentPrice);
    }
  }, [gateway, mintGatewayPrices, mintGatewayPrices?.data]);

  return (
    <div className="d-flex mb-5 mb-lg-0 align-items-center align-items-lg-start text-lg-start h-100">
      <div className="max-content-title-and-wrapper w-100" ref={mintWrapperRef}>
        <div className="flex-fill">
          <h1 className="subtitle f-700 c-tx-secondary text-center text-md-start c-mb-30">{handleTheLanguageTranslation(label?.choose_payment_option, "Choose payment option")}</h1>
          <p className="c-tx-forth mb-4">
            Important note : once your NFT is deployed on the network, <span className="fw-bold">you will not be able to edit or update any of its information.</span>
          </p>
          <div
            className={`d-flex check-credit align-items-center position-relative ${userWallet <= 0 ? "disable opacity-50" : ""}`}
            role="button"
            onClick={(e) => {
              setPaymentGateway(PAYMENT_GATEWAY.WALLET);
            }}>
            {/* Below code is commented for temporary: As we do have some stripe payment issue, dinesh said to hide this - 11/01/2023 */}
            {/* <label className="custom-checkbox"> */}

            {/* This code is not commnted by me on:11/01/2023 */}
            {/* <input type="hidden" name="custom-checkbox" value="False" /> */}
            {/* if user wallet is not having sufficient amount to mint the nft, he will select either paypal or stripe with wallet */}

            {/* Below code is commented for temporary: As we do have some stripe payment issue, dinesh said to hide this - 11/01/2023 */}
            {/*  <input
                className="custom-checkbox-input"
                name="custom-checkbox"
                value="True"
                type="checkbox"
                checked={paymentGateway === PAYMENT_GATEWAY.WALLET || (amountOfMintingAnNft > userWallet && userWallet > 0)}
                onChange={() => {}}
              />
              <span className="custom-checkbox-box position-absolute">
                <span></span>
              </span> */}
            {/* </label>
            <p className="c-tx-secondary mb-0">
              Use available credit <span className="fw-bold">{userWallet || 0.0} USD</span>
            </p> */}
          </div>
          {/* show user a message that he has to select a payment gateway for the remaining amount. */}
          {userWallet !== 0 && amountOfMintingAnNft > userWallet ? (
            <p className="c-tx-third alert" role={"alert"}>
              Your wallet dosen't have enough balance to mint this NFT, please select a payment gateway to pay the remaining amount of{" "}
              <b>{Math.abs(amountOfMintingAnNft - userWallet).toFixed(2)} USD.</b>
            </p>
          ) : null}
          <Form>
            <PaymentOptionListItem
              gatewayLogo={PaypalLogo}
              isDisabled={(isCustomUser as boolean) || amountOfMintingAnNft < userWallet}
              paymentGateway={paymentGateway}
              setPaymentGateway={setPaymentGateway}
              gatewayItemName={PAYMENT_GATEWAY.PAYPAL}
            />

            {/* Below code is commented for temporary: As we do have some stripe payment issue, dinesh said to hide this */}

            {/* <PaymentOptionListItem
              gatewayLogo={handleTheLanguageTranslation(label?.credit_debit_card, "Credit / Debit Card")}
              isDisabled={(isCustomUser as boolean) || amountOfMintingAnNft < userWallet}
              paymentGateway={paymentGateway}
              setPaymentGateway={setPaymentGateway}
              gatewayItemName={PAYMENT_GATEWAY.STRIPE}
            /> */}
            {paymentGateway === PAYMENT_GATEWAY.STRIPE && <StripePaymentCardUI paymentFormik={paymentFormik} />}

            <div className="d-flex justify-content-between">
              <Button
                className="custom-primary-outline dark-text"
                onClick={(e) => {
                  navigate(0);
                }}>
                {handleTheLanguageTranslation(label?.go_back, "Go Back")}
              </Button>
              <Button
                className="custom-primary-outline dark-text"
                onClick={(e) => {
                  handleSubmit();
                }}>
                {/* @todo - add usd price from the blockchain or the price which user selected */}
                {tokenLoading ? <Spinner size={"sm"} /> : `${handleTheLanguageTranslation(label?.pay_now, "Pay Now")} ${amountOfMintingAnNft} USD`}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default WithStripeProvider(ChoosePaymentOptions);
