import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { getUserInfo } from "actions/auth/user-info";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { onUserLogIN, onUserLogout } from "store/auth/action";
import { onUserDetailsUpdate, setIsAccess, setUserIdAndUserName } from "store/user/action";
import { removeEmptyParameters } from "utils/decorators/RemoveEmptyParameters";
import store from "../store/index";
import { setLoading } from "../store/loader/action";
import { cookieKeys, localStorageKeys } from "./constants/constants";
import { getDecryyptedCookie, setEncryptedLocalStorage, toastError } from "./functions/commonFunctions";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_APIBASEURL
});

const errorInterceptor = (errorResponse: AxiosResponse) => {
  store.dispatch(setLoading(false));
  if (errorResponse) {
    const { status } = errorResponse.data;
    if (status === 401) {
      store.dispatch(onUserLogout());
    } else if (status === 406) {
      getUserInfo().then((response) => {
        store.dispatch(setIsAccess(response.data.isAccess));
        setEncryptedLocalStorage(localStorageKeys.isLoggedIN, response.data);
        // this id is used to fetch the profile information of user.
        // it will be set to link tag so when user click on profile link he will be redirect to right page.
        // otherwise the userId value we receive from backend is the unique id.
        // but to fetch nft details of a particular user we need to send his _id.
        // that's why we are setting it in localstorage so it will always be available to use after login.
        setEncryptedLocalStorage(localStorageKeys.user_id, response.data?._id);
        setEncryptedLocalStorage(localStorageKeys.username, response.data?.username);
        store.dispatch(setIsAccess(response.data.isAccess));
        store.dispatch(setLoading(false));
        store.dispatch(onUserLogIN());
        store.dispatch(onUserDetailsUpdate(response.data));
        store.dispatch(
          setUserIdAndUserName({
            userid: response.data?._id,
            username: response.data?.username
          })
        );
        window.location.reload();
      });
    } else if (status === 425) {
      // 425 => nft processing is in progress error.
      // this may usually happen because of blockchain error
      // like - we already send the mint request for that nft but blockchain is not responding and the nft still shows in our draft and we try to update the draft then this erro happen.
      // we are simply showing error toast and reloading the page. to redirect user back to create nft page.
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      toastError(errorResponse.data.message);
    } else if (status === 404) {
      // window.location.href = "/404";
    } else if (status === 400) {
      if (errorResponse.data?.message) {
        if (errorResponse.data.message === "Invalid Request") {
          window.location.href = "/404";
        } else {
          toastError(errorResponse.data.message);
        }
      }
    } else if (status === 403) {
      // if (window.location.pathname.includes("/create-mint")) {
      //   window.location.reload();
      // }
      if (errorResponse.data.message !== "Please subscribe to authenticate") {
        if (errorResponse.data.message !== "NFT minting has failed. You have used all your free credits. If you still wish to mint, please select another chain.") {
          toastError(errorResponse.data.message);
        }
      }
      // toastError(errorResponse.data.message);
    } else {
      toastError(errorResponse.data.message);
    }
  }
};

axiosInstance.interceptors.request.use(
  (req) => {
    const cookie = getDecryyptedCookie(cookieKeys.cookieUser);
    // check for token
    if (cookie && cookie.token) {
      // @ts-ignore
      req.headers.Authorization = `Bearer ${cookie.token}`;
    }
    return req;
  },
  (err) => {
    // catches client side error like no internet etc
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (req) => {
    // setisLoaderActive(false);
    return req;
  },
  (err) => {
    errorInterceptor(err.response);
    return Promise.reject(err);
  }
);

export default class HTTPService {
  @removeEmptyParameters()
  static get<T = never, R = AxiosResponse<T>>(url: string, params?: any): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(url, params)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static put<T = never, R = AxiosResponse<T>>(url: string, body: any): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(url, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static post<T = never, R = AxiosResponse<T>>(url: string, body: any): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(url, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static delete<T = never, R = AxiosResponse<T>>(url: string, body: any): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(url, { data: body })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static deleteWithParams<T = never, R = AxiosResponse<T>>(url: string, params: any): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(url, params)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static userInfo<T = never, R = AxiosResponse<T>>(url: string, body: any): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(url, { data: body })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static authenticateUserBstamp<T = never, R = AxiosResponse<T>>(url: string, body: any, config: any): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(url, body, config)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }
}

export const reactToolKitAxiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    /**
     * Remove all the empty params [key: value] pairs
     * @removeEmptyParameters - we were using it earlier in httpService class.
     * Now we are removing empty params in next line
     */
    let senatizedParams = params
      ? Object.fromEntries(
          Object.entries(params).filter(([key, value]) => {
            if (typeof value === "boolean") {
              return true;
            }

            return !!value;
          })
        )
      : {};
    try {
      const result = await axiosInstance({
        url: url,
        method,
        data,
        params: senatizedParams,
        headers
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message
        }
      };
    }
  };
