import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const deleteNftTemplateAPI = (id: string): Promise<IDeleteTemplateResponse> => {
//   return HTTPService.deleteWithParams(`${apiEndPoints.app.deleteTemplate}?id=${id}`, "");
// };

const deleteNftTemplateApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    deleteNftTemplateAPI: build.mutation<IDeleteTemplateResponse, { id: string }>({
      query: (params) => ({
        url: apiEndPoints.app.deleteTemplate,
        method: "delete",
        params: params
      })
    })
  })
});

export const { useDeleteNftTemplateAPIMutation } = deleteNftTemplateApiSlice;

export interface IDeleteTemplateResponse {
  status: number;
  message: string;
}
