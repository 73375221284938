import { makeFullS3ObjectUrl } from "assets/images";
import { FC, memo, useState } from "react";

const defualtBannerImage = makeFullS3ObjectUrl("profile-banner.png");

interface ITopsUserImageComponent {
  bannerImage: string;
}

const TopsUserImageComponent: FC<ITopsUserImageComponent> = ({ bannerImage }) => {
  const [state, setState] = useState({
    error: true,
    defulatBannerImage: defualtBannerImage
  });
  const { error, defulatBannerImage } = state;

  return (
    <>
      <img
        src={error ? defulatBannerImage : bannerImage}
        alt={"alt"}
        onLoad={() => {
          setState({ ...state, error: false });
        }}
        className="img-fluid"
        onError={() => {
          setState({ ...state, error: true });
        }}
      />
    </>
  );
};

export default memo(TopsUserImageComponent);
