import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AES, enc, SHA512 } from 'crypto-js'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import {
  fileValidation,
  imageValidation,
  videoValidation
} from '../../components/createnft/TypeValidation'
import { setAttachments, setStep } from '../../store/createNFT/action'
import { IRootReducer } from '../../store/root-reducer'
import {
  IDocumentType,
  IHTMLElementType
} from '../@types/global-types'
import {
  cookieExpiresInDays,
  cookieKeys,
  toasterPosition
} from '../constants/constants'
import { getAudioFingerPrint } from './getAudioFingerPrint'

const toastSuccess = (message: string) => {
  toast.remove()
  toast(message, {
    duration: 4000,
    position: toasterPosition,
    style: {
      color: '#090909',
      minWidth: 120,
      padding: 10,
      fontWeight: 500,
      marginBottom: 60,
      border: '1px solid #DADCE0',
      wordBreak: 'break-all'
    },
    className: '',
    icon: (
      <FontAwesomeIcon
        icon={faCheck}
        style={{
          color: '#f71137',
          fontSize: '20px',
          border: '1px solid #f71137',
          borderRadius: '50%',
          padding: 4
        }}
      />
    ),
    iconTheme: {
      primary: '#f71137',
      secondary: '#fff'
    }
  })
}

const toastError = (message: string) => {
  toast.remove()
  toast(message, {
    duration: 4000,
    position: toasterPosition,
    style: {
      color: '#090909',
      minWidth: 120,
      padding: 10,
      fontWeight: 500,
      marginBottom: 60,
      border: '1px solid #DADCE0'
    },
    className: '',
    // Custom Icon
    icon: (
      <div className="info-icon" style={{ position: 'relative' }}>
        <span style={{ position: 'absolute', top: '1.5px' }}>!</span>
      </div>
    ),
    // Change colors of success/error/loading icon
    iconTheme: {
      primary: '#f71137',
      secondary: '#fff'
    }
  })
}

export const toastInfo = (message: string) => {
  toast(message, {
    duration: 4000,
    position: toasterPosition,
    style: {
      color: '#090909',
      minWidth: 120,
      padding: 10,
      fontWeight: 500,
      marginBottom: 60,
      border: '1px solid #DADCE0'
    },
    className: '',
    // Custom Icon
    icon: <span className="info-icon">i</span>,
    // Change colors of success/error/loading icon
    iconTheme: {
      primary: '#f71137',
      secondary: '#fff'
    }
  })
}

export const handleErrors = () => {
  // prevent production and staging console and warnings
  if (
    process.env.REACT_APP_ENV === 'PRODUCTION' ||
    process.env.REACT_APP_ENV === 'STAGING'
  ) {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
    console.warn = () => {}
  }
}

/**
 * This function will take a file as input and returns its base64 version string.
 *
 * @param {File} file the file which need to be converted to base64
 * @returns {string} `base64 string`
 *
 * @isTestWrittenForThisFunction `true`
 */
export const getBase64 = (file: File) => {
  if (file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        return resolve(reader.result)
      }
      reader.onerror = function (error) {
        reject(false)
      }
    })
  } else {
    return new Promise((resolve, reject) => resolve(''))
  }
}

/**
 * If file size is greater than the second argument then it returns false
 *
 * @param {File} file the original file which need to be validated agains the second param
 * @param {number} size the size for which the input file has to be validated
 * @returns {boolean} true | false
 * @example
 * Call validateFileSize(event.target.files[0], 1024)
 *  if file size is equal to 1kb then returns `true`
 *  else returns `false`
 *
 * @isTestWrittenForThisFunction `true`
 */
export const validateFileSize = (file: File, size: number) => {
  // file will be the file boject
  if (file?.size > size) {
    return false
  } else {
    return true
  }
}

/**
 * This function will set the encrypted data to localstorage.
 *
 * @param {string} key  name of the item to be set in localstorage
 * @param {string} data data to be set in localstorage
 *
 * @isTestWrittenForThisFunction `true`
 */
export const setEncryptedLocalStorage = <
  T extends
    | string
    | object
    | boolean
    | Array<string>
    | Array<{ [key: string]: string | object }>
>(
  key: string,
  data: T
) => {
  if (data && key) {
    const encryptedString = encryptData(data)
    const keyName = cookieKeys.cookieInitial + '-' + key.trim()
    window.localStorage.setItem(keyName, encryptedString.toString())
  }
}

/**
 * This function will get the item from the browser local storage decrypt it and then returns it.
 *
 * @param {string} key - name of the localstorage item key
 * @returns {string} value of the input key
 *
 * @isTestWrittenForThisFunction `true`
 */
export const getDecryptedLocalStorage = (key: string) => {
  if (key) {
    const keyName = cookieKeys.cookieInitial + '-' + key.trim()
    const localStorageData = window.localStorage.getItem(keyName)
    if (localStorageData) {
      return decryptData(localStorageData)
    } else {
      const cookieUser = getDecryyptedCookie(cookieKeys.cookieUser)
      if (!cookieUser) {
        removedCookie(cookieKeys.cookieUser)
      }
    }
  }
}

/**
 * This function accepts any input type and returns an encrypted string.
 *
 * @param {any} data
 * @returns {string}
 *
 * @isTestWrittenForThisFunction `true`
 */
export const encryptData = <
  T extends
    | string
    | object
    | boolean
    | Array<string>
    | Array<{ [key: string]: string | object }>
>(
  data: T
) => {
  return AES.encrypt(JSON.stringify(data), cookieKeys.cryptoSecretKey)
}

/**
 * This function wil decrypt an encrypted string {the encrypted string suppose to be an object}
 *
 * @param {string} data
 * @returns {json | string}
 *
 * @isTestWrittenForThisFunction `true`
 */
export const decryptData = (data: string) => {
  const bytes = AES.decrypt(
    data.toString(),
    cookieKeys.cryptoSecretKey
  )
  if (bytes.toString()) {
    return JSON.parse(bytes.toString(enc.Utf8))
  }
  return ''
}

/**
 * It will set and encrypted cookie to localhost and lateron we can use this cookie by decrypting it.
 * @param  {string} key
 * @param {any} data
 *
 * @isTestWrittenForThisFunction `true`
 */
export const setEncryptedCookie = <
  T extends
    | string
    | object
    | boolean
    | Array<string>
    | Array<{ [key: string]: string | object }>
>(
  key: string,
  data: T
) => {
  if (data && key) {
    const encryptedString = encryptData(data)
    const keyName = cookieKeys.cookieInitial + '-' + key.trim()
    const date = new Date()
    const expiryTime = new Date(
      date.setTime(
        date.getTime() + cookieExpiresInDays * 24 * 60 * 60 * 1000
      )
    ).toUTCString()
    document.cookie = `${keyName}=${encryptedString};expires=${expiryTime};domain=${window.location.hostname.replace(
      'accounts',
      ''
    )};secure;path=/;`
    // this.localStorageService.set(keyName, encryptedString);
  }
}

/**
 * This function will take cookie name as input and returns its decrypted value
 *
 * @param {string} key the name of the cookie
 * @returns {string} decrypted cookie string
 *
 * @isTestWrittenForThisFunction `true`
 */
export const getDecryyptedCookie = (key: string) => {
  if (key) {
    const keyName = cookieKeys.cookieInitial + '-' + key.trim()
    const cookieData = getCookie(keyName)
    if (cookieData) {
      return decryptData(cookieData)
    }
  }
}

/**
 * It will take a key as Input and returns the cookie value of that key
 * @param {string} cookieName
 * @returns {string} cookie
 *
 * @isTestWrittenForThisFunction `false`
 */
const getCookie = (cookieName: string) => {
  let name = cookieName + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  if (decodedCookie) {
    let ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim()
      while (c.charAt(0) === '') {
        c = c.substring(1)
      }
      if (+c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
  }
  return ''
}

/**
 * It will set cookie for localhost use, without any encryption - it will set as it is.
 * We are using this function for localHostLogin [useLocalhostLogin] custom hook.
 *
 * @param {string} key
 * @param {any} data
 *
 * @isTestWrittenForThisFunction `false`
 */
export const setEdexaCookieForLocalhost = <
  T extends
    | string
    | object
    | boolean
    | Array<string>
    | Array<{ [key: string]: string | object }>
>(
  key: string,
  data: T
) => {
  if (data && key) {
    const keyName = cookieKeys.cookieInitial + '-' + key.trim()
    const date = new Date()
    const expiryTime = new Date(
      date.setTime(
        date.getTime() + cookieExpiresInDays * 24 * 60 * 60 * 1000
      )
    ).toUTCString()
    document.cookie = `${keyName}=${data};expires=${expiryTime};domain=${window.location.hostname.replace(
      'accounts',
      ''
    )};secure;path=/;`
    // this.localStorageService.set(keyName, encryptedString);
  }
}

/**
 * This function takes a key as input and remove that key's cookie from storage.
 * @param {string} key name of the cookie
 *
 * @isTestWrittenForThisFunction `false`
 */
export const removedCookie = (key: string) => {
  if (key) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const keyName = cookieKeys.cookieInitial + '-' + key.trim()
    document.cookie = `${keyName}=;expires=${new Date(
      0
    ).toUTCString()};domain=${window.location.hostname.replace(
      'nft',
      ''
    )};path=/;`
    // document.cookie = `${keyName}=;expires=${new Date(0).toUTCString()};domain=localhost;path=/;`;
  }
}

/**
 *
 * @param {isRedirect} boolean if true user will be redirect to login page
 * @returns Either redirect to login page or return null
 *
 * @isTestWrittenForThisFunction `false`
 */
const handleLogout = (isRedirect?: boolean) => {
  localStorage.clear()
  removedCookie(cookieKeys.cookieUser)

  if (isRedirect) {
    if (process.env.REACT_APP_ENV === 'DEVELOPMENT') {
      window.location.href =
        process.env.REACT_APP_LOCAL_authWithEdexaLogin +
          window.location.hostname || ''
      return null
    }
    window.location.href =
      process.env.REACT_APP_authWithEdexaLogin || ''
    return null
  }
  return null
}

/**
 * This function returns a first letter capitalized string;
 *
 * @param {string} string
 * @returns {string}
 *
 * @isTestWrittenForThisFunction `true`
 */
const capitalizeFirstLetter = (string: string) => {
  return (
    string?.toString().charAt(0).toUpperCase() + string.slice(1) ||
    string
  )
}

/**
 * This function will take an long number as input and returns its short form
 *
 * @param {number} num
 * @param {number} digits
 * @returns {string}
 *
 * @example Input - `10000` Output - `10K`
 *
 * @isTestWrittenForThisFunction `true`
 */
const numberFormatter = (num: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value
    })
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') +
        item.symbol
    : '0'
}

/**
 * It will take a long string as input and returns the short form of it
 *
 * @param {string} fileName
 * @returns {string}
 *
 * @example `fileName.ext` "fileLength will be upto 25char without extension."
 *
 * @isTestWrittenForThisFunction `true`
 */
const returnFormattedBigFileNames = (fileName: string) => {
  if (!fileName) return ''
  if (fileName.length < 25) return fileName
  let fileNameWithoutExtension = fileName?.slice(
    0,
    fileName?.lastIndexOf('.')
  )
  return `${fileNameWithoutExtension?.slice(0, 25)}.${fileName?.slice(
    ((fileName?.lastIndexOf('.') - 1) >>> 0) + 2
  )}`
}

/**
 *
 * @param fileName
 * @returns {string} the extension of the file
 *
 * @isTestWrittenForThisFunction `true`
 */
export const getFileExtensionName = (fileName: string) => {
  return fileName?.slice(((fileName?.lastIndexOf('.') - 1) >>> 0) + 2)
}

/**
 * We are not using this function anywhere and this always return false,
 * @todo - fix this functions working to validate dates.
 * @param date
 * @returns
 *
 * @isTestWrittenForThisFunction `true`
 */
export function isValidDate(date: string) {
  return (
    date && Object.prototype.toString.call(date) === '[object Date]'
  )
}

/**
 * This function will scroll to a given element by its Id and It also accept the offset.
 * what is offset - https://developer.mozilla.org/en-US/docs/Web/CSS/offset
 *
 * @param headerOffset {number} - it is the value which will be used as offset from top while scrolling
 * @param elementId {string} - this id of the element whom we want to scroll into view.
 *
 * @isTestWrittenForThisFunction `false`
 */
export const scrollToTargetWithOffset = (
  headerOffset: number,
  elementId: string
) => {
  var element = document.getElementById(elementId)
  var elementPosition: any = element?.getBoundingClientRect().top
  // @ts-ignore
  var offsetPosition =
    elementPosition + window.pageYOffset - headerOffset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  })
}

/**
 * Accepts an status an as per status returns a particular css classname and the particular label for that status.
 *
 * @param statusCode {number}
 * @returns {result} - {label: "", class: ""}
 *
 * @isTestWrittenForThisFunction `true`
 */
export const getKyCStatus = (statusCode: number) => {
  let result = {
    label: '',
    class: ''
  } // eslint-disable-next-line
  switch (statusCode) {
    case 0:
      result = {
        label: 'Pending',
        class: 'pending'
      }
      break
    case 1:
      result = {
        label: 'Approved',
        class: 'active'
      }
      break
    case 2:
      result = {
        label: 'Rejected',
        class: 'deactive'
      }
      break

    default:
      result = {
        label: 'Not Started',
        class: 'pending'
      }
      break
  }
  return result
}

/**
 * It takes an string as input and remove its extra white spaces. ex - `ra j   e` will be `ra j e`
 *
 * @param {string} string
 * @returns {string}
 *
 * @isTestWrittenForThisFunction `true`
 */
export const removeExtraWhiteSpaces = (string: string) => {
  if (!string.trim().length) {
    return ''
  }
  return string.replace(/\s+/g, ' ') || string
}

// get date with month name
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
/**
 * @param `validDate`  Accepts a string which is a valid date;
 * @return a formatted date string | null
 * @example
 * Input - "2021-12-03 09:42:15"
 * Output - "3 December 2021"
 *
 * @isTestWrittenForThisFunction `true`
 */
export const getFormattedDateWithMonthName = (
  validDate: string,
  time: boolean = false
) => {
  try {
    const d = new Date(validDate)
    if (d.toString() !== 'Invalid Date' && validDate !== '') {
      const year = d.getFullYear() // 2019
      const date = d.getDate() // 23
      const month = months[d.getMonth()]
      const timeString12hr = d.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      })

      return `${date} ${month} ${year} ${
        time ? ', ' + timeString12hr : ''
      }`.trim()
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

/**
 * @remarks
 * This function should be used for simple usecases
 *
 * @result when we play a video all other videos should stop or pause.
 *
 * @isTestWrittenForThisFunction `false`
 */
export const selectAllVideoAndPerformMediaOperation = () => {
  document
    .querySelectorAll('video')
    .forEach((el: HTMLVideoElement) => {
      el.onplay = function (e) {
        document
          .querySelectorAll('video')
          .forEach((el1: HTMLVideoElement) => {
            if (el === el1) el1.play()
            else el1.pause()
          })
      }
    })
}

/**
 * @start
 * file validation method
 */
let extensionLists = {
  video: ['m4v', 'avi', 'mpg', 'mp4', 'webm'],
  image: ['jpg', 'gif', 'bmp', 'png', 'jpeg'],
  document: ['pdf', 'doc', 'docx'],
  application: ['pdf', 'doc', 'docx']
} //Create an object for all extension lists

export enum fileTypeEnum {
  IMAGE = 'image',
  VIDEO = 'video',
  DOCUMENT = 'document',
  APPLICATION = 'application'
}

export const ObjectOfFileMimeTypes = {
  IMAGE: 'image',
  VIDEO: 'video',
  DOCUMENT: 'document',
  APPLICATION: {
    PDF: 'application/pdf',
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    OCTETSTREAM: 'application/octet-stream'
  }
}

/**
 * @remarks
 * return the type of media
 *
 * @param {string} fileNameOrUrl It accepts a fileName or a complete url of file
 * @param {enum} fileType It accepts a type from fileTypeEnum
 *
 * @returns {boolean} boolean
 *
 * @example
 * Input 1: `https://abc.com/filepath/filename.edx.fileisfortesting.mp4`
 * Input 2: `fileTypeEnum.IMAGE`
 * result: true
 *
 * @isTestWrittenForThisFunction `true`
 */
export function validateSupportedFileType(
  fileNameOrUrl: string,
  fileType: fileTypeEnum
) {
  if (!fileNameOrUrl || !fileType) return
  return (
    extensionLists[fileType]?.indexOf(
      fileNameOrUrl.toLowerCase().split('.').pop() || ''
    ) > -1
  )
}

/**
 * Dear... Future coder hi 👋
 *
 * @remarks
 * Well! nice to see you finally decided to see whats going on in here. 😀
 *
 * @todo
 * keep the chain continues and add something Intresting of your choice 🙌🍸
 *
 * @list
 * 1. @Rajesh-Royal
 * 2. @
 *
 * Don't be afraid of adding your name to the list, if asked me, I will say You should and definately be adding
 *  your contry 😄
 *
 * well se ya!
 * @italic @bold nft ko helmet pehnanaa he 😅 🙋‍♀️
 *
 * @isTestWrittenForThisFunction - `No need to write tests for these functions`
 */

export const doSomethingNesty = () => {
  // this will print some funky messages to the console and a "sad life" programmer gif.
  ;(function async(url) {
    window.addEventListener('DOMContentLoaded', (event) => {
      // Create a new `Image` instance
      var image = new Image()

      image.onload = function () {
        // Inside here we already have the dimensions of the loaded image
        var style = [
          // Hacky way of forcing image's viewport using `font-size` and `line-height`
          'font-size: 1px;',
          // @ts-ignore
          'line-height: ' + this.height + 'px;',

          // Hacky way of forcing a middle/center anchor point for the image
          'padding: ' +
            // @ts-ignore
            this.height * 0.05 +
            'px ' +
            // @ts-ignore
            this.width * 0.5 +
            'px;',

          // Set image dimensions
          // @ts-ignore
          'background-size: ' +
            // @ts-ignore
            this.width +
            'px ' +
            // @ts-ignore
            this.height +
            'px;',

          // Set image URL
          'background: url(' + url + ');'
        ].join(' ')
        console.clear()
        // #1 welcome to my site
        let msg =
          '%c Hi 👋! Welcome to my site! 😇😇. Its really nice to see you here! 😁'
        let welcomeToSiteStyle = `
          font-size: 20px;
          font-family: monospace;
          background: white;
          display: inline-block;
          color: black;
          padding: 8px 19px;
          border: 1px dashed;
          margin-top: 40px;
          margin-bottom: 40px
        `
        console.log(msg, welcomeToSiteStyle)

        console.log(
          '%cOh! nooooo ',
          'color: red; font-family: sans-serif; font-size: 4.5em; font-weight: bolder; text-shadow: #000 1px 1px;'
        )

        // #2 show the image
        // notice the space after %c
        console.log('%c ', style)

        // #3 error message
        const errorStyle = `
          color:white;
          background: linear-gradient(312deg, rgba(255,0,0,1) 0%, rgba(241,255,0,1) 15%, rgba(0,255,12,1) 30%, rgba(0,254,255,1) 43%, rgba(0,1,255,1) 59%, rgba(250,0,253,1) 88%, rgba(255,0,0,1) 100%);
          border: 1px solid white;
          padding: 5px;
          font-family: "Comic Sans MS";
          font-size: 23px;
          margin-top: 20px;
          margin-bottom: 20px;
      `

        console.error(
          '%c🌈💖 An error has occurred and everything is ruined forever. 💖🌈',
          `${errorStyle}`
        )
      }

      // Actually loads the image
      image.src = url
    })
  })(
    'https://media3.giphy.com/media/l378ANQFpBCwTNtni/giphy.gif?cid=6c09b952ecd995e546169f821e5f1309d9ec096daba0ea4f&rid=giphy.gif'
  )
}

export const niceTryBrother = () => {
  console.clear()
  let msg = '%c Nice try brother!😁'
  let welcomeToSiteStyle = `
                font-size: 20px;
                font-family: monospace;
                background: white;
                display: inline-block;
                color: black;
                padding: 8px 19px;
                border: 1px dashed;
                margin-top: 40px;
                margin-bottom: 40px
              `
  console.log(msg, welcomeToSiteStyle)
}

/****************************************************** mislenious fun ends *** */

/**
 * This functions working
 * @Param {null}
 * @return {Promise} - resolve(string)
 *
 * @isTestWrittenForThisFunction `false`
 */
export const getDeviceId = () => {
  /***
   * @I userMediaFingerPrint
   * @II FingerPrintJs
   * @III canvasFingerPrint
   * @IV userAgentFingerPrint + canvasFingerPrint
   * @V WebRTC_FingerPrinting
   * @VI audioFingerprinting
   * @VII audio and canvas fingerprint
   */
  /**
   * @method 1
   * this method will get the user's device informations and generate a unique value
   * @drawback
   * 1. This function will return same value if another user has same config machine.
   * 2. If user changes browser plugins(extensions) then the value will also be changed.
   * 3. If user changes his monitor then this method will also give different result.
   * 4. mimeTypes is also depricated and will be removed from browsers in future
   *  @conclusion - this function should never be used
   */

  /* 
    let navigator_info = window.navigator;
    let screen_info = window.screen;
    let uid: any = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, "");
    uid += navigator_info.plugins.length;
    uid += screen_info.height || "";
    uid += screen_info.width || "";
    return (uid += screen_info.pixelDepth || "");
  */

  /**
   * It will generate unique value for each device always {particular browser}
   * @method 2
   */
  /**
   * This function will return a promise which result a string output
   * @fingerPrintJs - using fingerPrintJs library
   * @Param {null}
   * @return {Promise}
   */
  // return FingerprintJs.load()
  //   .then((response) => response.get())
  //   .then((result) => result.visitorId);

  /**
   * @method 3
   * @canvasFingerPrint
   * @Param {null}
   * @return {deviceId} - hashed value with sha512
   *
   * This method usages canvas based fingerprinting -
   * with this approach the statistics show that if you put this information together, your browser fingerprint will only match 1 in 286,777 others.
   * The alternate solution of this approach to return always unique value are
   * @first
   *  1. get the unique username of current user
   *  2. encrypt this value with the user as key
   *  3. return this value.
   * usagecase - usernames will be unique for each user and so our hashed value will be unique for each user.
   * For this approach to work we will need this function to take an string argument which will be the username value of the current logged-in user.
   * @second
   *  1. Combine method 1 and method 3 formulae
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const canvasFingerPrint = (function () {
    try {
      var canvas: HTMLCanvasElement = document.createElement('canvas')
      var ctx = canvas.getContext('2d') as CanvasRenderingContext2D
      var txt = 'edeXa9accounts..$#edeXa((^@gdsSRTdfyt!~cz'
      ctx.textBaseline = 'top'
      ctx.font = "16px 'Arial'"
      ctx.textBaseline = 'alphabetic'
      ctx.rotate(0.05)
      ctx.fillStyle = '#f60'
      ctx.fillRect(125, 1, 62, 20)
      ctx.fillStyle = '#069'
      ctx.fillText(txt, 2, 15)
      ctx.fillStyle = 'rgba(102, 200, 0, 0.7)'
      ctx.fillText(txt, 4, 17)
      ctx.shadowBlur = 10
      ctx.shadowColor = 'blue'
      ctx.fillRect(-20, 10, 234, 5)
      var string = canvas.toDataURL()
      var hash = 0
      if (string.length === 0) return 'nothing!'
      for (let i = 0; i < string.length; i++) {
        hash = (hash << 5) - hash + string.charCodeAt(i)
        // 1. leftshift operation
        // 2. 0 + char =
        // 3. 0char - this will be converted to binary
        // 4. return to step 1
        hash = hash & hash
        // bitwise and operation 1(1X1 = 0)
        //  the bit in the resulting binary representation is 1 (1 × 1 = 1); otherwise, the result is 0 (1 × 0 = 0 and 0 × 0 = 0)
        /**
         * example -
              0101 (decimal 5)
            AND 0011 (decimal 3)
              = 0001 (decimal 1)
         */
        // ref - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Bitwise_AND
      }
      const hashedDeviceId = SHA512(hash?.toString()).toString(
        enc.Base64
      )
      return hashedDeviceId //example - JxXleEyKh11uO0sQZoAF2ICnheXJiR0DVPAvp78qI/9ocQwE8hf8hiQu1EhK+v2L7GnePijKiu6Ygfhu/TY3uA==
    } catch (error) {
      /**
       * Catch Errors here
       */
      // fall back to default method if any error occurs
      let navigator_info = window.navigator
      let screen_info = window.screen
      let uid = String(navigator_info.mimeTypes.length)
      uid += navigator_info.userAgent.replace(/\D+/g, '')
      uid += navigator_info.plugins.length
      uid += screen_info.height || ''
      uid += screen_info.width || ''
      return SHA512((uid += screen_info.pixelDepth || '')).toString(
        enc.Base64
      )
    }
  })()
  // return canvasFingerPrint;

  /**
   * @method 4
   * @userAgentFingerPrint + @canvasFingerPrint
   * @Param {null}
   * @return {deviceId} - string
   * chances of uniqueness is increased by 1
   * This method is the combination fo the method 1 and method 2
   * usage case - canvas fingerprint + usernavigator value
   * drawback - if two peoples are uging same useragent then there is no meaning of using thsi method
   */
  // const userNavigator = window.navigator.userAgent.replace(/\D+/g, "");
  // return SHA256(currentUserDeviceId + userNavigator).toString(enc.Base64);

  /**
   * @method 5
   * @WebRTC_FingerPrinting
   * @param {null}
   * @return {peerConnectionUserId} - number
   *
   * Using webRtc to generate uniqueid
   * @browser_compatibility - https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection#browser_compatibility
   *
   * @drawbacks
   *  1. not supported in firefox and new versions of safari
   *  2. It generates unique Id for whole machine so if you change browser you Id will remain same.
   */
  // WebRTCUniqueId()
  //   .then((data) => {
  //   })
  //   .catch((error) => {
  //   });

  /**
   * @method 6
   * @audioFingerprinting
   * @param {null}
   * @return {Promise} - number
   *
   * @pitfalls - https://fingerprintjs.com/blog/audio-fingerprinting/
   * except tor browser and brave browser it will work like a charm. Brave and tor are privacy concious browsers so they slightly modify the audio signals.
   */
  const audioFingerPrint = new Promise((resolve, reject) => {
    try {
      getAudioFingerPrint.run(function (fingerprint: number) {
        resolve(fingerprint)
      })
    } catch (error) {
      reject(error)
    }
  })

  /**
   * @method 7 - @audioFingerPrinting + @canvasFingerPrinting
   * @Accuracy - high
   *
   * @param {null}
   * @return {Promise} - result sha512 hash5Kb+kh34lyLdojGH54E1B4RInTdpp9pwmKJgUJ8T7WgDuk13gAatlJ9DhWCAhejG5xgJnbj2KjQTr9PnwdFU1Q==
   */
  const AudioCanvasFingerPrint = new Promise((resolve, reject) => {
    audioFingerPrint
      .then((audioChannelResult) => {
        // resolve promise with sha512 hashing
        resolve(
          SHA512(canvasFingerPrint + audioChannelResult).toString(
            enc.Base64
          )
        )
      })
      .catch((error) => {
        reject('')
      })
  })
  return AudioCanvasFingerPrint
}

/**
 * Set an element into full screen
 *
 * @param {IHTMLElementType} element
 *
 * @isTestWrittenForThisFunction `false`
 */
export function GoInFullscreen(element: IHTMLElementType) {
  if (element.requestFullscreen) element.requestFullscreen()
  else if (element.mozRequestFullScreen)
    element.mozRequestFullScreen()
  else if (element.webkitRequestFullscreen)
    element.webkitRequestFullscreen()
  else if (element.msRequestFullscreen) element.msRequestFullscreen()
}

/**
 * Get out of full screen
 *
 * @returns {null}
 *
 * @isTestWrittenForThisFunction `false`
 */
export function GoOutFullscreen() {
  let document: IDocumentType = window.document
  if (document.exitFullscreen) document.exitFullscreen()
  else if (document.mozCancelFullScreen)
    document.mozCancelFullScreen()
  else if (document.webkitExitFullscreen)
    document.webkitExitFullscreen()
  else if (document.msExitFullscreen) document.msExitFullscreen()
}

/**
 * Is currently in full screen or not
 *
 * @returns {boolean}
 *
 * @isTestWrittenForThisFunction `false`
 */
export function IsFullScreenCurrently() {
  let document: IDocumentType = window.document
  var full_screen_element =
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement ||
    null

  // If no element is in full-screen
  if (full_screen_element === null) return false
  else return true
}

/**
 * check if a given element is in the viewport or not
 * @param el
 * @returns {boolean}
 *
 * @isTestWrittenForThisFunction `false`
 */
export function elementInViewport(
  el: HTMLElement | null | HTMLDivElement,
  callBack: IntersectionObserverCallback
) {
  if (!el) {
    return false
  }

  let observer = new IntersectionObserver(callBack)
  observer.unobserve(el)
  observer.observe(el)
  return false
}

/**
 *
 * @param  {string} message
 *
 * @isTestWrittenForThisFunction `true`
 */
// check if user wants to navigate away
export function enableBeforeUnload(message?: string) {
  window.onbeforeunload = function (e) {
    return 'You Cannot navigate away while minting is in progress'
  }
}
export function disableBeforeUnload() {
  window.onbeforeunload = null
}

// convert image to object part instead of base64 for better performance
// https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
/**
 *
 * @param file
 * @param revoke
 * @returns
 *
 * @isTestWrittenForThisFunction `true`
 */
export const importFileandPreview = (
  file: File,
  revoke?: boolean
): Promise<string> =>
  new Promise((resolve, reject) => {
    window.URL = window.URL || window.webkitURL
    let preview = window.URL.createObjectURL(file)
    // remove reference
    if (revoke) {
      window.URL.revokeObjectURL(preview)
    }
    setTimeout(() => {
      resolve(preview)
    }, 100)
  })

/**
 *
 * @param {base64String} dataURI
 * @returns {blob}
 *
 * @isTestWrittenForThisFunction `true`
 */
export function dataURItoBlob(dataURI: string) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

/**
 * Creates an anchor element `<a></a>` with
 * the base64 pdf source and a filename with the
 * HTML5 `download` attribute then clicks on it.
 *
 * Once the click action executes then the browser will download this pdf file.
 *
 * @param  {string} pdf
 * @return {void}
 *
 * @isTestWrittenForThisFunction `true`
 */
export function downloadPDFFromBase64(
  pdf: string,
  fileName: string
): void {
  const linkSource = `data:application/pdf;base64,${pdf}`
  const downloadLink = document.createElement('a')
  const downloadableFilename =
    'edexa-Nft-invoice-' + fileName + '.pdf'

  downloadLink.href = linkSource
  downloadLink.download = downloadableFilename
  downloadLink.click()
}

/**
 *
 * @param dataurl {string} - the url of the image
 * @param filename {string}
 * @returns {Promise<File>}
 *
 * @isTestWrittenForThisFunction `true`
 */
export function dataURLtoImageFile(
  dataurl: string,
  filename: string
): Promise<File> {
  return fetch(dataurl)
    .then((res) => res.blob())
    .then((blob) => {
      return new File([blob], filename, { type: 'image/jpg' })
    })
}

/**
 *
 * @param seconds {number}
 * @returns {string} ex. 00:00:00 // hh:mm:ss
 *
 * @isTestWrittenForThisFunction `true`
 */

export function SecondsToMinutesAndSeconds(seconds = 0): string {
  var d = new Date(seconds * 1000)
  function pad(i: number) {
    return ('0' + i).slice(-2)
  }
  var str =
    d.getUTCHours() +
    ':' +
    pad(d.getUTCMinutes()) +
    ':' +
    pad(d.getUTCSeconds())
  return str
}

/**
 * This function will take an boolean `true or false` as input and add `modal-open` class to `HTML` root.
 *
 * In CSS we have a condition which will hide the main scroll bar when a `modal is open`.
 *
 * Code reference - {@link ../../styles/style.scss | Style.scss} in line no - 82
 *
 *
 * @param {boolean} isModalOpen
 * @returns {void}
 *
 *  @isTestWrittenForThisFunction `true`
 */
export const toggleClassNameToRoot = (isModalOpen: boolean): void => {
  let root = document.getElementsByTagName('html')[0]
  if (isModalOpen) {
    root.classList.add('modal-open')
  } else {
    root.classList.remove('modal-open')
  }
}

/**
 * @remarks
 * We upload video files to nft - then server sends us back a s3 uploaded file link.
 *
 * This function will take an string/file-url as Input and check wheather the received url is for listening to `file-compress-progress` socket or its the url of s3 uploaded file after compression
 *
 *
 * @param {string} fileUrl
 * @returns {boolean | string}
 * return {`false`} if the url is of processed file or returns {`string`} if processing file [it means resource
 * compression is in progress the returned string will be used as id to listen to socket for that particular resource.]
 *
 * I write this function to listen to file processing socket [backend compress big files and sends progress socket]
 *
 * It Takes an fileUrl sent by backend and check if that url includes nft/ string;
 *   - If it has `nft/` string then this url is cannot be used as socket id to listen to compression.
 *   - But if this url dosen't include `bnft/` that means the file haven't uploaded to AWS s3. Backend sent
 *     it as a response to use for socket id to listend to compression progress of the file
 *
 *  @isTestWrittenForThisFunction `true`
 */
export const checkIfRequestIsForSocketListening = (
  fileUrl: string
): string | boolean => {
  // if falsy value then return
  if (!Boolean(fileUrl)) return false
  if (fileUrl?.split('.com/')?.[1]?.includes('nft/')) return false

  return fileUrl?.split('.com/')?.[1]?.split('.')[0]
}

/**
 * Detects if a user's browser suports webGL rendering [to run three.js animation on hoem page.]
 *
 * @param return_context - if you want results with details send it as true of any string
 * @returns {boolean}
 *
 *  @isTestWrittenForThisFunction `true`
 */
export function webgl_detect(return_context?: string | boolean) {
  if (!!window.WebGLRenderingContext) {
    var canvas = document.createElement('canvas'),
      names = [
        'webgl2',
        'webgl',
        'experimental-webgl',
        'moz-webgl',
        'webkit-3d'
      ],
      context: boolean | null | any = false

    for (var i = 0; i < names.length; i++) {
      try {
        context = canvas.getContext(names[i])
        if (context && typeof context.getParameter == 'function') {
          // WebGL is enabled
          if (return_context) {
            // return WebGL object if the function's argument is present
            return { name: names[i], gl: context }
          }
          // else, return just true
          return true
        }
      } catch (e) {}
    }

    // WebGL is supported, but disabled
    return false
  }

  // WebGL not supported
  return false
}

export const useUploadHook = () => {
  const handleStepChange = (number: number) =>
    dispatch(setStep(number))

  const dispatch = useDispatch()

  // handle file upload click

  const createNFT = useSelector(
    (state: IRootReducer) => state.createNFT
  )

  const { fileType } = createNFT
  // eslint-disable-next-line
  const [subTitle, setSubTitle] = useState({
    title: '',
    accept: '',
    size: ''
  })

  const handleAssetUploadOperation = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.files) return
    const file = e.target.files[0]
    if (file) {
      setTimeout(() => {
        handleStepChange(2)
        // increase the timing to show the uploading to ipfs screen
        // also you have to remove commented code in createNFTPage.tsx
      }, 0)
    }

    if (fileType === 'image') {
      if (imageValidation(file)) {
        await importFileandPreview(file).then((data) =>
          dispatch(
            setAttachments({
              attachments: file,
              fileBlob: data,
              compressedFIlePath: data,
              fileThumbPath: data,
              fileMimeType: file.type
            })
          )
        )
      }
    } else if (fileType === 'file') {
      fileValidation(file) &&
        (await importFileandPreview(file).then((data) => {
          dispatch(
            setAttachments({
              attachments: file,
              fileBlob: data,
              compressedFIlePath: data,
              // if file type is not pdf then it can be any other file [we are getting octet mime type from backend api,so making the frontend mime type for word files as octet]
              fileMimeType: file.type || 'application/octet-stream'
            })
          )
        }))
    } else if (fileType === 'video') {
      videoValidation(file) &&
        (await importFileandPreview(file).then((data) =>
          dispatch(
            setAttachments({
              attachments: file,
              fileBlob: data,
              compressedFIlePath: data,
              fileMimeType: file.type
            })
          )
        ))
    }
  }

  return { handleAssetUploadOperation, subTitle }
}

/**
 * This function takes an `string` as Input and returns the turncated version with ellipses.
 *
 * @param {string} address
 * @param {string} length - "short" | "long"
 * @returns {string} - a formatted string
 *
 * - Reference taken from the {@link https://github.com/DefiLlama/chainlist  ChainList Repository}
 *
 * @example
 *  `Input = `  (0x9CD96215c2Db3821d7345c887b56D5f610d45dC3, "short")
 *  `Output = ` 0x9CD9...5dC3
 *
 *  @isTestWrittenForThisFunction `true`
 */
export function formatLargeStringsAddress(
  address: string,
  length = 'short'
) {
  if (address && length === 'short') {
    address =
      address.substring(0, 6) +
      '...' +
      address.substring(address.length - 4, address.length)
    return address
  } else if (address && length === 'long') {
    address =
      address.substring(0, 12) +
      '...' +
      address.substring(address.length - 8, address.length)
    return address
  } else {
    return null
  }
}

/**
 * @todo - please document this function and its working - mayur-edx
 *
 * @param email
 * @returns
 */
export const handleEmail = (email: string) => {
  if (email) {
    let name = email.split('@')
    return name[0]
  }
  return 'No name'
}

/**
 * @todo - please document this function and its working - mayur-edx
 *
 * @param data
 * @returns
 */
export const handleDate = (data: any) => {
  if (data) {
    let newDate = new Date(data.value)
    let date =
      Number(newDate.getDate()) > 9
        ? `${newDate.getDate()}`
        : `0${newDate.getDate()}`
    let month =
      Number(newDate.getMonth() + 1) > 9
        ? `${newDate.getMonth() + 1}`
        : `0${newDate.getMonth() + 1}`
    let year = `${newDate.getFullYear()}`
    return date + '/' + month + '/' + year
  }
}

/**
 * @todo - please document this function and its working - mayur-edx
 *
 * @param url
 * @returns
 */
// custom NFT file name take in the url
export const handleUrl = (url: string) => {
  if (url) {
    let array = url?.split('/')
    return array[array.length - 1]
  }
}

/**
 * @remarks We are using this function for the site transalation
 *  - we pass this function 2 values
 *    - first one is the actual transalation fetched from backend
 *    - and the second one is the fallback static value `[english]`
 *
 * If `transalation value` is not available then this function will send the `fallbackValue` and our `DOM`
 * will render the fallback.
 *
 * @param {string} keyValue
 * @param {string} fallbackValue
 * @returns {string} either the keyvalue or the fallbackValue
 *
 * @isTestWrittenForThisFunction `true`
 */
export const handleTheLanguageTranslation = (
  keyValue: string,
  fallbackValue: string
) => {
  if (keyValue) {
    return keyValue
  }
  return fallbackValue
}

export {
  capitalizeFirstLetter,
  getCookie,
  handleLogout,
  numberFormatter,
  returnFormattedBigFileNames,
  toastError,
  toastSuccess
}
// eslint-disable-next-line
