import { PAYMENT_GATEWAY } from "actions/enum/payment-type";
import { makeFullS3ObjectUrl } from "assets/images";
import { PaymentOptionListItem } from "components/payment/ChoosePaymentOptions";
import { StripePaymentCardUI } from "components/stripe/StripePaymentCardUI";
import WithStripeProvider from "components/stripe/WithStripeProvider";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Modal, Spinner } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import "styles/components/PaymentModal.scss";
import { handleTheLanguageTranslation } from "utils/functions/commonFunctions";
import useWalletAddCreditsPaymentModel from "./useWalletAddCreditsPaymentModel";
const PaypalLogo = makeFullS3ObjectUrl("paypal-logo.svg");
const crossImg = makeFullS3ObjectUrl("search_cross.png");

interface IWalletCreditsAddPaymentProps {
  handleModal: boolean;
  toggleModal: () => void;
  amount: number;
}

const WalletAddCreditsPaymentModal = ({ handleModal, toggleModal, amount }: IWalletCreditsAddPaymentProps) => {
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);

  // if user is a investor
  const { isCustomUser } = useSelector((state: IRootReducer) => state.userDetailsReducer.user) || {};
  useEffect(() => {
    if (isCustomUser) {
      setPaymentGateway(PAYMENT_GATEWAY.STRIPE);
    }
  }, [isCustomUser]);

  // payment handling states
  const [paymentGateway, setPaymentGateway] = useState(PAYMENT_GATEWAY.PAYPAL);
  const { tokenLoading, paymentFormik, apiLoadingStatus } = useWalletAddCreditsPaymentModel(amount, paymentGateway);

  // close model after payment is success
  useEffect(() => {
    if (apiLoadingStatus === "fulfilled") {
      toggleModal();
      paymentFormik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiLoadingStatus]);

  return (
    <Modal centered isOpen={handleModal} className="payment-modal c-br-3 text-center">
      {apiLoadingStatus === "pending" || tokenLoading ? (
        <div className="loader">
          <Spinner />
        </div>
      ) : null}
      <p className="h2 fw-bold c-tx-secondary mb-3">Choose payment option</p>
      <Button className="close-icon" onClick={toggleModal}>
        <img src={crossImg} alt="close-icon" />
      </Button>
      <Form className="">
        <div className="payment-options">
          <PaymentOptionListItem
            gatewayLogo={PaypalLogo}
            isDisabled={isCustomUser as boolean}
            paymentGateway={paymentGateway}
            setPaymentGateway={setPaymentGateway}
            gatewayItemName={PAYMENT_GATEWAY.PAYPAL}
          />
          {/* Below code is commented for temporary: As we do have some stripe payment issue, dinesh said to hide this */}
          {/* <PaymentOptionListItem
            gatewayLogo={handleTheLanguageTranslation(label?.credit_debit_card, "Credit / Debit Card")}
            isDisabled={isCustomUser as boolean}
            paymentGateway={paymentGateway}
            setPaymentGateway={setPaymentGateway}
            gatewayItemName={PAYMENT_GATEWAY.STRIPE}
          /> */}
          {paymentGateway === PAYMENT_GATEWAY.STRIPE && <StripePaymentCardUI paymentFormik={paymentFormik} />}
        </div>
        <Button
          disabled={amount <= 0 || apiLoadingStatus === "pending" || tokenLoading}
          className="custom-primary w-100"
          onClick={(e) => {
            paymentFormik.handleSubmit();
          }}>
          {apiLoadingStatus === "pending" || tokenLoading ? <Spinner size={"sm"} /> : `${handleTheLanguageTranslation(label?.add_credits, "Add Credits")} ${amount} USD`}
        </Button>
      </Form>
    </Modal>
  );
};
export default WithStripeProvider(WalletAddCreditsPaymentModal);
