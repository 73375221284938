import { INftListReturn } from "actions/app/get-nft-list";
import GlobalLoadMoreButton from "components/GlobalLoadMoreButton";
import React from "react";
import { Col, Row } from "reactstrap";

export interface IUserProfileNFTsListingPropsType {
  isAPILoading: boolean;
  userNftsList: INftListReturn | undefined;
  loadMoreData: () => void;
  checkIfRequestIsForCurrentUser: Function;
  activeTab: {
    mintedByMe: boolean;
    ownedByMe: boolean;
    overview: boolean;
  };
}

const UserProfileNFTsListingLoadMoreButtons = ({ userNftsList, isAPILoading, loadMoreData, checkIfRequestIsForCurrentUser, activeTab }: IUserProfileNFTsListingPropsType) => {
  return (
    <Row>
      {/* if the user is visiting its own profile then show him minted by me and owned tabs load more */}
      {!checkIfRequestIsForCurrentUser() && (
        <Col className="d-flex justify-content-center align-items-center mt-5" sm={12}>
          {activeTab.mintedByMe && userNftsList?.count.mintedByMeCount! > 0 && (
            <GlobalLoadMoreButton dataCount={userNftsList?.count.mintedByMeCount!} isLoading={isAPILoading} listData={userNftsList?.data.mintedByMe as []} loadMoreData={loadMoreData} />
          )}
          {activeTab.ownedByMe && userNftsList?.count.ownByMeCount! > 0 && (
            <GlobalLoadMoreButton dataCount={userNftsList?.count.ownByMeCount!} isLoading={isAPILoading} listData={userNftsList?.data.ownByMe as []} loadMoreData={loadMoreData} />
          )}
        </Col>
      )}
      {/* if user is not the current profile user and not logged in then show hime global cards layout */}
      {
        <Col className="d-flex justify-content-center align-items-center mt-5" sm={12}>
          {Array.isArray(userNftsList?.data) && userNftsList?.data.length! > 0 && !activeTab.overview && (
            <GlobalLoadMoreButton dataCount={userNftsList?.count.NFTs} isLoading={isAPILoading} listData={userNftsList?.data} loadMoreData={loadMoreData} />
          )}
        </Col>
      }
    </Row>
  );
};

export default React.memo(UserProfileNFTsListingLoadMoreButtons);
