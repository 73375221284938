// @use this slice in react components
// @todo - gives cannot access nftcentralrtqapiservice before initialization

import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";
import { IUserProfile } from "./user-info";

export const getUserInfoApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    getUserInfo: build.query<IUserProfile, void>({
      query: () => ({ url: apiEndPoints.userInfo, method: "get" }),
      providesTags: [{ type: "getUserInfo", id: "getUserInfo" }]
    })
  })
});

export const { useLazyGetUserInfoQuery } = getUserInfoApiSlice;
