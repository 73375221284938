import { PAYMENT_GATEWAY } from "actions/enum/payment-type";
import { handleTheLanguageTranslation } from "utils/functions/commonFunctions";
import * as Yup from "yup";

export const CardDetailsFormYupValidationSchema = (paymentGateway: PAYMENT_GATEWAY, label: any) =>
  paymentGateway === PAYMENT_GATEWAY.STRIPE
    ? Yup.object({
        // cardNumber: Yup.number()
        //   // .min(11, "Invalid card number").max(19, "Invalid card number")
        //   .required("Card number is required"),
        // expiryMonth: Yup.string().required("Expiry month is required"),
        // expiryYear: Yup.string().required("Expiry year is required"),
        // cvv: Yup.string().min(3, "CVV must be 3 char long").max(3, "CVV can be maximum of 3 char").required("CVV is required"),
        nameOnCard: Yup.string().required(handleTheLanguageTranslation(label?.name_required, "Name is required"))
      })
    : "";
