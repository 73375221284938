import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const appMintNft = (params: INftMintRequest): Promise<INftMintReturn> => {
//   return HTTPService.post(apiEndPoints.app.MintNft, params);
// };

const mintNftApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    mintNft: build.mutation<INftMintReturn, INftMintRequest>({
      query: (data) => ({
        url: apiEndPoints.app.MintNft,
        data: data,
        method: "post"
      })
    })
  })
});

export const { useMintNftMutation } = mintNftApiSlice;

export interface INftMintRequest {
  id: string | undefined;
  gateway: number;
  saveTemplate: number;
  // futureOwner: string;
}

// @todo change the type its not a valid type
export interface INftMintReturn {
  status: number;
  message: string;
  count: number;
  data: {
    id: string;
  };
}
