import { FC, useState } from "react";
import { Spinner } from "reactstrap";

interface IImageComponent {
  src?: string;
  name: string;
}
const defaultImg = "https://edexa-portal-beta.s3.ap-south-1.amazonaws.com/users/defaultImg.png";

const ImageComponent: FC<IImageComponent> = ({ src, name }) => {
  const [imgState, setImgState] = useState({
    isLoading: true,
    isError: false
  });
  const handleError = () => {
    setImgState({ ...imgState, isError: true });
  };
  const handleLoad = () => {
    setImgState({ ...imgState, isLoading: false, isError: false });
  };
  return (
    <>
      {imgState.isLoading ? <Spinner style={{ position: "absolute", top: "25px", left: "31px" }} /> : null}
      <img src={imgState?.isError ? defaultImg : src ? src : defaultImg} className="img-fluid" alt={name} onError={handleError} onLoad={handleLoad} />
    </>
  );
};

export default ImageComponent;
