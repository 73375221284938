import { makeFullS3ObjectUrl } from "assets/images";
import { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
const tamplateImage = makeFullS3ObjectUrl("templateImage.png");

const ImageLoad = ({ data, index, handleImage }: { data: string; index: number; handleImage?: Function }) => {
  const [imgState, setImgState] = useState({
    load: true,
    error: false,
    dataImg: ""
  });
  const { load, error, dataImg } = imgState;

  useEffect(() => {
    if (error) {
      if (handleImage) {
        handleImage();
        setImgState({ ...imgState, dataImg: data });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (error) {
      if (dataImg !== data) {
        setImgState({ ...imgState, dataImg: data, error: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {/* <div className={`${load ? "custom-spiner" : ""}`}> */}
      {load && <Spinner />}
      <img
        src={error ? tamplateImage : data}
        className={`${handleImage ? "pdf-wrapper" : "img-fluid"}`}
        alt={`${index}`}
        onLoad={() => setImgState({ ...imgState, load: false })}
        onError={() => setImgState({ ...imgState, error: true, load: false })}
      />
      {/* </div> */}
    </>
  );
};

export default ImageLoad;
