import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const appDeleteCollection = (params: { id: string }) => {
//   return HTTPService.deleteWithParams(apiEndPoints.app.deleteCollection, {
//     params
//   });
// };
const deleteCollectionApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appDeleteCollection: build.mutation<void, { id: string }>({
      query: (params) => ({
        url: apiEndPoints.app.deleteCollection,
        method: "delete",
        params: params
      })
    })
  })
});

export const { useAppDeleteCollectionMutation } = deleteCollectionApiSlice;
