import { TOGGLEHEADER } from "./type";

const initialState = {
  isHeaderOpen: /Mobi/.test(navigator.userAgent) ? false : true
};

export const HeaderReducer = (state = initialState, action: { type: string; payload: boolean }) => {
  const newState = { ...state };
  switch (action.type) {
    case TOGGLEHEADER:
      if (window.innerWidth < 768) {
        newState.isHeaderOpen = action.payload;
      }
      return newState;
    default:
      return newState;
  }
};

export interface IHeaderReducer {
  isHeaderOpen: boolean;
}
