import { authReducer, IAuthReducer } from "./auth/reducer";
import { ConfimationPopupReducer, IConfirmationPopup } from "./confimationPopup/reducer";
import { createNFTReducer, ICreateNFTReducer } from "./createNFT/reducer";
import { favouriteClickReducer, IFavouriteCountReducer } from "./favouriteClick/reducer";
import { GlobalSettingReducer, IGlobalSettingReducer } from "./globalSettings/reducer";
import { HeaderReducer, IHeaderReducer } from "./header/reducer";
import { ILanguagesJson } from "./internationalization/module";
import { LanguagesJsonReducer } from "./internationalization/reducer";
import { ILoaderReducer, loadingReducer } from "./loader/reducer";
import { ILoginRequiredPopupReducer, loginRequiredPopupReducer } from "./loginRequiredPopup/reducer";
import { INormalFileAndCompressedFileReducer, normalFileAndCompressedFileReducer } from "./oldUrlNewUrl/reducer";
import { IRequestDemoMdoalReducer, RequestDemoMdoalReducer } from "./requestDemoModal/reducer";
import { scrollingReducer } from "./scrolling/reducer";
import userDetailsReducer, { IUserDetailsReducer } from "./user/reducer";
import videoPlayerStateReducer, { IVideoPlayerReducer } from "./video/reducer";

const rootReducer = {
  header: HeaderReducer,
  auth: authReducer,
  loading: loadingReducer,
  userDetailsReducer: userDetailsReducer,
  createNFT: createNFTReducer,
  videoPlayerStateReducer: videoPlayerStateReducer,
  confimationModal: ConfimationPopupReducer,
  scrollingReducer: scrollingReducer,
  favouriteClick: favouriteClickReducer,
  loginRequiredPopupReducer: loginRequiredPopupReducer,
  globalSettingReducer: GlobalSettingReducer,
  requestDemoMdoalReducer: RequestDemoMdoalReducer,
  languagesJsonReducer: LanguagesJsonReducer,
  normalFileAndCompressedFileReducer: normalFileAndCompressedFileReducer
};

export default rootReducer;

export interface IRootReducer {
  header: IHeaderReducer;
  auth: IAuthReducer;
  userDetailsReducer: IUserDetailsReducer;
  loading: ILoaderReducer;
  createNFT: ICreateNFTReducer;
  videoPlayerStateReducer: IVideoPlayerReducer;
  confimationModal: IConfirmationPopup;
  // scrollingReducer: IScrollingReducer;
  favouriteClick: IFavouriteCountReducer;
  loginRequiredPopupReducer: ILoginRequiredPopupReducer;
  globalSettingReducer: IGlobalSettingReducer;
  requestDemoMdoalReducer: IRequestDemoMdoalReducer;
  languagesJsonReducer: ILanguagesJson;
  normalFileAndCompressedFileReducer: INormalFileAndCompressedFileReducer;
}
