import { MEDIALOADING, SETCREATE, SETLOADING, SETUPLOAD, TASKINPROGRESS, TASKINPROGRESSALERT } from "./type";

export const setLoading = (payload: boolean) => {
  return { type: SETLOADING, payload };
};

export const setUploadLoading = (payload: boolean) => {
  return { type: SETUPLOAD, payload };
};

export const setCreateLoading = (payload: boolean) => {
  return { type: SETCREATE, payload };
};

export const setMediaLoading = (payload: boolean) => {
  return { type: MEDIALOADING, payload };
};

export const setTaskInProgress = (payload: boolean) => {
  return { type: TASKINPROGRESS, payload };
};

export const setTaskInProgressAlert = (payload: boolean) => {
  return { type: TASKINPROGRESSALERT, payload };
};
