import { UNAUTHORIZEDUSERCONTENTACCESS } from "./type";

const initialState: ILoginRequiredPopupReducer = {
  unAuthorized: false
};

export const loginRequiredPopupReducer = (state = initialState, action: { type: string; payload: boolean }) => {
  const newState = { ...state };
  switch (action.type) {
    case UNAUTHORIZEDUSERCONTENTACCESS:
      newState.unAuthorized = action.payload;
      break;
    default:
      return newState;
  }
  return newState;
};

export interface ILoginRequiredPopupReducer {
  unAuthorized: boolean;
}
