import ProtectedRouteLayout from "core/layout/ProtectedRouteLayout";
import PublicRouteLayout from "core/layout/PublicRouteLayout";
import { AddNftsToCollectionPage } from "pages/AddNftsToCollectionPage";
import { CollectionListPage } from "pages/CollectionListPage";
import CollectionPreviewPage from "pages/collectionPreview/CollectionPreviewPage";
import { CustomNFT } from "pages/customNFT/CustomNft";
import { Dashboard } from "pages/Dashboard";
import EditUserProfilePage from "pages/editUserProfile/EditUserProfilePage";
import { FavouriteCollectionPage } from "pages/FavouriteCollectionPage";
import InvoicePage from "pages/InvoicePage";
import { MintNftPage } from "pages/mintNFT/MintNFTPage";
import MintNFTPaymentStatusPage from "pages/MintNFTPaymentStatusPage";
import MyCreditsPage from "pages/MyCreditsPage";
import { DraftCollectionsPage } from "pages/NftDraftsListingPage";
import NftIntegrationPage from "pages/nftIntegration/NftIntegrationPage";
import { TopUsersPage } from "pages/TopUsersPage";
import UnderMaintenancePage from "pages/UnderMaintenancePage";
import { Navigate, Outlet, RouteObject } from "react-router-dom";
import Page404 from "../../pages/404Page";
import { Login } from "../../pages/auth/Login";
import { Register } from "../../pages/auth/Register";
import CollectiondetailPage from "../../pages/collectionDetail/CollectionDetailPage";
import { CreateNFT } from "../../pages/createNFT/CreateNFTPage";
import { ArchiveTemplates } from "../../pages/customNFT/ArchiveTemplates";
import { CreatCustomNFT } from "../../pages/customNFT/CreateCustomNFTPage";
import { MintCustomNftPage } from "../../pages/customNFT/MintCustomNftPage";
import { ExploreCollectionsPage } from "../../pages/exploreCollection/ExploreCollectionsPage";
import { ExploreNftsPage } from "../../pages/ExploreNftsPage";
import { NftDetailPage } from "../../pages/NftDetailPage";
import UserProfilePage from "../../pages/UserProfilePage";

export const PrivateRoutesV6: RouteObject[] = [
  {
    path: "/",
    element: <ProtectedRouteLayout />,
    children: [
      { path: "/", element: <Dashboard /> },
      { path: "/integration", element: <NftIntegrationPage /> },
      { path: "/404", element: <Page404 /> },
      { path: "/invoice", element: <InvoicePage /> },
      { path: "/my-credits", element: <MyCreditsPage /> },
      { path: "payment", element: <MintNFTPaymentStatusPage /> },
      {
        path: "/account",
        element: <Outlet />,
        children: [
          { index: true, element: <Page404 /> },
          {
            path: "@:userId",
            element: <Outlet />,
            children: [
              { index: true, element: <UserProfilePage /> },
              { path: "edit", element: <EditUserProfilePage /> }
            ]
          },
          {
            path: "draft-collection",
            element: <DraftCollectionsPage />
          },
          {
            path: "favourite-collection",
            element: <FavouriteCollectionPage />
          },
          { path: "*", element: <Page404 /> }
        ]
      },
      {
        path: "/assets",
        element: <Outlet />,
        children: [
          { index: true, element: <Page404 /> },
          {
            path: "/assets/:nftId",
            element: <NftDetailPage />
          },
          { path: "*", element: <Page404 /> }
        ]
      },
      {
        path: "/create-nft",
        element: <Outlet />,
        children: [
          { index: true, element: <CreateNFT /> },
          { path: ":id", element: <CreateNFT /> },
          { path: "*", element: <Page404 /> }
        ]
      },
      {
        path: "/create-mint",
        element: <Outlet />,
        children: [
          { index: true, element: <Page404 /> },
          { path: ":id", element: <MintNftPage /> },
          { path: "*", element: <Page404 /> }
        ]
      },
      {
        path: "/all-nfts",
        element: <ExploreNftsPage />
      },
      {
        path: "/custom-mint",
        element: <Outlet />,
        children: [
          { index: true, element: <Page404 /> },
          { path: ":id", element: <MintCustomNftPage /> },
          { path: "*", element: <Page404 /> }
        ]
      },
      {
        path: "/top-users",
        element: <TopUsersPage />
      },
      {
        path: "/explore",
        element: <TopUsersPage />
      },
      {
        path: "/explore-collections",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <ExploreCollectionsPage isPublicCollection={false} />
          },
          {
            path: ":collectionUrl",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <CollectiondetailPage isPublicCollection={false} />
              },
              {
                path: "add-nft",
                element: <AddNftsToCollectionPage />
              }
            ]
          }
        ]
      },
      {
        path: "/all-collections",
        element: <Outlet />,
        children: [
          { index: true, element: <CollectionListPage /> },
          {
            path: ":collectionUrl",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <CollectiondetailPage isPublicCollection={true} />
              }
            ]
          }
        ]
      },

      {
        path: "/collection",
        element: <Outlet />,
        children: [
          { index: true, element: <Page404 /> },
          {
            path: ":collectionUrl",
            element: <CollectionPreviewPage />
          }
        ]
      },
      {
        path: "/create-custom-nft",
        element: <Outlet />,
        children: [
          { index: true, element: <CreatCustomNFT /> },
          { path: ":templateID", element: <CreatCustomNFT /> }
        ]
      },
      {
        path: "/custom-nft",
        element: <CustomNFT />
      },
      {
        path: "/draft-nft",
        element: <Outlet />,
        children: [
          { index: true, element: <Page404 /> },
          { path: ":templateID", element: <CreatCustomNFT /> },
          { path: "*", element: <Page404 /> }
        ]
      },
      {
        path: "/archive-templates",
        element: <ArchiveTemplates />
      },
      { path: "/under-maintenance", element: <UnderMaintenancePage /> },
      { path: "*", element: <Page404 /> }
    ]
  }
];

export const PublicRoutesV6: RouteObject[] = [
  {
    path: "/",
    element: <PublicRouteLayout />,
    children: [
      { index: true, element: <Dashboard /> },
      {
        path: "/all-nfts",
        element: <ExploreNftsPage />
      },
      {
        path: "/top-users",
        element: <TopUsersPage />
      },
      {
        path: "/explore",
        element: <TopUsersPage />
      },
      { path: "/404", element: <Page404 /> },
      { path: "/login", element: <Login /> },
      { path: "/signup", element: <Register /> },
      { path: "/under-maintenance", element: <UnderMaintenancePage /> },
      {
        path: "/account",
        element: <Outlet />,
        children: [
          { index: true, element: <Page404 /> },
          {
            path: "@:userId",
            element: <UserProfilePage />
          }
        ]
      },
      {
        path: "/assets",
        element: <NftDetailPage />,
        children: [
          { index: true, element: <Page404 /> },
          {
            path: ":nftId",
            element: <NftDetailPage />
          }
        ]
      },
      {
        path: "/all-collections",
        element: <Outlet />,
        children: [
          { index: true, element: <CollectionListPage /> },
          {
            path: ":collectionUrl",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <CollectiondetailPage isPublicCollection={true} />
              }
            ]
          }
        ]
      },
      {
        path: "/collection",
        element: <Outlet />,
        children: [
          { index: true, element: <Page404 /> },
          {
            path: ":collectionUrl",
            element: <CollectionPreviewPage />
          }
        ]
      },
      { path: "*", element: <Page404 /> }
    ]
  }
];

export const RequestDemoRoutesV6: RouteObject[] = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: "/login", element: <Login /> },
      { path: "/signup", element: <Register /> },

      {
        path: "*",
        element: <Navigate to="/" replace state={{ requestDemo: true }} />
      }
    ]
  }
];

export const UnderConstructionsRoutesV6: RouteObject[] = [
  { path: "/under-maintenance", element: <UnderMaintenancePage /> },
  {
    path: "*",
    element: <Navigate to="/under-maintenance" replace />
  }
];
