import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const getDraftListAPI = (params: IDraftNftsRequest): Promise<IDraftListReturn> => {
//   return HTTPService.get(apiEndPoints.app.GetDraft, { params });
// };

const draftApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    getDraftListAPI: build.query<IDraftListReturn, IDraftNftsRequest>({
      query: (params) => ({
        url: apiEndPoints.app.GetDraft,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "getCreditCardListAPI", id: "getDraftListAPI" }]
    })
  })
});

export const { useGetDraftListAPIQuery, useLazyGetDraftListAPIQuery } = draftApiSlice;
export interface IDraftNftsRequest {
  page: number;
  limit: number;
  search: string;
}

interface ICompressionProgressValues {
  status: number;
  key: string;
}

/**
 * This interface is used to type the values of InProcess object
 * This will be used to track if an resource is in compression process or done processing
 * Also we are receiving the socket id for that particular resource to listen to progress change
 */
export interface IDraftCompressionProgress {
  compressedFile: ICompressionProgressValues;
  originalFile: ICompressionProgressValues;
  thumbFile: ICompressionProgressValues;
}

export interface IDraftList {
  inProcess: IDraftCompressionProgress;
  fileThumbPath: string;
  fileMimeType: string;
  createdAt: string;
  title: string;
  description: string;
  price: number;
  visibility: number;
  tags: string[];
  _id: string;
  createdBy: string;
  compressedFilePath: string;
  isCustom: number;
  filePath: string;
  categoryId: string;
  fileType: string;
  id: string;
}

export interface IDraftListReturn {
  status: number;
  message: string;
  count: number;
  data: IDraftList[];
}
