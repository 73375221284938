import { VIDEOPAUSED, VIDEOPLAYING } from "./type";

const initialState: IVideoPlayerReducer = {
  videoId: ""
};

const videoPlayerStateReducer = (
  state = initialState,
  action: {
    type: string;
    payload: string;
  }
) => {
  const newState = {
    ...state
  };
  switch (action.type) {
    case VIDEOPLAYING:
      newState.videoId = action.payload;
      break;
    case VIDEOPAUSED:
      newState.videoId = "";
      break;
  }
  return newState;
};

export default videoPlayerStateReducer;

export interface IVideoPlayerReducer {
  videoId: string;
}
