import { MintGateways } from "actions/enum/gateway-types";
import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

const paymentApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    createNewPayment: build.mutation<IAddNewPaymentResult, IAddNewPayment>({
      query: (data) => ({
        url: apiEndPoints.createPayment,
        data: data,
        method: "post"
      })
    })
  })
});

export const { useCreateNewPaymentMutation } = paymentApiSlice;

export interface IAddNewPayment {
  nftId: string;
  token?: string;
  gateway: MintGateways;
  planId: string;
  purchaseFrom: string;
  purchasePlanType: string;
  saveTemplate: number;
  //   price: number;
  //   nft: number;
  //   couponId: string;
}

export interface IAddNewPaymentResult {
  message: string;
  status: number;
  data: string;
}
