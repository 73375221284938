import { useLazyAppSetFavouriteOrUnfavouriteNFTQuery } from "actions/app/favourite-nft";
import { useEffect, useState } from "react";

const useFavouriteUnFavoruite = (nftId = "", isFavourite = false): [favourite: boolean, toggleFavourite: Function] => {
  const [makeAPIReadyForCall, setMakeAPIReadyForCall] = useState(false);
  const [setFavouriteOrUnfavouriteNFTQuery] = useLazyAppSetFavouriteOrUnfavouriteNFTQuery();

  const [favourite, setFavourite] = useState(false);

  const toggleFavourite = () => {
    setFavourite(!favourite);
    setMakeAPIReadyForCall(true);
  };

  // when favourite state change
  useEffect(() => {
    if (nftId && makeAPIReadyForCall) {
      setFavouriteOrUnfavouriteNFTQuery({ id: nftId }).then((response) => {
        // favourite === true ? toastSuccess("Added to favourite collection") : toastSuccess("Removed from favourite");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favourite]);
  useEffect(() => {
    setFavourite(isFavourite || false);
  }, [isFavourite]);

  return [favourite, toggleFavourite];
};

export default useFavouriteUnFavoruite;
