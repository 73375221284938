import { INTERNATIONALLANGUAGESENUM } from "store/internationalization/type";
import { apiEndPoints } from "utils/constants";
import HTTPService from "../../utils/httpService";

export const getUserInfo = (): Promise<IUserProfile> => {
  return HTTPService.get(apiEndPoints.userInfo);
};

// @use this slice in react components
// @todo - gives cannot access nftcentralrtqapiservice before initialization
// export const getUserInfoApiSlice = nftCentralRTQApiService.injectEndpoints({
//   endpoints: (build) => ({
//     getUserInfo: build.mutation<IUserProfile, void>({
//       query: () => ({ url: apiEndPoints.userInfo, method: "get" })
//     })
//   })
// });

// export const { useGetUserInfoMutation } = getUserInfoApiSlice;

export interface IUser {
  // appleId: string;
  // countryCode: string;
  // createdAt: string;
  cropProfilePicture?: string;
  language: INTERNATIONALLANGUAGESENUM;
  // dob: string;
  email: string;
  name: string;
  profilePicture?: string;
  isAccess: number;
  // first_name: string;
  // gender: string;
  _id: string;
  username: string;
  isVerified?: number;
  walletAddress: string;
  userWallet: number;
  /**
   * If the user is custom user that means he is invester and he will bypass all of the validations and mint nft.
   * Even he can make payment without any charge, backend will handle it.
   */
  isCustomUser?: boolean;
  // isEmailVerified: number;
  // kycApproved: number;
  // kycRejectReason: string;
  // last_name: string;
  // loginType: string;
  // notification: 1;
  // orgInfo: {
  //   countryCode: string;
  //   createdAt: string;
  //   id: string;
  //   logo: string;
  //   name: string;
  //   owner: string;
  //   thumbsLogo: string;
  //   timezone: string;
  //   uid: string;
  //   updatedAt: string;
  // };
  // organization: string;
  // owner_org: boolean;
  // phone: string;
  // profileName: string;
  // profilePic: string;
  // resendEmailTimeExpire: string;
  // secondaryEmail: string;
  // status: string;
  // thumbProfile: string;
  // twoAuth: boolean;
  // twoAuthMaintenance: string;
  // updatedAt: string;
  userId: string;
}
export interface IUserProfile {
  data: IUser;
  message: string;
  status: 200;
}
