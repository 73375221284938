import { useEffect } from "react";

/**
 * This hook will add `light-theme` class to the DOM `body` if themetype is `light` otherwise the dark theme will be used.
 *
 * @param {THEMETYPES} theme theme name dark | light
 * @param {any[]} dependencies  the array of the useeffect dependencies.
 * @returns {null} null
 *
 * @todo - add dark theme support and start using validations of theme.
 */
const useTheme = (theme: THEMETYPES, dependencies: any[]) => {
  useEffect(() => {
    // @todo - apply below condition when dhaval fix the theme switching functionality
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    theme !== THEMETYPES.DARK && document.body.classList.add("light-theme");
    return () => document.body.classList.remove("light-theme");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);
  return null;
};

export default useTheme;

export enum THEMETYPES {
  LIGHT = "light",
  DARK = "dark"
}
