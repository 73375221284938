import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import {
  faGlobe,
  faImage,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IUserProfileOverview } from 'actions/app/get-user-profile'
import { useAppUpdateUserProfileMutation } from 'actions/app/update-user-profile'
import { CloseButtonSvg } from 'assets/images/close-button'
import { useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Spinner,
  Tooltip
} from 'reactstrap'
import { UpdateUserProfileYupValidationSchema } from 'schema/UpdateUserProfileDetailsSchema'
import { IRootReducer } from 'store/root-reducer'
import 'styles/components/createcollectionmodal.scss'
import {
  getBase64,
  handleTheLanguageTranslation
} from 'utils/functions/commonFunctions'
import ConnectWalletButton from './ConnectWalletButton'

interface CreateCollectionModalPropTypes {
  toggleUpdateUserProfileModal: Function
  refreshCollectionListing: Function
  UpdateUserProfileModalIsOpen: boolean
  userDetails?: IUserProfileOverview
}

const EditUserProfileDetailsmodal = ({
  toggleUpdateUserProfileModal,
  UpdateUserProfileModalIsOpen,
  refreshCollectionListing,
  userDetails
}: CreateCollectionModalPropTypes) => {
  const [isLoading, setIsLoading] = useState(false)
  const [bannertTooltip, setBannertTooltip] = useState<boolean>(false)
  const [walletAddressTooltip, setWalletAddressTooltip] =
    useState(false)
  const [updateUserProfileMutation] =
    useAppUpdateUserProfileMutation()

  const [walletAddress, setWalletAddress] = useState<string>('')

  // handle the form values
  const userProfileUpdateFormik = useFormik({
    initialValues: {
      banner: null,
      walletAddress: '',
      bio: '',
      twitter: '',
      facebook: '',
      instagram: '',
      youtube: '',
      website: ''
    },
    validationSchema: UpdateUserProfileYupValidationSchema,
    onSubmit: (values) => {
      const profileData = new FormData()
      Object.entries(values).forEach((item) => {
        // if wallet address is not changed then do not update it
        // or if value is null then do not add it to request
        if (
          (item[1] === userDetails?.data.walletAddress ||
            item[1] === null) &&
          item[1] !== 'website'
        )
          return
        // if bio is not updated then do not add it to request
        if (item[1] === userDetails?.data.bio) return
        // if links are not changed then do not add them to request
        if (
          Object.keys(userDetails?.data.links || {}).includes(item[0])
        ) {
          // I know what I'm doing by using @ts-ignore
          // @ts-ignore
          if (userDetails?.data.links[item[0]] === item[1])
            return null
        }

        profileData.append(item[0], item[1] as string | Blob)
      })

      // if all the values are same as earlier then disable the update again.
      // because formData/request body is empty
      if (profileData.entries().next().done) {
        setFormUpdated(false)
        return
      }

      setIsLoading(true)
      updateUserProfileMutation(profileData)
        .then((response) => {
          setIsLoading(false)
          toggleUpdateUserProfileModal()
          refreshCollectionListing()
        })
        .catch((err) => {
          setIsLoading(false)
        })
    }
  })
  const { values, setFieldValue, errors, touched, handleSubmit } =
    userProfileUpdateFormik
  const [formUpdated, setFormUpdated] = useState(false)

  // inputtype refrences
  const InputRefs = useRef<{
    [key: string]: HTMLInputElement | null
  }>({
    bannerImageRef: null
  })

  // set images in base64 format
  const [imagePreviews, setImagePreviews] = useState({
    banner: ''
  })
  useEffect(() => {
    if ((values.banner as unknown as File)?.type) {
      // @ts-ignore
      getBase64(values.banner).then((res) => {
        setImagePreviews({ ...imagePreviews, banner: res as string })
      })
    }
    if (walletAddress !== null) {
      setFieldValue('walletAddress', walletAddress)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.banner, walletAddress])

  // social Links refs
  const socialRef = useRef<{
    [key: string]: HTMLInputElement | null
  }>({
    facebook: null,
    youtube: null,
    instagram: null,
    twitter: null,
    website: null,
    url: null
  })
  const executeClickHandler = (name: string) => {
    if (Object.keys(socialRef.current).includes(name)) {
      socialRef.current[name] && socialRef.current?.[name]?.focus()
    }
  }

  useEffect(() => {
    if (userDetails) {
      userDetails.data.bannerImage &&
        setImagePreviews({ banner: userDetails.data.bannerImage })
      userDetails.data.links &&
        Object.entries(userDetails.data.links).forEach((item) => {
          setFieldValue(item[0], item[1])
        })
      userDetails.data.walletAddress &&
        setFieldValue('walletAddress', userDetails.data.walletAddress)
      userDetails.data.bio &&
        setFieldValue('bio', userDetails.data.bio)
    }
  }, [setFieldValue, userDetails, userDetails?.data.bannerImage])

  // website url error tooltip
  const [isWebErrorTooltip, setIsWebErrorTooltip] = useState(false)

  const label = useSelector(
    (state: IRootReducer) =>
      state.languagesJsonReducer.currentLanguage.json
  )

  const handleErrorLabelKey = (str: string) => {
    if (str) {
      if (str === 'User bio should be at least 3 character long') {
        return handleTheLanguageTranslation(
          label?.user_bio_should_be_at_least_3_character_long,
          str
        )
      } else if (str === 'User bio can only be 250 character long') {
        return handleTheLanguageTranslation(
          label?.user_bio_can_only_be_250_character_long,
          str
        )
      } else if (str === 'User bio is required') {
        return handleTheLanguageTranslation(
          label?.user_bio_is_required,
          str
        )
      } else if (str === 'Wallet address is required') {
        return handleTheLanguageTranslation(
          label?.wallet_address_is_required,
          str
        )
      }
      return str
    }
  }
  return (
    <Modal
      isOpen={UpdateUserProfileModalIsOpen}
      centered
      toggle={() => toggleUpdateUserProfileModal()}
      backdrop="static"
      scrollable={true}
      className="create-collection-modal modal-features"
    >
      <ModalBody className="">
        <button
          className="close-btn background-transparent border-none"
          onClick={() => toggleUpdateUserProfileModal()}
        >
          {CloseButtonSvg}
        </button>
        <div className="text-center">
          <h2 className="c-tx-secondary f-700 title-lr mb-0">
            {handleTheLanguageTranslation(
              label?.edit_user_profile,
              'Edit user profile'
            )}{' '}
          </h2>
        </div>
        <div className="c-mt-40">
          <Form
            className="create-min-form"
            autoComplete="off"
            onChange={(e) => {
              setFormUpdated(true)
            }}
          >
            {/* banner */}
            <FormGroup className="position-relative form-input-mb ">
              <Label
                className="text-start d-flex align-items-center"
                style={{ width: '15%' }}
              >
                {handleTheLanguageTranslation(
                  label?.banner_image,
                  'Banner image'
                )}
                <button
                  id="bannert"
                  className="background-transparent border-none"
                >
                  <FontAwesomeIcon
                    className="info-tooltip"
                    icon={faInfoCircle}
                  />
                </button>
                <Tooltip
                  placement="right"
                  isOpen={bannertTooltip}
                  target="bannert"
                  toggle={() => setBannertTooltip(!bannertTooltip)}
                  className="common-form-tip custom-tooltip-style"
                >
                  <p className="c-tx-forth mb-0">
                    This image will appear at the top of your
                    collection page. Avoid including too much text in
                    this banner image, as the dimensions change on
                    different devices.
                  </p>
                </Tooltip>
              </Label>
              <div
                className={`common-image-bl ${
                  Boolean(errors.banner && touched.banner) &&
                  'is-invalid border-error-dotted '
                }`}
                onClick={() => InputRefs.current.banner?.click()}
              >
                {!imagePreviews.banner ? (
                  <div className="before-image">
                    <FontAwesomeIcon icon={faImage} />
                    <div className="text-note">
                      <p className=" c-tx-forth mb-0">
                        {handleTheLanguageTranslation(
                          label?.recommended_size,
                          'Recommended size'
                        )}{' '}
                        1920 x 400
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="after-image cursor-pointer">
                    <img
                      src={imagePreviews.banner}
                      alt="banner"
                      className="img-fluid"
                    />
                  </div>
                )}
              </div>
              <input
                ref={(e) => (InputRefs.current.banner = e)}
                type="file"
                onChange={(e) => {
                  if (e.target.files?.length! > 0) {
                    setFieldValue(e.target.name, e.target.files?.[0])
                  }
                }}
                style={{ display: 'none' }}
                name="banner"
                accept="image/*"
              />
              <FormFeedback>{errors.banner}</FormFeedback>
            </FormGroup>
            {/* wallet address */}
            <FormGroup
              className="position-relative form-input-mb position-relative"
              style={{
                paddingBottom:
                  touched.walletAddress && errors.walletAddress
                    ? 30
                    : ''
              }}
            >
              <Label
                className="text-start d-flex align-items-center"
                style={{ width: '15%' }}
              >
                {handleTheLanguageTranslation(
                  label?.wallet_address,
                  'Wallet Address'
                )}

                <button
                  id="walletAddresst"
                  className="background-transparent border-none cursor-pointer"
                >
                  <FontAwesomeIcon
                    className="info-tooltip"
                    icon={faInfoCircle}
                  />
                </button>
              </Label>
              <ConnectWalletButton
                setWalletAddress={setWalletAddress}
                setFormUpdated={setFormUpdated}
              />
            </FormGroup>
            <Tooltip
              placement="right"
              isOpen={walletAddressTooltip}
              target="walletAddresst"
              toggle={() =>
                setWalletAddressTooltip(!walletAddressTooltip)
              }
              className="common-form-tip custom-tooltip-style"
            >
              <p className="c-tx-forth mb-0">
                Change your Metamask wallet easily! Open Metamask,
                connect your wallet, and click on the 'Update' button.
                Your latest wallet will be automatically fetched and
                updated.
              </p>
            </Tooltip>
            {/* user bio */}
            <FormGroup
              className={`position-relative form-input-mb ${
                Boolean(touched.bio && errors.bio) && 'mb-40px'
              }`}
            >
              <Input
                name="bio"
                placeholder={handleTheLanguageTranslation(
                  label?.bio,
                  'Bio'
                )}
                type="textarea"
                onChange={(e) => {
                  // do not store only spaces
                  if (e.target.value !== ' ' || values.bio) {
                    setFieldValue(e.target.name, e.target.value)
                  }
                }}
                value={values.bio}
                maxLength={250}
                invalid={Boolean(touched.bio && errors.bio)}
              />
              <FormFeedback className="position-absolute">
                {errors.bio && handleErrorLabelKey(errors.bio)}
              </FormFeedback>
              <div className="character-counter text-area">
                <p className="c-tx-forth mb-0">
                  {values.bio.length}/250
                </p>
              </div>
            </FormGroup>

            {/* social links */}
            <div className="social-bt-wrap">
              <Label className="" style={{ width: '15%' }}>
                {handleTheLanguageTranslation(
                  label?.social_links,
                  'Social links'
                )}
              </Label>
              <div className="two-input">
                <FormGroup
                  className="position-relative form-input-mb "
                  onClick={() => executeClickHandler('youtube')}
                >
                  <div className="social-input">
                    <div className="social-icon">
                      {' '}
                      <FontAwesomeIcon icon={faYoutube} />
                    </div>
                    <div className="input-wrapper-bl">
                      <div className="cy-bl">
                        https://www.youtube.com/
                      </div>
                      <input
                        name="youtube"
                        ref={(e) => (socialRef.current.youtube = e)}
                        className="form-control"
                        placeholder={handleTheLanguageTranslation(
                          label?.youtube_video_path,
                          'Your youtube link'
                        )}
                        type="text"
                        onChange={(e) => {
                          // store only char no spaces
                          setFieldValue(
                            e.target.name,
                            e.target.value.trim()
                          )
                        }}
                        value={values.youtube}
                      />
                    </div>
                  </div>
                </FormGroup>
                <FormGroup
                  className="position-relative form-input-mb "
                  onClick={() => executeClickHandler('facebook')}
                >
                  <div className="social-input">
                    <div className="social-icon">
                      {' '}
                      <FontAwesomeIcon icon={faFacebookF} />
                    </div>
                    <div className="input-wrapper-bl">
                      <div className="cy-bl">
                        https://www.facebook.com/
                      </div>
                      <input
                        name="facebook"
                        ref={(e) => (socialRef.current.facebook = e)}
                        className="form-control"
                        placeholder="@yourFacebookAcc"
                        type="text"
                        onChange={(e) => {
                          // store only char no spaces
                          setFieldValue(
                            e.target.name,
                            e.target.value.trim()
                          )
                        }}
                        value={values.facebook}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
              <div className="two-input">
                <FormGroup
                  className="position-relative form-input-mb "
                  onClick={() => executeClickHandler('instagram')}
                >
                  <div className="social-input">
                    <div className="social-icon">
                      {' '}
                      <FontAwesomeIcon icon={faInstagram} />
                    </div>
                    <div className="input-wrapper-bl">
                      <div className="cy-bl">
                        https://www.instagram.com/
                      </div>
                      <input
                        name="instagram"
                        ref={(e) => (socialRef.current.instagram = e)}
                        className="form-control"
                        placeholder="@yourinstagramhandle"
                        type="text"
                        onChange={(e) => {
                          // store only char no spaces
                          setFieldValue(
                            e.target.name,
                            e.target.value.trim()
                          )
                        }}
                        value={values.instagram}
                      />
                    </div>
                  </div>
                </FormGroup>
                <FormGroup
                  className="position-relative form-input-mb "
                  onClick={() => executeClickHandler('twitter')}
                >
                  <div className="social-input">
                    <div className="social-icon">
                      {' '}
                      <FontAwesomeIcon icon={faTwitter} />
                    </div>
                    <div className="input-wrapper-bl">
                      <div className="cy-bl">
                        https://www.twitter.com/
                      </div>
                      <input
                        name="twitter"
                        ref={(e) => (socialRef.current.twitter = e)}
                        className="form-control"
                        placeholder="@yourTwitterHandle"
                        type="text"
                        onChange={(e) => {
                          // store only char no spaces
                          setFieldValue(
                            e.target.name,
                            e.target.value.trim()
                          )
                        }}
                        value={values.twitter}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
              <div className="two-input">
                <FormGroup
                  className="position-relative form-input-mb "
                  onClick={() => executeClickHandler('website')}
                >
                  <div
                    className={`social-input ${
                      Boolean(errors.website && touched.website) &&
                      'is-invalid border-error'
                    }`}
                  >
                    <div className="social-icon">
                      {' '}
                      <FontAwesomeIcon icon={faGlobe} />
                    </div>
                    <div className="input-wrapper-bl">
                      <div className="cy-bl"></div>
                      <input
                        ref={(e) => (socialRef.current.website = e)}
                        className="form-control"
                        name="website"
                        placeholder="Your website link"
                        type="text"
                        onChange={(e) => {
                          // store only char no spaces
                          setFieldValue(
                            e.target.name,
                            e.target.value.trim()
                          )
                        }}
                        value={values.website}
                      />
                    </div>
                  </div>
                  <FormFeedback>
                    {errors.website} &nbsp;
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      id="website-hint"
                    />
                  </FormFeedback>
                  <Tooltip
                    placement="auto"
                    isOpen={isWebErrorTooltip}
                    target="website-hint"
                    toggle={() =>
                      setIsWebErrorTooltip(!isWebErrorTooltip)
                    }
                    className="custom-tooltip-style common-form-tip"
                  >
                    <p className="c-tx-forth mb-0">
                      ex. https://example.com
                    </p>
                  </Tooltip>
                </FormGroup>
              </div>
              <div className="d-flex  modal-btn-group justify-content-end">
                <Button
                  className="custom-primary"
                  onClick={() => handleSubmit()}
                  disabled={isLoading || !formUpdated}
                >
                  {isLoading ? (
                    <Spinner size={'sm'} className="text-white" />
                  ) : (
                    handleTheLanguageTranslation(
                      label?.update,
                      'Update'
                    )
                  )}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default React.memo(EditUserProfileDetailsmodal)
