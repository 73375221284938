import { INotableNftData, ISingleNftDataFromLandingPage, useLazyAppGetLandingPageDataQuery } from "actions/app/get-landing-page-data";
import { ITopUsers, useLazyAppGetTopUsersQuery } from "actions/app/get-top-users";
import GlobalHelmetProvider from "components/GlobalHelmetProvider";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import { setRequestDemoModal } from "store/requestDemoModal/action";
import { IRootReducer } from "store/root-reducer";
import "styles/pages/home.scss";
import { paginationKeysObject } from "utils/constants/constants";
import { handleTheLanguageTranslation } from "utils/functions/commonFunctions";
import { Bannersection, CreateAndSellNft, DigitalAssets, EdexaMediumLatestArticles, NotableNftSection, TopUserSection } from "./";

export interface ILandingPageContextData {
  notableNfts: INotableNftData[] | undefined;
  featuredNft: ISingleNftDataFromLandingPage | undefined;
  topUsers: ITopUsers | undefined;
}
const LandingPageContext = React.createContext<ILandingPageContextData | undefined>(undefined);
const LandingPageProvider = LandingPageContext.Provider;

export default LandingPageContext;

export const Dashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation() || {};
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  const state = (location.state as { requestDemo: boolean }) || {};
  const { requestDemo } = state;

  const [getTopUserQuery] = useLazyAppGetTopUsersQuery();
  const [getLandingPageDataQuery] = useLazyAppGetLandingPageDataQuery();
  const [topUser, setTopUser] = useState<ITopUsers>();
  const [notableNfts, setNotableNfts] = useState<INotableNftData[]>();
  const [featuredNft, setFeaturedNft] = useState<ISingleNftDataFromLandingPage>();

  useEffect(() => {
    if (requestDemo) {
      dispatch(setRequestDemoModal(true));
    }
  }, [dispatch, requestDemo]);

  // Fetch the landing page data
  // @start
  const fetchLandingPageData = {
    getTopUsers: () => {
      getTopUserQuery({ ...paginationKeysObject, limit: 5 }).then((response) => {
        setTopUser(response.data);
      });
    },
    getLandingPageData: () => {
      getLandingPageDataQuery().then((response) => {
        if ("data" in response && response.data && response.data.status === 200) {
          setNotableNfts(response.data.notableNftData);
          setFeaturedNft(response.data.nftData);
        }
      });
    }
  };
  useEffect(() => {
    fetchLandingPageData.getTopUsers();
    fetchLandingPageData.getLandingPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LandingPageProvider
      value={{
        notableNfts: notableNfts,
        featuredNft: featuredNft,
        topUsers: topUser
      }}>
      <GlobalHelmetProvider title={handleTheLanguageTranslation(label?.home, "Home")} />
      <div className="home-wrapper">
        <Container>
          <Bannersection />
          <DigitalAssets />
          <CreateAndSellNft />
          <NotableNftSection />
          {process.env.REACT_APP_ENV !== "PRODUCTION" && <TopUserSection />}
          <EdexaMediumLatestArticles limit={3} />
        </Container>
      </div>
    </LandingPageProvider>
  );
};
