import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const createCustomNft = (data: FormData): Promise<ICustomNft> => {
//   return HTTPService.post(apiEndPoints.app.CreateCustomNft, data);
// };

const customNftApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    createCustomNft: build.mutation<ICustomNft, FormData>({
      query: (data) => ({
        url: apiEndPoints.app.CreateCustomNft,
        method: "post",
        data: data
      })
    })
  })
});

export const { useCreateCustomNftMutation } = customNftApiSlice;

export interface IAttributeResponse {
  type: string;
  trait_type: string;
  mimeType: string;
  value: string;
}

export interface ICreateCustomNftResponse {
  attributes: IAttributeResponse[];
  categoryId?: string;
  collectionId?: string;
  compressedFilePath?: string;
  createdAt: string;
  createdBy: string;
  description: string;
  fileMimeType: string;
  filePath: string;
  fileThumbPath?: string;
  fileType: string;
  id: string;
  inProcess: {
    originalFile: { status: 0 };
    compressedFile: { status: 0 };
    thumbFile: { status: 0 };
  };
  isCustom: number;
  templateId?: string | null;
  title: string;
  updatedAt: string;
  visibility: number;
  walletAddress: string;
  _id: string;
}

export interface ICustomNft {
  status: number;
  message: string;
  count: number;
  data: ICreateCustomNftResponse;
}
