import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { IRootReducer } from "store/root-reducer";

function useForceUpdate() {
  let [value, setState] = useState(true);
  return () => setState(!value);
}

const NFTLoadingIndicatorCard = ({ mediaSocketListening = false, socketId = "", getDraftNftData = () => {} }) => {
  const check = <FontAwesomeIcon icon={faCheckCircle} />;

  useEffect(() => {
    // dispatch(storeCompressedFileSockerResponse(socketId));
  }, [socketId]);

  let rerenderdForcelly = useForceUpdate();

  useEffect(() => {
    if (socketId) {
      rerenderdForcelly();
    }
  }, [socketId]);

  const { username } = useSelector((state: IRootReducer) => state.userDetailsReducer);

  const compressedPercentage = useSelector((state: IRootReducer) => state.createNFT.percentage);

  return (
    <div className="user-wrapper image">
      <div className="img-wrapper image-loader-light">
        {!mediaSocketListening ? (
          <div className="image-loader-panding">
            <Spinner color="#707070">Loading...</Spinner>
            <p className="c-tx-forth mb-0 ct-20">Processing ... </p>
          </div>
        ) : (
          <div className="image-loader-panding">
            <p className="c-tx-forth mb-0 ct-20 loading-dots-wrapper">
              Processing ({compressedPercentage ? compressedPercentage : "1%"})<span id="dot1">.</span>
              <span id="dot2">.</span>
              <span id="dot3">.</span>
              <br></br>Creation of NFT process is in progress, please <b>do not navigate</b> to other page or <b>do not refresh</b> the page
            </p>
          </div>
        )}
        {!mediaSocketListening && (
          <div className="content-block">
            <div className="text-line loading-border-radius"></div>
            <p className="mb-0 align-items-center d-inline-flex">
              <span className="c-tx-primary f-700"> @{username}</span>
              <span className="pt-25 ms-1 f-700 ct-tx-secondary">{check}</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(NFTLoadingIndicatorCard);
