import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";
import HTTPService from "utils/httpService";

export const updateArchiveAPI = (params: INftListRequest): Promise<INftListReturn> => {
  return HTTPService.get(apiEndPoints.app.updateArchive, { params });
};

const updateArchiveTemplateApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    updateArchiveAPI: build.query<INftListReturn, INftListRequest>({
      query: (params) => ({
        url: apiEndPoints.app.updateArchive,
        params: params,
        method: "get"
      }),
      providesTags: [{ type: "updateArchiveAPI", id: "updateArchiveAPI" }]
    })
  })
});

export const { useLazyUpdateArchiveAPIQuery, useUpdateArchiveAPIQuery } = updateArchiveTemplateApiSlice;

interface INftListRequest {
  id: string;
}

interface INftListReturn {
  status: number;
  message: string;
}
