import * as Yup from 'yup'
import { ImageValidationSchema } from './ImageValidationSchema'

export const CreateCollectionYupValidationSchema = Yup.object().shape(
  {
    name: Yup.string()
      .required('Collection name is required')
      .min(3, 'Name should be at least 3 character long')
      .max(50, 'Name can only be 50 character long'),
    description: Yup.string()
      .required("Collection's description is required")
      .min(3, 'Description should be at least 3 character long')
      .max(250, 'Description can only be 250 character long'),
    categoryId: Yup.string().required('Please select a category'),
    customUrl: Yup.string()
      .required('Custom URL is required')
      .max(128, 'Custom URL can be only 128 char long')
      .matches(/^[a-zA-Z0-9]+$/, 
        'Custom URL can only contain alphabetic characters and numbers'
      ),
    logo: ImageValidationSchema('Logo').required(
      'Logo image is required'
    ),
    banner: ImageValidationSchema('Banner').required(
      'Banner image is required'
    ),
    featured: ImageValidationSchema('Featured').required(
      'Feature image is required'
    ),
    // logo: Yup.string().nullable().required("Logo is required"),
    // featured: Yup.string().nullable().required("Feature image is required"),
    // banner: Yup.string().nullable().required("Banner is required"),
    //   royalty: Yup.string().required(),
    //   censorship: Yup.string().required(),
    twitter: Yup.string().nullable(),
    facebook: Yup.string().nullable(),
    instagram: Yup.string().nullable(),
    youtube: Yup.string().nullable(),
    website: Yup.string().nullable().url()
  },
  [
    ['logo', 'logo'],
    ['featured', 'featured'],
    ['banner', 'banner']
  ]
)
