import {
  CHANGE_STATE_TO_RENDER_COMPONENET,
  COMPRESSED_FILE_RESPONSE,
  DRAFT,
  FILETYPE,
  METAMASK,
  NFT_TITLE,
  PERCENTAGE_OF_COMPRESSED_FILE,
  REMOVEMETADATAATTRIBUTE,
  RESETNFT,
  SETATTACHMENTS,
  SETSTEP,
  SETTHUMBNAIL
} from "./type";

const initialState: ICreateNFTReducer = {
  attachments: {},
  attributes: [],
  fileType: "",
  fileMimeType: "",
  compressedFIlePath: "",
  fileThumbPath: "",
  fileBlob: "",
  step: 1,
  draft: false,
  title: "",
  socket: null,
  percentage: "",
  changeState: false
};

const createNFTReducer = (state = initialState, action: { type: string; payload: any }) => {
  const newState = { ...state };
  switch (action.type) {
    case SETSTEP:
      newState.step = action.payload;
      break;

    case SETATTACHMENTS:
      newState.attachments = action.payload.attachments || "";
      newState.fileBlob = action.payload.fileBlob || "";
      newState.fileMimeType = action.payload.fileMimeType || "";
      newState.compressedFIlePath = action.payload.compressedFIlePath || "";
      newState.fileThumbPath = action.payload.fileThumbPath || "";
      break;
    case SETTHUMBNAIL:
      newState.fileThumbPath = action.payload;
      break;

    case METAMASK:
      newState.attributes = action.payload;
      break;

    case REMOVEMETADATAATTRIBUTE:
      let metaDataAttributes = [...newState.attributes];
      metaDataAttributes.splice(action.payload, 1);
      newState.attributes = metaDataAttributes;
      break;

    case FILETYPE:
      newState.fileType = action.payload;
      break;

    case RESETNFT:
      newState.attachments = {};
      newState.attributes = [];
      newState.fileType = "";
      newState.fileBlob = "";
      newState.step = 1;
      newState.draft = false;
      break;

    case DRAFT:
      newState.draft = action.payload;
      break;

    case NFT_TITLE:
      newState.title = action.payload;
      break;

    case COMPRESSED_FILE_RESPONSE:
      newState.socket = action.payload;
      break;

    case PERCENTAGE_OF_COMPRESSED_FILE:
      newState.percentage = action.payload;
      break;

    case CHANGE_STATE_TO_RENDER_COMPONENET:
      newState.changeState = action.payload;
      break;
  }
  return newState;
};

interface metaDataAttributes {
  trait_type: string;
  value: string;
  trait_typeError: string;
  valueError: string;
}
export interface ICreateNFTReducer {
  attachments: any;
  attributes: metaDataAttributes[] | [];
  fileType: string;
  fileBlob: string;
  step: number;
  draft: boolean;
  fileMimeType: string;
  compressedFIlePath: string;
  fileThumbPath: string;
  title: string;
  socket: any;
  percentage: string;
  changeState: boolean;
}

export { createNFTReducer };
