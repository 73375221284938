import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";
import HTTPService from "utils/httpService";

export const getTemplateAPI = (params: { id: string }): Promise<IGetTemplateListNFTReturn> => {
  return HTTPService.get(apiEndPoints.app.getTemplateList, { params });
};

const appGetTemplatelice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    getTemplateAPI: build.query<IGetTemplateListNFTReturn, { id: string }>({
      query: (params) => ({
        url: apiEndPoints.app.getTemplateList,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "getTemplateAPI", id: "getTemplateAPI" }]
    })
  })
});

export const { useGetTemplateAPIQuery, useLazyGetTemplateAPIQuery } = appGetTemplatelice;

export interface INFTattributes {
  trait_type: string;
  type: string;
  value?: any;
  mimeType: any;
}

export interface ITemplateListData {
  attributes: INFTattributes[];
  description: string;
  filePath: string;
  id: string;
  title: string;
  visibility: number;
  templateId?: string;
  _id: string;
}

export interface IGetTemplateListNFTReturn {
  status: number;
  message: string;
  count: number;
  data: ITemplateListData;
}
