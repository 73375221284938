import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const getDraftNftAPI = (params: { id: string }): Promise<IGetDraftNFTReturn> => {
//   return HTTPService.get(apiEndPoints.app.GetDraft, { params });
// };
const draftApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    getDrafAPI: build.query<IGetDraftNFTReturn, { id: string }>({
      query: (params) => ({
        url: apiEndPoints.app.GetDraft,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "getDrafAPIQuery", id: "getDrafAPIQuery" }]
    })
  })
});

export const { useGetDrafAPIQuery, useLazyGetDrafAPIQuery } = draftApiSlice;

export interface IDraftNft {
  attributes: { trait_type: string; value: string }[];
  fileThumbPath: string;
  title: string;
  description: string;
  price: number;
  visibility: number;
  tags: string[];
  _id: string;
  createdBy: string;
  compressedFilePath: string;
  filePath: string;
  categoryId: string;
  fileType: string;
  fileMimeType: string;
  walletAddress: string;
  templateId: string;
  id: string;
  inProcess: {
    compressedFile: {
      key: string;
    };
  };
}

export interface IGetDraftNFTReturn {
  status: number;
  message: string;
  data: IDraftNft;
}
