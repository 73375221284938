import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeFullS3ObjectUrl } from "assets/images";
import ImageComponent from "components/ImageComponent";
import WithErrorBoundaryHandler from "core/error/WithErrorBoundaryHandler";
import TopsUserImageComponent from "pages/TopsUserImageComponent";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { handleTheLanguageTranslation } from "utils/functions/commonFunctions";
import LandingPageContext from "./Dashboard";
const defaultBannerImage = makeFullS3ObjectUrl("profile-banner.png");

const TopUserSection = () => {
  const landingPageData = useContext(LandingPageContext);
  const navigate = useNavigate();
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);

  return (
    <section className="top-user ct-90 cb-100">
      <Row>
        <Col md="12">
          <h3 className="text-white section-title f-700 text-center h2">{handleTheLanguageTranslation(label?.top_users, "Top users")}</h3>
        </Col>
      </Row>
      <div className="top-user-main-control-wrapper">
        <Row className="justify-content-center row-cols-xl-5 row-cols-lg-3 row-cols-sm-2 row-cols-1 ">
          {/* @ts-ignore */}
          {landingPageData?.topUsers?.data === undefined || landingPageData?.topUsers?.data < 0 ? (
            <p style={{ color: "#fff", textAlign: "center" }}>Loading...</p>
          ) : (
            landingPageData?.topUsers?.data?.map((topUser) => {
              return (
                <Col key={topUser._id}>
                  <div
                    className="top-user-wrapper overflow-hidden cursor"
                    onClick={() => {
                      navigate(`/account/@${topUser._id}`);
                    }}>
                    <div className="img-wrapper image-loader">
                      <TopsUserImageComponent bannerImage={topUser.bannerImage ? topUser.bannerImage : defaultBannerImage} />
                    </div>
                    <div className="bg-white content-info pb-50">
                      <div className="user-block">
                        <div className="user-img-wrapper">
                          <ImageComponent src={topUser.cropProfilePicture ? topUser?.cropProfilePicture : topUser?.profilePicture} name={topUser?.username} />
                        </div>
                      </div>
                      <div className="capsual-block" data-title={topUser?.name || topUser?.username}>
                        <span className="animate-bl text-truncate" style={{ maxWidth: 100 }}>
                          {topUser?.name || topUser?.username}
                        </span>
                      </div>
                      <div className="username-block text-center">
                        <div className="d-flex align-items-center justify-content-center">
                          <h3 className="mb-0 lh-26 c-tx-secondary f-700">{topUser?.username} </h3>
                          <span className="checkmark-block">
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                        </div>
                        <p className="c-tx-forth nft-ct text-truncate">{topUser.NFTs > 1 ? `${topUser.NFTs} NFTs` : `${topUser.NFTs} NFT`}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })
          )}
          ;
        </Row>
      </div>
      <Row>
        <Col md="12">
          <div className="text-center btn-block">
            <Link to="/top-users">
              <Button className="custom-primary-outline">{handleTheLanguageTranslation(label?.see_all_top_users, "See all top users")}</Button>
            </Link>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default WithErrorBoundaryHandler(TopUserSection, "Top Users");
