import { faFacebook, faInstagram, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faGlobe, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IUserProfileOverview } from "actions/app/get-user-profile";
import { makeFullS3ObjectUrl } from "assets/images";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { handleTheLanguageTranslation } from "utils/functions/commonFunctions";
const ProfileBanner = makeFullS3ObjectUrl("profile-banner.png");

const OverViewUserProfile = ({ profileData }: { profileData: IUserProfileOverview | undefined }) => {
  const [socialLinks, setSocialLinks] = useState<{ url: string; username: string; icon: IconDefinition }[]>([]);
  useEffect(() => {
    // set all the social links of nft
    setSocialLinks([
      {
        url: "https://www.youtube.com/",
        username: profileData?.data.links.youtube || "",
        icon: faYoutube
      },
      {
        url: "https://twitter.com/",
        username: profileData?.data.links.twitter || "",
        icon: faTwitter
      },
      {
        url: "https://www.facebook.com/",
        username: profileData?.data.links.facebook || "",
        icon: faFacebook
      },
      {
        url: "https://www.instagram.com/",
        username: profileData?.data.links.instagram || "",
        icon: faInstagram
      },
      // website url will be the complete url from backend
      {
        url: "",
        username: profileData?.data.links.website || "",
        icon: faGlobe
      }
    ]);
  }, [profileData?.data.links]);
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);

  return (
    <div className="overview-nft c-mb-40 c-mt-40">
      <div className="poster-wrapper">
        <div className="poster-img-wrapper">
          <img src={profileData?.data.bannerImage || ProfileBanner} className="img-fluid poster-img" alt="poster" />
        </div>
        <div className="profile-img-wrapper">
          <img src={profileData?.data.cropProfilePicture} className="img-fluid profile-img" alt="poster" />
        </div>
      </div>
      <div className="overview-nft-inner-wrapper">
        <div className="block-1"></div>
        <div className="block-2">
          <Form className="create-min-form">
            <FormGroup className="position-relative form-input-mb ">
              <Input id="title" name="title" placeholder={handleTheLanguageTranslation(label?.name, "Name")} type="text" disabled value={profileData?.data.name} />
              <div className="character-counter">
                <p className="c-tx-forth mb-0">{profileData?.data.name.length}/50</p>
              </div>
            </FormGroup>
            <FormGroup className="position-relative form-input-mb ">
              <Input
                id="walletaddress"
                name="walletaddress"
                placeholder={handleTheLanguageTranslation(label?.wallet_address, "Wallet address")}
                type="text"
                disabled
                value={profileData?.data.walletAddress}
              />
              <div className="character-counter">
                <p className="c-tx-forth mb-0">{profileData?.data.walletAddress.length}/50</p>
              </div>
            </FormGroup>
            <FormGroup className="position-relative form-input-mb">
              <Input id="title" name="Description" placeholder={handleTheLanguageTranslation(label?.bio, "Bio")} type="textarea" disabled value={profileData?.data.bio} />
              <div className="character-counter text-area">
                <p className="c-tx-forth mb-0">{profileData?.data.bio.length}/250</p>
              </div>
            </FormGroup>

            {socialLinks.filter((link) => Boolean(link.username)).length > 0 && (
              <div className="position-relative form-input-mb form-input-wrap-mb mb-0 ">
                <Label className="">{handleTheLanguageTranslation(label?.social_links, "Social links")}</Label>
                <div className="social-bt-wrap single-channel row">
                  {socialLinks.map((link, index) => {
                    if (!link.username || link.username === null) return <></>;
                    return (
                      <div className="two-input col col-6" key={link.username + index + 20000}>
                        <a href={link.url + link.username} target="_blank" referrerPolicy="no-referrer" rel="noreferrer" className="text-decoration-none">
                          <FormGroup className="position-relative form-input-mb disable w-100">
                            <div className="social-input">
                              <div className="social-icon">
                                {" "}
                                <FontAwesomeIcon icon={link.icon} />
                              </div>
                              <div className="input-wrapper-bl">
                                <span className="opacity-50">{link.url}</span>
                                <span className="dark-text-bold text-break">{link.username}</span>
                                {/* <div className="cy-bl"></div> */}
                                {/* <Input id="title" name="title" placeholder="YourWebsiteLink" value="" type="text" /> */}
                              </div>
                            </div>
                          </FormGroup>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default React.memo(OverViewUserProfile);
