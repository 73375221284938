import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const getUserPlanInfo = (): Promise<IUserPlansResult> => {
//   return HTTPService.get(apiEndPoints.UserPlan);
// };

const userPlanApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    getUserPlanInfo: build.query<IUserPlansResult, void>({
      query: () => ({ method: "get", url: apiEndPoints.UserPlan }),
      providesTags: [{ type: "getUserPlanInfo", id: "getUserPlanInfo" }]
    })
  })
});

export const { useGetUserPlanInfoQuery, useLazyGetUserPlanInfoQuery } = userPlanApiSlice;
export interface IUserSubscription {
  _id: string;
  planId: string;
  paymentId: string;
  purchaseDate: Date;
  name: string;
  price: number;
}
export interface IUserPlan {
  userSubscription: IUserSubscription[];
  planName: string;
  perFile: number;
  totalNFTs: number;
  usedNFTs: number;
}
export interface IUserPlansResult {
  status: number;
  message: string;
  data: IUserPlan;
}
