import { useLazyGetLanguagesJsonQuery } from "actions/admin/getLabels";
import UnauthorizedPopup from "components/global/UnauthorizedPopup";
import ScrollToTopButton from "components/ScrollToTopButton";
import { useScrollTopRouteChange } from "hooks";
import { Suspense, useCallback, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { changeLanguage, setLanguagesJson } from "store/internationalization/action";
import { INTERNATIONALLANGUAGESENUM } from "store/internationalization/type";
import { IRootReducer } from "store/root-reducer";
import { setUserDeviceId } from "store/user/action";
import { localStorageKeys } from "utils/constants/constants";
import { getDecryptedLocalStorage, getDeviceId, setEncryptedLocalStorage } from "utils/functions/commonFunctions";
import RoutingComponent from "./core/routes/Routing";
import "./styles/styles.scss";
function App() {
  const dispatch = useDispatch();

  const langaugeType = useSelector((state: IRootReducer) => state.userDetailsReducer.user);
  const [getLanguagesJsonQuery] = useLazyGetLanguagesJsonQuery();

  // this function will do all the heavy calculations to make this site multilingual
  const internationalizeTheApp = useCallback(() => {
    // if we already have localstorage of languages array then use it.
    if (getDecryptedLocalStorage(localStorageKeys.internationalization) && getDecryptedLocalStorage(localStorageKeys.languageType)) {
      dispatch(setLanguagesJson(getDecryptedLocalStorage(localStorageKeys.internationalization)));
      dispatch(changeLanguage(getDecryptedLocalStorage(localStorageKeys.languageType)));
    } else {
      // get the languages json and store it inside localstorage
      // redux store will take that language json from there.
      getLanguagesJsonQuery().then((response) => {
        if (response.data?.data) {
          let languagesJson = {
            DE: response.data.data.DE,
            EN: response.data.data.EN
          };
          dispatch(setLanguagesJson(languagesJson));
          dispatch(changeLanguage(langaugeType?.language || INTERNATIONALLANGUAGESENUM.ENGLISH));
          // store data in lcoalstorage to save api calls every time app reloads
          setEncryptedLocalStorage(localStorageKeys.internationalization, languagesJson);
          setEncryptedLocalStorage(localStorageKeys.languageType, langaugeType?.language || INTERNATIONALLANGUAGESENUM.ENGLISH);
        }
      });
    } // eslint-disable-next-line
  }, [dispatch]);

  /**
   * @REMOVE_CONSOLES
   * // remove the working of console logs
   * // remove any accidental use of console logs
   */

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "PRODUCTION" || process.env.REACT_APP_ENV === "STAGING") {
      // GlobalDebug(false, false);
    }
    getDeviceId().then((uDeviceId) => {
      dispatch(setUserDeviceId(uDeviceId));
    });
    internationalizeTheApp();
  }, [dispatch, internationalizeTheApp]);

  // scroll to top if pathname change [change in route history]
  useScrollTopRouteChange();
  return (
    <Suspense
      fallback={
        <div className="loader-wrapper">
          <Spinner type="grow" color="light" />
        </div>
      }>
      <ScrollToTopButton />
      <HelmetProvider>
        <RoutingComponent />
        <UnauthorizedPopup />
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
