import React, { useEffect } from "react";

/**
 * @abstract - please use only when needed and with caution
 *
 * @param {React.RefObject<HTMLElement>} wrapperToScroll - this will be the reference of the element which we want to scroll
 * @returns {null}
 */
const useScrollTheReference = (wrapperToScroll: React.RefObject<HTMLElement>) => {
  const scrollMintWrapperOnBodyScroll = (e: WheelEvent) => {
    if (wrapperToScroll.current) {
      // if the scrolling target is wrapper itself then do not do anything and let naturally scroll happen
      // casting to any because didn't find any solution
      // @todo - remove the any typecasting
      if ((e as any)?.path?.includes(wrapperToScroll.current)) {
        return null;
      } else {
        wrapperToScroll.current.scroll({
          behavior: "smooth",
          // top: e.deltaY > 10 ? 200 : 0
        });
      }
    }
  };
  useEffect(() => {
    window.addEventListener("wheel", scrollMintWrapperOnBodyScroll);
    return () => document.body.removeEventListener("wheel", scrollMintWrapperOnBodyScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default useScrollTheReference;
