export enum MintGateways {
  ETHEREUM_BLOCKCHAIN = 1,
  POLYGON_BLOCKCHAIN = 2,
  BINANCE_BLOCKCHAIN = 3,
  EDEXA = 4,
  POLYGON_BLOCKCHAIN_CONTRACT = 5,
  Arbitrum = 6,
  Solana = 10,
  Optimism = 7,
  NULL = ""
}

export const gateGatewayNames = (gateway: number | undefined) => {
  if (!gateway) return "";
  switch (gateway) {
    case 1:
      return "Ethereum";
    case 2:
      return "Polygon";
    case 3:
      return "Binance";
    case 4:
      return "edeXaScan";
    case 5:
      return "Polygon";
    case 6:
      return "Arbitrum";
    case 7:
      return "Optimism";
    case 10:
      return "Solana";
  }
};

export const gateGatewayScannerNames = (gateway: number | undefined) => {
  if (!gateway) return "";
  switch (gateway) {
    case 1:
      return "Etherscan";
    case 2:
      return "Polygonscan";
    case 3:
      return "BscScan";
    case 5:
      return "Polygonscan";
    case 4:
      return "edeXaScan";
    case 6:
      return "Arbitrumscan";
    case 7:
      return "Optimismscan";
    case 10:
      return "Solanascan";
  }
};
