import * as Yup from "yup";
import { ImageValidationSchema } from "./ImageValidationSchema";

// address validation with yup
Yup.addMethod(Yup.string, "validateEtherAddress", function (errorMessage) {
  return this.test("validate-eth-wallet-address", errorMessage, function (value) {
    if (!value) return true;
    const { path, createError } = this;
    // @ts-ignore
    return WAValidator.validate(value, "eth") || createError({ path, message: errorMessage });
  });
});
export const UpdateUserProfileYupValidationSchema = Yup.object().shape(
  {
    bio: Yup.string().nullable().min(3, "User bio should be at least 3 character long").max(250, "User bio can only be 250 character long"),
    banner: ImageValidationSchema("Banner").nullable(),
    // @ts-ignore
    walletAddress: Yup.string().nullable().validateEtherAddress("This Address is not a valid Ethereum wallet address"),
    twitter: Yup.string().nullable(),
    facebook: Yup.string().nullable(),
    instagram: Yup.string().nullable(),
    youtube: Yup.string().nullable(),
    website: Yup.string().nullable().url()
  },
  [["banner", "banner"]]
);
