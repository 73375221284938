import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";
import { paginationKeysObject } from "utils/constants/constants";
import HTTPService from "utils/httpService";

export const appGetTopUsers = (params: typeof paginationKeysObject): Promise<ITopUsers> => {
  return HTTPService.get(apiEndPoints.topUsers, { params });
};

const getTopUserApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appGetTopUsers: build.query<ITopUsers, typeof paginationKeysObject>({
      query: (params) => ({
        url: apiEndPoints.topUsers,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "appGetTopUsers", id: "appGetTopUsers" }]
    })
  })
});

export const { useAppGetTopUsersQuery, useLazyAppGetTopUsersQuery } = getTopUserApiSlice;

export interface ITopUser {
  _id: string;
  name: string;
  username: string;
  profilePicture?: string;
  cropProfilePicture?: string;
  bannerImage: string;
  NFTs: number;
  isVerified: number;
}

export interface ITopUsers {
  status: number;
  message: string;
  count: number;
  data: ITopUser[];
}
