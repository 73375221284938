export const toasterPosition = 'bottom-left'
export const cookieExpiresInDays = 7

export const TemplateLimite = 12

export const paginationKeysObject = {
  page: 1,
  limit: 12,
  search: ''
}

export const extensionLists = {
  video: ['m4v', 'avi', 'mpg', 'mp4', 'webm'],
  image: ['jpg', 'gif', 'bmp', 'png'],
  document: ['pdf', 'doc', 'docx']
}

export const ToggleMessage = {
  Private: 'Please enable to make your NFT Private',
  Public: 'Please disable to make your NFT Public'
}

export enum PAYMENT_STATUS {
  PENDING = 'pending',
  FAILED = 'failed',
  SUCCESS = 'success',
  CANCELLED = 'cancelled'
}

export const debounceTimeInMilliseconds = 500

export const fileSize = {
  '1mb': 1 * 1024 * 1024,
  '2mb': 2 * 1024 * 1024,
  '5mb': 5 * 1024 * 1024,
  '30mb': 30 * 1024 * 1024,
  '50mb': 50 * 1024 * 1024,
  '100mb': 100 * 1024 * 1024
}

export const localStorageKeys = {
  isLoggedIN: 'isLoggedIN',
  userToken: 'userToken',
  cookieToken: 'cookieToken',
  deviceId: 'deviceId',
  emailId: 'emailId',
  user_id: 'userId_profile',
  username: 'username',
  wallet: 'wallet',
  unauthContentRedirect: 'unauthcontenturl',
  globalSetting: 'globalSetting',
  internationalization: 'internationalization',
  languageType: 'languageType'
}

export const MediaTYpes = {
  IMAGE: 'Image',
  VIDEO: 'Video',
  PDF: 'PDF',
  DOC: 'Document'
}

export const cookieKeys = {
  cryptoSecretKey: 'edexaUser',
  cookieInitial: 'edexa',
  cookieUser: 'CookieUser',
  paypalPayment: 'PaypalPayment',
  nftId: 'nftid'
}

export const DraftButtonText = {
  editButtonText: 'Edit',
  deleteButtonText: 'Delete'
}

export const validationMessages = {
  image: {
    required: 'Image is required',
    match: 'Please upload only image',
    invalid: 'Please select only jpg, jpeg, png, gif type image',
    min: `Image size must be under ${
      fileSize['50mb'] / 1024 / 1024
    }MB!`
  },
  file: {
    required: 'File is required',
    match: 'Please upload only file',
    invalid: 'Please select only pdf, doc  type file',
    min: `File size must be under ${
      fileSize['50mb'] / 1024 / 1024
    }MB!`
  },
  video: {
    required: 'Video is required',
    match: 'Please upload only video',
    invalid: 'Please select only video file',
    min: `Video size must be under ${
      fileSize['50mb'] / 1024 / 1024
    }MB!`
  },
  title: {
    required: 'Title is required',
    max: '150 character are allow'
  },
  description: {
    required: 'Description is required',
    max: '150 character are allow'
  },
  logo: {
    required: 'Logo is required'
  }
}

export const monthsList = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 }
]
