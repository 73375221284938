import { INftListReturn } from "actions/app/get-nft-list";
import { IUserPlan } from "actions/user-plan";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { handleTheLanguageTranslation, numberFormatter } from "utils/functions/commonFunctions";
export interface IUserProfileCountersPropsType {
  loading: boolean;
  isLoggedIn: boolean;
  userNftsList: INftListReturn | undefined;
  checkIfRequestIsForCurrentUser: Function;
  userPlan: IUserPlan | undefined;
}
const UserProfileCounters = ({ loading, isLoggedIn, checkIfRequestIsForCurrentUser, userNftsList, userPlan }: IUserProfileCountersPropsType) => {
  const totaNFTsOfLoggedInUser = (userNftsList?.count.mintedByMeCount || 0) + (userNftsList?.count?.ownByMeCount || 0) || 0;
  const navigate = useNavigate();
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  return (
    <Row>
      <Col md="12">
        <ul className="item-counter-list">
          {userNftsList?.user && (
            <AnimatePresence>
              <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.1 }} exit={{ opacity: 0 }} key={"items"}>
                <h3 className="mb-0 text-white semi-title">{numberFormatter(userNftsList.count?.NFTs || totaNFTsOfLoggedInUser, 1)}</h3>
                <h5 className="c-tx-third mb-0">{handleTheLanguageTranslation(label?.items, "Items")}</h5>
              </motion.li>
              {isLoggedIn && !checkIfRequestIsForCurrentUser() && (
                <motion.li
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.1 }}
                  exit={{ opacity: 0 }}
                  key={"drafts"}
                  onClick={() => navigate("/account/draft-collection")}
                  role="button">
                  <h3 className="mb-0 text-white semi-title">{numberFormatter(userNftsList?.count.drafts || 0, 1)}</h3>
                  <h5 className="c-tx-third mb-0">{handleTheLanguageTranslation(label?.drafts, "Drafts")}</h5>
                </motion.li>
              )}
              {isLoggedIn && !checkIfRequestIsForCurrentUser() && (
                <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.1 }} exit={{ opacity: 0 }} key={"nft-balance"}>
                  <h3 className="mb-0 text-white semi-title">
                    {/* numberFormatter(userPlan?.usedNFTs || 0, 1)}/ */}
                    {userPlan?.totalNFTs! >= 0 ? numberFormatter(userPlan?.totalNFTs || 0, 1) : "∞"}
                  </h3>
                  <h5 className="c-tx-third mb-0">{handleTheLanguageTranslation(label?.nft_balance, "NFT balance")}</h5>
                </motion.li>
              )}
              {isLoggedIn && !checkIfRequestIsForCurrentUser() && (
                <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.1 }} exit={{ opacity: 0 }} key={"used-nfts"}>
                  <h3 className="mb-0 text-white semi-title">{numberFormatter(userPlan?.usedNFTs || 0, 1)}</h3>
                  <h5 className="c-tx-third mb-0">{handleTheLanguageTranslation(label?.used_nfts, "Used NFTs")}</h5>
                </motion.li>
              )}
            </AnimatePresence>
          )}
          {loading && (
            <AnimatePresence>
              <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.1 }} exit={{ opacity: 0 }} key={"first-Skeleton"}>
                <Skeleton width={70} height={20} />
              </motion.li>
              <motion.li initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.1 }} exit={{ opacity: 0 }} key={"second-Skeleton"}>
                <Skeleton width={70} height={20} />
              </motion.li>
            </AnimatePresence>
          )}
        </ul>
      </Col>
    </Row>
  );
};

export default React.memo(UserProfileCounters);
