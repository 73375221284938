import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const getGlobalSettingAPI = (): Promise<IGetGlobalSettingResponse> => {
//   return HTTPService.get(apiEndPoints.app.getGlobalSetting);
// };

const globalSettingAPIslice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    getGlobalSettingAPI: build.query<IGetGlobalSettingResponse, void>({
      query: () => ({ url: apiEndPoints.app.getGlobalSetting, method: "get" })
    })
  })
});

export const { useGetGlobalSettingAPIQuery, useLazyGetGlobalSettingAPIQuery } = globalSettingAPIslice;

export interface IGetGlobalSettingDataResponse {
  _id: string;
  requestDemo: number;
  underMaintenance: number;
  customNft: number;
  nftCollection: number;
}

export interface IGetGlobalSettingResponse {
  status: number;
  message: string;
  data: IGetGlobalSettingDataResponse;
}
