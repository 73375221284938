import { faCompass, faHeart, faUser, faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { faArchive, faCalculator, faChevronDown, faCog, faDollarSign, faFileInvoice, faLayerGroup, faPenSquare, faSignOutAlt, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppUpdateUserProfileMutation } from "actions/app/update-user-profile";
import { logoutCurrentUserAPI } from "actions/auth/user-logout";
import { makeFullS3ObjectUrl } from "assets/images";
import { motion } from "framer-motion";
import useIsWindowScrolling from "hooks/useIsWindowScrolling";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, Navbar, UncontrolledDropdown } from "reactstrap";
import { onUserLogout, onUserLogoutAndRedirect } from "store/auth/action";
import { resetCreateNFTAction } from "store/createNFT/action";
import { changeLanguage } from "store/internationalization/action";
import { INTERNATIONALLANGUAGESENUM } from "store/internationalization/type";
import { setLoading } from "store/loader/action";
import { setRequestDemoModal } from "store/requestDemoModal/action";
import { IRootReducer } from "store/root-reducer";
import { localStorageKeys } from "utils/constants/constants";
import { handleTheLanguageTranslation, setEncryptedLocalStorage } from "utils/functions/commonFunctions";
import SearchBox from "../../pages/SearchBox";
import { onToggleHeader } from "../../store/header/action";
import "../../styles/components/header.scss";
const deImg = makeFullS3ObjectUrl("de.png");
const enImg = makeFullS3ObjectUrl("en.png");

const HeaderComponent = () => {
  const { isHeaderOpen } = useSelector((state: IRootReducer) => state.header);
  const { taskInProgress } = useSelector((state: IRootReducer) => state.loading);
  const languageType = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.languageType);
  const { IsNftCollectionDisabled, IsCustomNftDisabled } = useSelector((state: IRootReducer) => state.globalSettingReducer) || {};
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);

  const isLoggedIn = useSelector((state: IRootReducer) => state.auth.isLoggedIn);
  const { user, user_id } = useSelector((state: IRootReducer) => state.userDetailsReducer);
  const userReducer = useSelector((state: IRootReducer) => state.userDetailsReducer);
  const { requestDemo } = useSelector((state: IRootReducer) => state.globalSettingReducer);

  // mutation
  const [updateUserProfileMutation] = useAppUpdateUserProfileMutation();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchBoxVisible, setSearchBoxVisible] = useState(false);
  const [dark, setDark] = useState<boolean>(false);
  const [issOpen, setIssOpen] = useState(false);
  const Togggle = () => {
    setIssOpen(!issOpen);
  };
  const { isScrolling } = useIsWindowScrolling(100);
  useEffect(() => {
    if (Object.values(document.getElementsByTagName("body")[0].classList).includes("light-theme")) {
      setDark(false);
    } else {
      setDark(true);
    }
  }, [location]);

  const handleExplore_SerachPermission = () => {
    if (requestDemo) {
      if (isLoggedIn) {
        if (userReducer.isAccess === 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const handleLoginPermisson = () => {
    if (isLoggedIn) {
      if (userReducer.isAccess === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const handleLogoutAPI = () => {
    dispatch(setLoading(true));
    if (!taskInProgress) {
      logoutCurrentUserAPI()
        .then((res) => {
          if (res.status === 200) {
            dispatch(onUserLogout());
            navigate("/", { replace: true });
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(onUserLogout());
          navigate("/", { replace: true });
        });
    }
    dispatch(onToggleHeader(!isHeaderOpen));
  };

  return (
    <motion.div className={`header-wrapper ${isScrolling ? "stickey" : ""}`}>
      <Navbar color="transparent" expand="md" light>
        <Link to="/" className="logo-text navbar-brand">
          <span>{handleTheLanguageTranslation(label?.edexa, "edeXa")} </span>
          <span className="f-600">{handleTheLanguageTranslation(label?.nfts, "NFTs")} </span>
        </Link>

        {/* mobile user profile menu */}
        {handleLoginPermisson() ? (
          <div className="d-flex ms-auto d-md-none mobile-user-links me-3">
            <UncontrolledDropdown inNavbar nav className="user-dropdown">
              <DropdownToggle nav className="pe-0 user-link align-items-center d-flex" style={{ marginTop: 0 }}>
                {!user?.cropProfilePicture && !user?.cropProfilePicture && <FontAwesomeIcon icon={faUserCircle} />}
                {(user?.cropProfilePicture || user?.cropProfilePicture) && <img src={user?.cropProfilePicture || user?.cropProfilePicture} alt="" className={"user-profile-image "} />}
                <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: "12px" }} className={`${dark ? "icon-dark-color" : "text-white"} ms-2 mb-n1`} />
              </DropdownToggle>
              <DropdownMenu style={{ padding: 0 }}>
                <DropdownItem>
                  <Link
                    to={`/account/@${user_id}`}
                    className={`nav-link ${location.pathname.includes(`/account/@${user_id}`) ? "text-primary" : "text-black"}`}
                    onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                    <FontAwesomeIcon icon={faUser} className="me-2" />
                    {handleTheLanguageTranslation(label?.profile, "Profile")}
                  </Link>
                </DropdownItem>
                <DropdownItem divider style={{ margin: 0 }} />
                <DropdownItem>
                  <Link
                    to={"account/favourite-collection"}
                    className={`nav-link ${location.pathname.includes("/favourite-collection") ? "text-primary" : "text-black"}`}
                    onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                    <FontAwesomeIcon icon={faHeart} className="me-2" />
                    {handleTheLanguageTranslation(label?.favourite, "Favourite")}
                  </Link>
                </DropdownItem>
                <DropdownItem divider style={{ margin: 0 }} />
                <DropdownItem>
                  <Link
                    to={"/account/draft-collection"}
                    className={`nav-link ${location.pathname.includes("/draft-collection") ? "text-primary" : "text-black"}`}
                    onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                    <FontAwesomeIcon icon={faPenSquare} className="me-2" />
                    {handleTheLanguageTranslation(label?.draft, "Draft")}
                  </Link>
                </DropdownItem>
                <DropdownItem divider style={{ margin: 0 }} />
                <DropdownItem>
                  <Link to={"/invoice"} className={`nav-link ${location.pathname.includes("/invoice") ? "text-primary" : "text-black"}`} onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                    <FontAwesomeIcon icon={faFileInvoice} className="me-2" />
                    {handleTheLanguageTranslation(label?.invoices, "Invoices")}
                  </Link>
                </DropdownItem>
                <DropdownItem divider style={{ margin: 0 }} />

                {/* for mobile */}
                {!IsNftCollectionDisabled && (
                  <DropdownItem>
                    <Link
                      to={"/explore-collections"}
                      className={`nav-link ${location.pathname.includes("/explore-collections") ? "text-primary" : "text-black"}`}
                      onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                      <FontAwesomeIcon icon={faLayerGroup} className="me-2" />
                      {handleTheLanguageTranslation(label?.my_collections, "My collections")}
                    </Link>
                  </DropdownItem>
                )}
                <DropdownItem divider style={{ margin: 0 }} />
                {!IsCustomNftDisabled && (
                  <DropdownItem>
                    <Link
                      to={"/archive-templates"}
                      className={`nav-link ${location.pathname.includes("/archive-templates") ? "text-primary" : "text-black"}`}
                      onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                      <FontAwesomeIcon icon={faArchive} className="me-2" />
                      {handleTheLanguageTranslation(label?.archive_templates, "Archive Templates")}
                    </Link>
                  </DropdownItem>
                )}

                <DropdownItem divider style={{ margin: 0 }} />
                <DropdownItem>
                  <a href={process.env.REACT_APP_userProfile} target={"_blank"} className="nav-link text-black" onClick={() => dispatch(onToggleHeader(!isHeaderOpen))} rel="noreferrer">
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    {handleTheLanguageTranslation(label?.manage_edexa_account, "Manage edeXa account")}
                  </a>
                </DropdownItem>
                <DropdownItem divider style={{ margin: 0 }} />
                <DropdownItem>
                  <span onClick={handleLogoutAPI} className="nav-link text-black">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                    {handleTheLanguageTranslation(label?.logout, "Logout")}
                  </span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ) : handleExplore_SerachPermission() && !isLoggedIn ? (
          <div className="d-flex ms-auto d-md-none mobile-user-links me-3">
            <Link
              to="/login"
              className="nav-link fw-bold"
              onClick={(e) => {
                e.preventDefault();
                dispatch(onUserLogoutAndRedirect());
                dispatch(onToggleHeader(!isHeaderOpen));
              }}>
              {handleTheLanguageTranslation(label?.login, "Login")}
            </Link>
          </div>
        ) : (
          !handleExplore_SerachPermission() && (
            <div className="d-flex ms-auto d-md-none mobile-user-links me-3">
              <Link to="#" className="nav-link fw-bold" onClick={() => dispatch(setRequestDemoModal(true))}>
                {handleTheLanguageTranslation(label?.request_for_a_demo, "Request for a Demo")}
              </Link>
            </div>
          )
        )}
        {/* <NavbarToggler onClick={() => dispatch(onToggleHeader(!isHeaderOpen))} /> */}
        <Collapse navbar isOpen={isHeaderOpen}>
          <Nav className="d-none d-md-flex  me-auto" navbar>
            {handleExplore_SerachPermission() && (
              <>
                <NavItem>
                  <Link to="/explore" className={`nav-link ${location.pathname === "/explore" ? "text-primary" : ""}`} onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                    {handleTheLanguageTranslation(label?.explore, "Explore")}
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/all-nfts" className={`nav-link ${location.pathname === "/all-nfts" ? "text-primary" : ""}`} onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                    {handleTheLanguageTranslation(label?.allnfts, "All Nfts")}
                  </Link>
                </NavItem>
                {!IsNftCollectionDisabled ? (
                  <>
                    <NavItem>
                      <Link to="/all-collections" className={`nav-link ${location.pathname.includes("all-collections") ? "text-primary" : ""}`} onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                        {handleTheLanguageTranslation(label?.collections, "Collections")}
                      </Link>
                    </NavItem>
                  </>
                ) : null}

                <NavItem id="click-button">
                  <p className="nav-link mb-0" role={"button"} onClick={() => setSearchBoxVisible(!searchBoxVisible)}>
                    {handleTheLanguageTranslation(label?.search, "Search")}
                  </p>
                </NavItem>
              </>
            )}
          </Nav>
          <Nav className="d-none d-md-flex align-items-center" navbar>
            <UncontrolledDropdown inNavbar nav className="user-dropdown2 c-mr-20">
              <DropdownToggle nav className="pe-0 user-link align-items-center d-flex">
                <img src={languageType === "EN" ? enImg : deImg} alt="" className={"flag-img"} />
                <span onClick={() => {}} className={"nav-link "}>
                  {languageType === "EN" ? "English" : "German"}
                </span>
                <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: "12px", marginTop: 3 }} className={`${dark ? "icon-dark-color" : "text-white"}`} />
              </DropdownToggle>
              <DropdownMenu
                style={{ padding: 0 }}
                onClick={() => {
                  isLoggedIn &&
                    updateUserProfileMutation({
                      language: languageType === "EN" ? INTERNATIONALLANGUAGESENUM.GERMAN : INTERNATIONALLANGUAGESENUM.ENGLISH
                    });
                  dispatch(changeLanguage(languageType === "EN" ? INTERNATIONALLANGUAGESENUM.GERMAN : INTERNATIONALLANGUAGESENUM.ENGLISH));
                  setEncryptedLocalStorage(localStorageKeys.languageType, languageType === "EN" ? INTERNATIONALLANGUAGESENUM.GERMAN : INTERNATIONALLANGUAGESENUM.ENGLISH);
                }}>
                <DropdownItem className="d-flex align-items-center">
                  <img src={languageType === "EN" ? deImg : enImg} alt="" className={"flag-img"} style={{ marginLeft: "10px" }} />
                  <span className={`nav-link2 ${location.pathname === "/create-nft" ? "text-primary" : "c-tx-secondary"}`}>{languageType === "EN" ? "German" : "English"}</span>
                </DropdownItem>{" "}
              </DropdownMenu>
            </UncontrolledDropdown>

            {!handleExplore_SerachPermission() && (
              <NavItem>
                <Link to="#" className="nav-link" onClick={() => dispatch(setRequestDemoModal(true))}>
                  {handleTheLanguageTranslation(label?.request_for_a_demo, "Request for a Demo")}
                </Link>
              </NavItem>
            )}

            {handleExplore_SerachPermission() && !isLoggedIn && (
              <NavItem>
                <Link
                  to="/login"
                  className="nav-link"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(onUserLogoutAndRedirect());
                    dispatch(onToggleHeader(!isHeaderOpen));
                  }}>
                  {handleTheLanguageTranslation(label?.login, "Login")}
                </Link>
              </NavItem>
            )}

            {/* desktop user profile menu */}
            {handleLoginPermisson() && (
              <div className="d-none d-md-flex align-items-center ">
                <span
                  onClick={() => {
                    navigate("/create-nft");
                    dispatch(resetCreateNFTAction());
                    dispatch(onToggleHeader(!isHeaderOpen));
                  }}
                  className={`nav-link ${location.pathname === "/create-nft" ? "text-primary" : ""}`}
                  role={"button"}>
                  {handleTheLanguageTranslation(label?.create, "Create")}
                </span>
                <UncontrolledDropdown inNavbar nav className="user-dropdown">
                  <DropdownToggle title="Profile" nav className="pe-0 user-link align-items-center d-flex" style={{ marginTop: 6 }}>
                    {!user?.cropProfilePicture && !user?.cropProfilePicture && <FontAwesomeIcon icon={faUserCircle} />}
                    {(user?.cropProfilePicture || user?.cropProfilePicture) && <img src={user?.cropProfilePicture || user?.cropProfilePicture} alt="" className={"user-profile-image "} />}
                    <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: "12px" }} className={`${dark ? "icon-dark-color" : "text-white"} ms-2 mb-n1`} />
                  </DropdownToggle>
                  <DropdownMenu style={{ padding: 0 }}>
                    <DropdownItem>
                      <Link
                        to={`/account/@${user_id}`}
                        className={`nav-link ${location.pathname.includes(`/account/@${user_id}`) ? "text-primary" : "text-black"}`}
                        onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                        <FontAwesomeIcon icon={faUser} className="me-2" />
                        {handleTheLanguageTranslation(label?.profile, "Profile")}
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider style={{ margin: 0 }} />
                    <DropdownItem>
                      <Link
                        to={"account/favourite-collection"}
                        className={`nav-link ${location.pathname.includes("/favourite-collection") ? "text-primary" : "text-black"}`}
                        onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                        <FontAwesomeIcon icon={faHeart} className="me-2" />
                        {handleTheLanguageTranslation(label?.favourite, "Favourite")}
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider style={{ margin: 0 }} />
                    <DropdownItem>
                      <Link
                        to={"/account/draft-collection"}
                        className={`nav-link ${location.pathname.includes("/draft-collection") ? "text-primary" : "text-black"}`}
                        onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                        <FontAwesomeIcon icon={faPenSquare} className="me-2" />
                        {handleTheLanguageTranslation(label?.draft, "Draft")}
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider style={{ margin: 0 }} />
                    <DropdownItem>
                      <Link to={"/invoice"} className={`nav-link ${location.pathname.includes("/invoice") ? "text-primary" : "text-black"}`} onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                        <FontAwesomeIcon icon={faFileInvoice} className="me-2" />
                        {handleTheLanguageTranslation(label?.invoices, "Invoices")}
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider style={{ margin: 0 }} />
                    <DropdownItem>
                      <Link
                        to={"/my-credits"}
                        className={`nav-link ${location.pathname.includes("/my-credits") ? "text-primary" : "text-black"}`}
                        onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                        <FontAwesomeIcon icon={faDollarSign} className="me-2" />
                        {handleTheLanguageTranslation(label?.myCredits, "My Credits")}
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider style={{ margin: 0 }} />

                    {!IsNftCollectionDisabled && (
                      <DropdownItem>
                        <Link
                          to={"/explore-collections"}
                          className={`nav-link ${location.pathname.includes("/explore-collections") ? "text-primary" : "text-black"}`}
                          onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                          <FontAwesomeIcon icon={faLayerGroup} className="me-2" />
                          {handleTheLanguageTranslation(label?.my_collections, "My collections")}
                        </Link>
                      </DropdownItem>
                    )}
                    <DropdownItem divider style={{ margin: 0 }} />
                    {!IsCustomNftDisabled && (
                      <DropdownItem>
                        <Link
                          to={"/archive-templates"}
                          className={`nav-link ${location.pathname.includes("/archive-templates") ? "text-primary" : "text-black"}`}
                          onClick={() => dispatch(onToggleHeader(!isHeaderOpen))}>
                          <FontAwesomeIcon icon={faArchive} className="me-2" />
                          {handleTheLanguageTranslation(label?.archive_templates, "Archive Templates")}
                        </Link>
                      </DropdownItem>
                    )}

                    <DropdownItem divider style={{ margin: 0 }} />
                    <DropdownItem>
                      <a href={process.env.REACT_APP_userProfile} target={"_blank"} className="nav-link text-black" onClick={() => dispatch(onToggleHeader(!isHeaderOpen))} rel="noreferrer">
                        <FontAwesomeIcon icon={faCog} className="me-2" />
                        {handleTheLanguageTranslation(label?.manage_edexa_account, "Manage edeXa account")}
                      </a>
                    </DropdownItem>
                    <DropdownItem divider style={{ margin: 0 }} />
                    <DropdownItem>
                      <span onClick={handleLogoutAPI} className="nav-link text-black">
                        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                        {handleTheLanguageTranslation(label?.logout, "Logout")}
                      </span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            )}
          </Nav>
        </Collapse>
      </Navbar>
      {searchBoxVisible && <SearchBox search={searchBoxVisible} setSearch={setSearchBoxVisible} />}
      <div className="bottom-element-wrapper">
        <ul>
          {handleExplore_SerachPermission() && (
            <>
              <li>
                <Link to={"/all-nfts"}>
                  <FontAwesomeIcon icon={faCompass} onClick={() => navigate("/all-nfts")} size={"lg"} className={` ${location.pathname.includes("/all-nfts") ? "text-primary" : "text-white"}`} />
                </Link>
              </li>
              <li>
                <Link to={"/explore"}>
                  <FontAwesomeIcon icon={faUserAlt} onClick={() => navigate("/explore")} className={`${location.pathname.includes("/explore") ? "text-primary" : "text-white"}`} />
                </Link>
              </li>
              <li>
                <Link to={"#"} onClick={() => setSearchBoxVisible(!searchBoxVisible)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                    <path
                      id="Vector"
                      d="M16.1,17a.889.889,0,0,1-.633-.262l-2.393-2.394A7.965,7.965,0,0,1,8.052,16.1,8.052,8.052,0,1,1,16.1,8.052a7.965,7.965,0,0,1-1.761,5.026l2.394,2.393A.9.9,0,0,1,16.1,17ZM8.052,1.789a6.263,6.263,0,1,0,6.263,6.263A6.27,6.27,0,0,0,8.052,1.789Z"
                      fill="#fff"
                    />
                  </svg>
                </Link>
              </li>
            </>
          )}
          {!handleExplore_SerachPermission() && (
            <li>
              <Link to="#" className="text-white" onClick={() => dispatch(setRequestDemoModal(true))}>
                <FontAwesomeIcon icon={faCalculator} />
              </Link>
            </li>
          )}
          {handleLoginPermisson() && (
            <li>
              <Link
                to={"/create-nft"}
                onClick={() => {
                  // navigate("/create-nft");
                  dispatch(resetCreateNFTAction());
                  dispatch(onToggleHeader(false));
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <path id="Path_375" data-name="Path 375" d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z" transform="translate(-4 -4)" fill="#fff" />
                </svg>
              </Link>
            </li>
          )}
          <li className="flag-drp">
            <Dropdown direction="up" isOpen={issOpen} toggle={Togggle}>
              <DropdownToggle tag="div" className="flag-item-main">
                <img src={languageType === "EN" ? enImg : deImg} className="img-fluid c-br-2" alt="flag" />
              </DropdownToggle>
              <DropdownMenu
                onClick={() => {
                  isLoggedIn &&
                    updateUserProfileMutation({
                      language: languageType === "EN" ? INTERNATIONALLANGUAGESENUM.GERMAN : INTERNATIONALLANGUAGESENUM.ENGLISH
                    });
                  dispatch(changeLanguage(languageType === "EN" ? INTERNATIONALLANGUAGESENUM.GERMAN : INTERNATIONALLANGUAGESENUM.ENGLISH));
                  setEncryptedLocalStorage(localStorageKeys.languageType, languageType === "EN" ? INTERNATIONALLANGUAGESENUM.GERMAN : INTERNATIONALLANGUAGESENUM.ENGLISH);
                }}>
                <DropdownItem tag="div" className="flag-item">
                  <img src={languageType === "EN" ? deImg : enImg} className="img-fluid c-br-2" alt="flag" />
                  <span className={`nav-link2 ${location.pathname === "/create-nft" ? "text-primary" : "c-tx-secondary"}`}>{languageType === "EN" ? "German" : "English"}</span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>
          <li>
            <Link to={"#"} onClick={scrollToTop}>
              {" "}
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path
                  id="Path_373"
                  data-name="Path 373"
                  d="M1.95,10.95a9,9,0,1,1,9,9A9,9,0,0,1,1.95,10.95Zm1.881,0A7.119,7.119,0,1,0,10.95,3.831,7.119,7.119,0,0,0,3.831,10.95Zm7.119-.493L8.884,12.466a.94.94,0,1,1-1.33-1.33L10.24,8.449a.94.94,0,0,1,1.33,0l2.687,2.687a.94.94,0,1,1-1.33,1.33Z"
                  transform="translate(-1.95 -1.95)"
                  fill="#fff"
                />
              </svg>
            </Link>
          </li>
        </ul>
      </div>
    </motion.div>
  );
};

export default React.memo(HeaderComponent);
