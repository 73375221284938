import { makeFullS3ObjectUrl } from "assets/images";
import { CloseButtonSvg } from "assets/images/close-button";
import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import { setAttachmentThumbnail } from "store/createNFT/action";
import { IRootReducer } from "store/root-reducer";
import { getBase64, handleTheLanguageTranslation, toastError } from "utils/functions/commonFunctions";
import { getMultipleThumbnailsOfVideoFile } from "utils/generate-video-thumbnails";
import "../../styles/components/ThumbnailSelectModal.scss";
const PhotoIcon = makeFullS3ObjectUrl("photo-icon.png");

const ThumbnailSelectModal = ({ isModalOpen, toggleThumbnailModal, videoFile }: IThumbnailSelectModalProps) => {
  const dispatch = useDispatch();

  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const [thumbnail, setThumbnail] = useState<string>();
  const [customThumbnail, setCustomThumbnail] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const [thumbnailsLoading, setThumbnailsLoading] = useState(true);
  const [activeImage, setActiveImage] = useState<number>();
  const [isError, setIsError] = useState("");

  useEffect(() => {
    if (typeof videoFile === "string" || videoFile?.type?.includes("video")) {
      setThumbnailsLoading(true);
      getMultipleThumbnailsOfVideoFile(videoFile, 4)
        .then((thumbs) => {
          setThumbnails(thumbs);
          setThumbnailsLoading(false);
          setIsError("");
        })
        .catch((err) => {
          setIsError(err);
          console.error(err);
        });
    }
  }, [videoFile]);
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);

  // if video file is not file type object then return null/empty
  // if (typeof videoFile === "string") return <></>;

  return (
    <Modal isOpen={isModalOpen} centered toggle={() => toggleThumbnailModal()} backdrop="static" scrollable={false} className="thumbnail-select-modal">
      <ModalBody>
        <div className="close-btn" onClick={() => toggleThumbnailModal()}>
          {CloseButtonSvg}
        </div>
        <div className="header">
          <h3>Custom thumbnail</h3>
          <p>Select or upload a picture that shows what's in your video. A good thumbnail stands out and draws viewers' attention.</p>
        </div>
        <div className="thumbnails">
          <div
            className="image custom text-center"
            onClick={() => {
              if (fileRef.current) {
                fileRef.current.click();
              }
            }}>
            {customThumbnail ? (
              <img src={thumbnail} alt="" />
            ) : (
              <div className="info">
                <img src={PhotoIcon} alt="" />
                <p className="mb-0">Upload thumbnail</p>
              </div>
            )}
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileRef}
              accept="image/*"
              onChange={(e) => {
                if (e.target && Boolean(e.target?.files?.length) && e.target?.files?.[0].type.includes("image")) {
                  getBase64(e.target.files[0]).then((image) => {
                    setThumbnail(image as string);
                    setCustomThumbnail(true);
                  });
                } else {
                  toastError("Only image file is accepted");
                }
              }}
            />
          </div>
          {!thumbnailsLoading && (
            <>
              {thumbnails.map((image, index) => {
                return (
                  <div
                    className={`image ${activeImage === index ? "active" : ""}`}
                    key={index}
                    onClick={() => {
                      setActiveImage(index);
                      setThumbnail(image);
                    }}>
                    <img src={image} alt="" />
                  </div>
                );
              })}
            </>
          )}
          {thumbnailsLoading &&
            Array(4)
              .fill(0)
              .map((item, index) => {
                return <Skeleton key={index + 500} height={120} width={120} count={1} baseColor="#f8f8f8" highlightColor="#ffffff" className="image" />;
              })}
        </div>
        {isError && (
          <div className="mt-4 c-tx-primary">
            <p>{isError}</p>
          </div>
        )}
        <div className="buttons">
          <Button
            className="custom-primary"
            onClick={() => {
              dispatch(setAttachmentThumbnail(thumbnail));
              toggleThumbnailModal();
            }}>
            {handleTheLanguageTranslation(label?.confirm, "Confirm")}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default React.memo(ThumbnailSelectModal);

interface IThumbnailSelectModalProps {
  isModalOpen: boolean;
  toggleThumbnailModal: Function;
  videoFile: File;
}
