import { makeFullS3ObjectUrl } from "assets/images";
import React from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { handleTheLanguageTranslation } from "utils/functions/commonFunctions";
const crossImg = makeFullS3ObjectUrl("search_cross.png");

const CustomNFTSaveTemplateConfirmationModal: React.FC<ICustomNFTConfirmationModal> = ({ open, setOpen, customNft, handleMint }) => {
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);

  return (
    <Modal isOpen={open} className={`${customNft?.templateId !== null && "create-nft-third-btn-modal"} custome-nft-modal `} centered>
      <ModalBody>
        <img src={crossImg} alt="cross_img" className="c-img" onClick={() => setOpen(!open)} />
        <h2 className="title-lr c-tx-secondary f-700 text-center main-title">{handleTheLanguageTranslation(label?.create_custom_nft, "Create Custom NFT")}</h2>
        <p className="c-tx-forth text-center c-p">
          {handleTheLanguageTranslation(label?.are_you_sure_you_want_to_save_this_custom_nft_as_template, "Are you sure you want to save this custom NFT as Template ?")}
        </p>
        <div className={` ${customNft?.templateId !== null ? "d-block d-md-flex" : "d-flex"} justify-content-between c-gap-20`}>
          <Button
            className="custom-grey dart-text w-btn-100 "
            onClick={() => {
              setOpen(false);
              handleMint(0);
            }}>
            {handleTheLanguageTranslation(label?.no_thanks, " No, Thanks")}
          </Button>
          {customNft?.templateId !== null && (
            <Button className="custom-primary my-2 my-md-0 w-btn-100" onClick={() => handleMint(1)}>
              {handleTheLanguageTranslation(label?.save_and_replace, "Save and replace")}
            </Button>
          )}
          <Button className="custom-primary w-btn-100" onClick={() => handleMint(2)}>
            {handleTheLanguageTranslation(label?.save_as_new, "Save as new")}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default React.memo(CustomNFTSaveTemplateConfirmationModal);

interface ICustomNFTConfirmationModal {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  customNft: any;
  handleMint: (temp: number) => void;
}
