import { useEffect } from "react";

const useLoadStripe = (publisherKey: string) => {
  useEffect(() => {
    (function () {
      if (!window.document.getElementById("stripe-script")) {
        var s = window.document.createElement("script");
        s.id = "stripe-script";
        s.type = "text/javascript";
        s.src = "https://js.stripe.com/v2/";
        s.onload = () => {
          //   we are adding the publisher key to stripe
          // @ts-ignore
          window["Stripe"].setPublishableKey(publisherKey);
        };
        window.document.body.appendChild(s);
      }
    })();
    // eslint-disable-next-line
  }, []);
  return null;
};

export default useLoadStripe;
