export const CloseButtonSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <path
      id="noun-cross-3869881"
      d="M104.334,23.332a11.013,11.013,0,1,0,7.774,3.226A11,11,0,0,0,104.334,23.332Zm4.242,13.829h0a1,1,0,1,1-1.414,1.414l-2.828-2.829-2.828,2.829a1,1,0,0,1-1.414-1.414l2.828-2.829L100.092,31.5a1,1,0,1,1,1.414-1.414l2.828,2.829,2.828-2.829a1,1,0,1,1,1.414,1.414l-2.828,2.829Z"
      transform="translate(-93.334 -23.332)"
      fill="#dadce0"
    />
  </svg>
);
