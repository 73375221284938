import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const appValidateCustomUrl = (params: { customUrl: string }): Promise<IValidateCustomUrlResult> => {
//   return HTTPService.get(apiEndPoints.app.validateCustomUrl, { params });
// };

const validateCustomUrlApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appValidateCustomUrl: build.query<IValidateCustomUrlResult, { customUrl: string }>({
      query: (params) => ({
        url: apiEndPoints.app.validateCustomUrl,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "appValidateCustomUrl", id: "appValidateCustomUrl" }]
    })
  })
});

export const { useAppValidateCustomUrlQuery, useLazyAppValidateCustomUrlQuery } = validateCustomUrlApiSlice;

export interface IValidateCustomUrlResult {
  status: 409 | 200 | number;
  message: string;
}
