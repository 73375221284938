import { SETFAVOURITECLICK, SETFAVOURITECOUNT } from "./type";

const initialState: IFavouriteCountReducer = {
  isLiked: false,
  totalLikes: 0
};

const favouriteClickReducer = (state = initialState, action: { type: string; payload?: IFavouriteCountReducer }) => {
  let newState: any = { ...state };
  switch (action.type) {
    case SETFAVOURITECOUNT: {
      newState = action?.payload;
      break;
    }
    case SETFAVOURITECLICK: {
      if (!newState.isLiked) {
        newState.totalLikes += 1;
      } else {
        newState.totalLikes -= 1;
      }
      newState.isLiked = !newState.isLiked;
      break;
    }
  }
  return newState;
};

export interface IFavouriteCountReducer {
  totalLikes: number;
  isLiked: boolean;
}

export { favouriteClickReducer };
