import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const appChangeNFTVisibility = (params: { id: string }): Promise<{ status: number; message: string }> => {
//   return HTTPService.get(apiEndPoints.app.ChangeAPIVisibility, { params });
// };

const nftVisibilityApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appChangeNFTVisibility: build.query<{ status: number; message: string }, { id: string }>({
      query: (params) => ({
        method: "get",
        url: apiEndPoints.app.ChangeAPIVisibility,
        params: params
      })
    })
  })
});

export const { useAppChangeNFTVisibilityQuery, useLazyAppChangeNFTVisibilityQuery } = nftVisibilityApiSlice;
