import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { IRootReducer } from "store/root-reducer";

const PrivateNFTIcon = ({ visibility = 0 }) => {
  const { isLoggedIn } = useSelector((state: IRootReducer) => state.auth);
  return isLoggedIn && visibility === 1 ? (
    <div className="favorite-ic-block" role={"button"} onClick={() => {}} title="This NFTs visibility is set to private, to change this go to NFT detail page">
      <FontAwesomeIcon icon={faLock} />
    </div>
  ) : (
    <></>
  );
};

export default PrivateNFTIcon;
