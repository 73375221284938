/**
 * @description - This decorator will remove the empty properties from the params object
 * @objective - To send only the parameters to api which are having values [no empty params to api calls]
 *
 * @working
 * - It will be used as decorator to a class' function and will intercept its parameters
 * - Once it receive the parameters of that function it will modify them and sent them back.
 *  ex. input - ["api/explore", { params: {page: 0, search: ""} }]
 *      output - ["api/explore", { params: {page: 0} }]
 * BTW this is not that simple as I wrote about its just an example.
 *
 * @requires - the function of the class should accept a parameter with the name : `params` and it should be an object
 *
 * @limitations - it can only work for single level of objects depth with accuracy.
 *
 * @note - this decorator is specially designed to work with the current get function of httpService.ts file
 * To use it elsewhere you should modify the decorator as per need
 *
 * @references
 * - https://stackoverflow.com/questions/52105906
 * - https://www.geeksforgeeks.org/what-are-decorators-and-how-are-they-used-in-javascript
 * -
 */
export const removeEmptyParameters = (): MethodDecorator => {
  return (target: Object, propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = (...args: any[]) => {
      // remove all the values of the params which are empty
      Object.keys(args).forEach((i) => {
        //   if the argument dont have params object nothing will happen to actual params
        if (args?.[1]?.params) {
          args[1].params = Object.fromEntries(Object.entries(args[1].params).filter(([key, value]) => !!value));
        }
      });

      return originalMethod.apply(null, args);
    };

    return descriptor;
  };
};
