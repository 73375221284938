import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const appShiftNft = (params: INftShiftRequest): Promise<INftShiftReturn> => {
//   return HTTPService.put(apiEndPoints.app.shiftNft, params);
// };
const appShiftNftApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appShiftNft: build.mutation<INftShiftReturn, INftShiftRequest>({
      query: (data) => ({
        url: apiEndPoints.app.shiftNft,
        data: data,
        method: "put"
      })
    })
  })
});

export const { useAppShiftNftMutation } = appShiftNftApiSlice;

interface INftShiftRequest {
  id: string[];
  collectionId: string;
}

interface INftShiftReturn {
  status: number;
  message: string;
}
