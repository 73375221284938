/* eslint-disable import/order */
// the configurestore import should alwasy come after the nftcentralrtq service otherwise it will throw error
import { configureStore } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { reactToolKitAxiosBaseQuery } from "utils/httpService";
import rootReducer from "./root-reducer";

export const nftCentralRTQApiService = createApi({
  reducerPath: "nftCentralRTQApiService",
  baseQuery: reactToolKitAxiosBaseQuery({
    baseUrl: process.env.REACT_APP_APIBASEURL || ""
  }),
  tagTypes: [
    "getLanguagesJson",
    "appExploreNFTs",
    "FavouriteNftListAPI",
    "appGetCollectionList",
    "appGetSingleCollection",
    "getCreditCardListAPI",
    "getDrafAPIQuery",
    "getArchiveTemplatesAPI",
    "appSetFavouriteOrUnfavouriteNFT",
    "appGetNftList",
    "appGetNft",
    "getTemplateListAPI",
    "getTemplateAPI",
    "appGetTopUsers",
    "appGetUserProfile",
    "gatewayMintPrice",
    "mintPriceList",
    "getSearchApi",
    "updateArchiveAPI",
    "appValidateCustomUrl",
    "getUserInfo",
    "getUserPlanInfo",
    "getNftCategoryList",
    "getInvoiceListAPIQuery",
    "downloadInvoiceAPIQuery",
    "getCreditCardListAPI"
  ],
  keepUnusedDataFor: 60, // default cache time for a query result
  endpoints: (builder) => ({})
});

const store = configureStore({
  // @ts-ignore
  reducer: {
    ...rootReducer,
    [nftCentralRTQApiService.reducerPath]: nftCentralRTQApiService.reducer
  },
  devTools: process.env.REACT_APP_ENV === "DEVELOPMENT" || false,
  //   @todo - enable the serializable check and fix redux states and actions
  // main issue was because in some of the reducer we are storing a function reference.
  // @ts-ignore
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
      thunk: {
        extraArgument: nftCentralRTQApiService
      }
    }).concat(nftCentralRTQApiService.middleware)
  ]
});

export default store;
