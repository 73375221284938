import { ILanguageJson } from "./module";
import { CHANGELANGUAGE, INTERNATIONALLANGUAGESENUM, SETLANGUAGESJSON } from "./type";

export const changeLanguage = (payload: INTERNATIONALLANGUAGESENUM) => {
  return {
    type: CHANGELANGUAGE,
    payload
  };
};
export const setLanguagesJson = (payload: ILanguageJson) => {
  return {
    type: SETLANGUAGESJSON,
    payload
  };
};
