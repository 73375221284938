import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const appDeleteDraft = (params: { id: string }) => {
//   return HTTPService.deleteWithParams(apiEndPoints.app.DeleteDraft, { params });
// };

const deleteNftDraftApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appDeleteDraft: build.mutation<void, { id: string }>({
      query: (params) => ({
        url: apiEndPoints.app.DeleteDraft,
        method: "delete",
        params: params
      })
    })
  })
});

export const { useAppDeleteDraftMutation } = deleteNftDraftApiSlice;
