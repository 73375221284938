import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const appGetSingleCollection = (params: { url: string }): Promise<ISingleCollectionReturn> => {
//   return HTTPService.get(apiEndPoints.app.getCollection, { params });
// };

const singleCollectionApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appGetSingleCollection: build.query<ISingleCollectionReturn, { url: string }>({
      query: (params) => ({
        url: apiEndPoints.app.getCollection,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "appGetSingleCollection", id: "appGetSingleCollection" }]
    })
  })
});

export const { useAppGetSingleCollectionQuery, useLazyAppGetSingleCollectionQuery } = singleCollectionApiSlice;

export interface ICollectionSocialLinks {
  twitter: string;
  facebook: string;
  youtube: string;
  website: string;
  instagram: string;
}
export interface ICollectionNFT {
  compressedFilePath: string;
  createdAt: string;
  createdBy: string;
  description: string;
  fileMimeType: string;
  filePath: string;
  fileThumbPath: string;
  fileType: string;
  isCustom: number;
  isLiked: false;
  nftId: number;
  tags: string[];
  title: string;
  totalLikes: number;
  totalViews: number;
  _id: string;
}
export interface ICollection {
  _id: string;
  name: string;
  description: string;
  bannerImage: string;
  featuredImage: string;
  logoImage: string;
  customUrl: string;
  nfts: ICollectionNFT[];
  links: ICollectionSocialLinks;
  categoryId: string;
  totalNFTs: number;
  createdBy: string;
}

export interface ISingleCollectionReturn {
  status: number;
  message: string;
  data: ICollection;
}
