import {
  ISingleCollectionReturn,
  useLazyAppGetSingleCollectionQuery
} from 'actions/app/get-collection-single'
import { makeFullS3ObjectUrl } from 'assets/images'
import CardLoadingSkelton from 'components/CardLoadingSkelton'
import CollectionPreviewCounters from 'components/collectionPreview/CollectionPreviewCounters'
import CollectionPreviewInfoCard from 'components/collectionPreview/CollectionPreviewInfoCard'
import CollectionPreviewOverview from 'components/collectionPreview/CollectionPreviewOverview'
import GlobalHelmetProvider from 'components/GlobalHelmetProvider'
import GlobalLoadMoreButton from 'components/GlobalLoadMoreButton'
import { NftDetailCard } from 'components/nftdetail/NftDetailCard'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, Col, Container, Row } from 'reactstrap'
import { IRootReducer } from 'store/root-reducer'
import { paginationKeysObject } from 'utils/constants/constants'
import {
  handleTheLanguageTranslation,
  toastError
} from 'utils/functions/commonFunctions'
const ProfileBanner = makeFullS3ObjectUrl('profile-banner.png')

const CollectionPreviewPage = () => {
  const { collectionUrl } = useParams() || {}
  const { user_id: loggedInUserId } = useSelector(
    (state: IRootReducer) => state.userDetailsReducer
  )
  const { isLoggedIn } = useSelector(
    (state: IRootReducer) => state.auth
  )
  const navigate = useNavigate()
  const [isAPILoading, setIsAPILoading] = useState(false)
  const [loading, setLoading] = useState(true)

  const [collectionDetails, setCollectionDetails] =
    useState<ISingleCollectionReturn>()

  // collection api operations starts from here
  const [collectionPagination, setCollectionPagination] = useState({
    ...paginationKeysObject,
    url: collectionUrl
  })
  // collection mutation
  const [getSingleCollectionQuery] =
    useLazyAppGetSingleCollectionQuery()
  const getCollectionDetails = useCallback(() => {
    if (collectionUrl) {
      setIsAPILoading(true)
      getSingleCollectionQuery({
        ...collectionPagination,
        url: collectionUrl
      }).then((res) => {
        if ('data' in res && res?.data && res?.data?.status === 200) {
          setLoading(false)
          setIsAPILoading(false)
          setCollectionDetails(res?.data)
        }
        if ('error' in res && res?.error) {
          toastError(
            (res.error as { data: { message: string } })?.data.message
          )
          if ((res.error as { status: number }).status === 404) {
            navigate('/404')
          }
        }
      })
    }
  }, [collectionPagination, collectionUrl, getSingleCollectionQuery])

  useEffect(() => {
    getCollectionDetails()
    // window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionUrl, collectionPagination])

  const loadMoreData = useCallback(() => {
    setCollectionPagination({
      ...collectionPagination,
      limit: collectionPagination.limit + collectionPagination.limit
    })
  }, [collectionPagination])

  // check if the collection is of the current user
  const checkIfLoggedInUserIsTheOwnerOfThisCollection =
    useCallback(() => {
      // return false if not loggedin at first
      if (!isLoggedIn) return false

      // ask prasham to add user id to collection detail page
      return loggedInUserId === collectionDetails?.data.createdBy
        ? true
        : false
    }, [
      collectionDetails?.data.createdBy,
      isLoggedIn,
      loggedInUserId
    ])

  // nft tab buttons
  const [activeTab, setActiveTab] = useState({
    nft: true,
    overview: false
  })
  const label = useSelector(
    (state: IRootReducer) =>
      state.languagesJsonReducer.currentLanguage.json
  )
  const gliderRef = useRef<HTMLSpanElement>(null)

  const buttonsRef = useRef<HTMLButtonElement[]>([])
  const [currentButtonRefIndex, setCurrentButtonRefIndex] =
    useState(0)

  const handleThePositionOfWhiteGlider = (e: React.MouseEvent) => {
    if (gliderRef.current) {
      gliderRef.current.style.width = `${Math.floor(
        (e.target as Element)?.getBoundingClientRect().width
      )}px`
      gliderRef.current.style.transform = `translateX(${Math.floor(
        (e.target as HTMLElement)?.offsetLeft - 10
      )}px)`
    }
  }

  useEffect(() => {
    if (buttonsRef.current.length > 0) {
      try {
        if (buttonsRef.current[currentButtonRefIndex] !== null) {
          const triggerClickEvent = (e: MouseEvent) => {
            handleThePositionOfWhiteGlider(
              e as unknown as React.MouseEvent
            )
          }
          buttonsRef.current[currentButtonRefIndex]?.addEventListener(
            'click',
            (e) => triggerClickEvent(e)
          )
          buttonsRef.current[currentButtonRefIndex]?.click()

          setTimeout(() => {
            buttonsRef.current[
              currentButtonRefIndex
            ]?.removeEventListener('click', (e) =>
              triggerClickEvent(e)
            )
          }, 3000)
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label])

  return (
    <>
      <GlobalHelmetProvider
        title={`${collectionDetails?.data.name}` || 'Account'}
        thumbnail={collectionDetails?.data.bannerImage}
        description={collectionDetails?.data.description}
      />
      <div className="profile-wrapper">
        <div className="profile-banner image-loader">
          {collectionDetails?.data?.bannerImage ? (
            <img
              src={collectionDetails?.data?.bannerImage}
              alt="profile-banner"
            />
          ) : (
            <img src={ProfileBanner} alt="profile-banner" />
          )}
          {!isAPILoading &&
            isLoggedIn &&
            checkIfLoggedInUserIsTheOwnerOfThisCollection() && (
              <Link
                to={`../../explore-collections/${collectionUrl}`}
                state={{ modalOpen: true }}
                className="capsual-block edit-bl text-decoration-none"
                role={'button'}
              >
                {handleTheLanguageTranslation(
                  label?.edit_collection,
                  'Edit Collection'
                )}
              </Link>
            )}
        </div>
        <Container>
          <div className="profile-detail-section">
            <Row className="justify-content-center">
              <Col md="12" sm="12" lg="6">
                <CollectionPreviewInfoCard
                  collectionDetails={collectionDetails}
                  isLoggedIn={isLoggedIn}
                  loading={loading}
                  isAPILoading={isAPILoading}
                  checkIfRequestIsForCurrentUser={
                    checkIfLoggedInUserIsTheOwnerOfThisCollection
                  }
                />
              </Col>
              <Col
                md="12"
                sm="12"
                lg="6"
                className="justify-content-end"
              >
                <CollectionPreviewCounters
                  collectionDetails={collectionDetails}
                  isLoggedIn={isLoggedIn}
                  loading={loading}
                  checkIfRequestIsForCurrentUser={
                    checkIfLoggedInUserIsTheOwnerOfThisCollection
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                {collectionDetails?.data && isLoggedIn && (
                  <div className="tab-container d-flex align-items-center">
                    <Button
                      ref={(e) =>
                        (buttonsRef.current![0] =
                          e as unknown as HTMLButtonElement)
                      }
                      onClick={(e) => {
                        setActiveTab({
                          nft: true,
                          overview: false
                        })

                        handleThePositionOfWhiteGlider(e)
                        setCurrentButtonRefIndex(0)
                      }}
                      style={{ minWidth: 130 }}
                      className={`${activeTab.nft && 'active'} btn1`}
                    >
                      NFT
                      {`(${
                        (
                          collectionDetails?.data
                            .nfts as unknown as []
                        )?.length
                      })`}
                    </Button>
                    <Button
                      ref={(e) =>
                        (buttonsRef.current![1] =
                          e as unknown as HTMLButtonElement)
                      }
                      onClick={(e) => {
                        setActiveTab({
                          nft: false,
                          overview: true
                        })
                        handleThePositionOfWhiteGlider(e)
                        setCurrentButtonRefIndex(1)
                      }}
                      style={{ minWidth: 183 }}
                      className={`${
                        activeTab.overview && 'active'
                      } btn2`}
                    >
                      {handleTheLanguageTranslation(
                        label?.overview,
                        'Overview'
                      )}
                    </Button>
                    <span className="glider" ref={gliderRef}></span>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="justify-content-center">
              {activeTab.nft && (
                <Col md="12" sm="12" lg="12">
                  <Row>
                    {!loading ? (
                      collectionDetails !== undefined &&
                      collectionDetails?.data?.nfts?.length > 0 ? (
                        collectionDetails?.data.nfts?.map(
                          (nftItem, index) => {
                            return (
                              <Col
                                md="6"
                                lg="4"
                                sm="6"
                                key={nftItem._id}
                              >
                                <NftDetailCard
                                  artName={nftItem.title}
                                  views={nftItem.totalViews}
                                  likes={nftItem.totalLikes}
                                  createdDate={nftItem.createdAt}
                                  nftArtURL={
                                    nftItem.isCustom
                                      ? nftItem.filePath
                                      : nftItem.compressedFilePath
                                  }
                                  nftId={nftItem._id}
                                  isFavourite={nftItem.isLiked}
                                  thumbnailUri={
                                    nftItem.isCustom
                                      ? nftItem.filePath
                                      : nftItem.fileThumbPath
                                  }
                                  fileMimeType={nftItem.fileMimeType}
                                  isCustom={nftItem.isCustom}
                                />
                              </Col>
                            )
                          }
                        )
                      ) : (
                        <Col>
                          <p className="text-center c-tx-third">
                            No NFTs found
                          </p>
                        </Col>
                      )
                    ) : (
                      Array(6)
                        .fill(0)
                        .map((item, index) => {
                          return (
                            <Col md="6" lg="4" sm="6" key={index}>
                              <CardLoadingSkelton />
                            </Col>
                          )
                        })
                    )}
                  </Row>

                  <Row>
                    <Col className="justify-content-center d-flex">
                      <GlobalLoadMoreButton
                        dataCount={collectionDetails?.data.totalNFTs}
                        isLoading={isAPILoading}
                        listData={collectionDetails?.data.nfts}
                        loadMoreData={loadMoreData}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              {activeTab.overview && (
                <Col md="12" sm="12" lg="12">
                  <CollectionPreviewOverview
                    collectionData={collectionDetails}
                  />
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CollectionPreviewPage
