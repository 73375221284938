import { faFilePdf, faFileWord, faPauseCircle, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeFullS3ObjectUrl } from "assets/images";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMediaLoading } from "store/loader/action";
import { IRootReducer } from "store/root-reducer";
import { removeCurrentPlayingVideoId, setCurrentPlayingVideoId } from "store/video/action";
import { ObjectOfFileMimeTypes, SecondsToMinutesAndSeconds, handleTheLanguageTranslation } from "utils/functions/commonFunctions";
const errorImage = makeFullS3ObjectUrl("art.png");

/**
 *
 * @param {string} mediaUri  File URL of nft
 * @param {string} nftId  The id of the nft
 * @param {string} artName  The name of the nft
 * @returns a media tag `<img/>`, `<video>`, `iframe`
 * @example
 * Input - "https://domainname.io/profile.jpg"
 * Output - <img src={mediaUri} className="img-fluid" alt="banner" />
 *
 * @todo
 * First and foremost Refactor this ugly component
 * 1. handle all major types of video format. [restrict video upload to certian types]
 * 2. handle all major image types. [restrict user to upload certain type images]
 * 3. handle document format also. [`.doc`, `.docx` etc]
 */
interface IRenderMediaPropsType {
  mediaUri: string | undefined;
  nftId: string | undefined;
  artName?: string | undefined;
  thumbnailUri?: string | undefined;
  fileMimeType: string;
  detailPage?: boolean;
  videoDuration?: number;
}
const RenderRespectiveMediaTag = ({ mediaUri, nftId, artName, thumbnailUri, fileMimeType, detailPage, videoDuration }: IRenderMediaPropsType) => {
  const dispatch = useDispatch();
  const videoElementRef = useRef<HTMLVideoElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [playPauseButtonVisibility, setPlayPauseButtonVisibility] = useState({
    play: true,
    pause: false
  });
  const [videoLoading, setVideoLoading] = useState(false);
  const [videoPlayTime, setVideoPlayTime] = useState(0);
  const [error, setError] = useState(false);

  const { videoId } = useSelector((state: IRootReducer) => state.videoPlayerStateReducer);

  const handleVideoPlay = (mouseEvent: React.MouseEvent<HTMLVideoElement, MouseEvent>, page?: string) => {
    if (page === "detail") {
      if ((mouseEvent.target as HTMLVideoElement).paused) {
        (mouseEvent.target as HTMLVideoElement).play();
        setPlayPauseButtonVisibility({ play: false, pause: true });
        setVideoLoading(true);
      } else {
        (mouseEvent.target as HTMLVideoElement).pause();
        setPlayPauseButtonVisibility({ play: true, pause: false });
      }
    } else {
      if (mouseEvent.type === "mouseleave") {
        (mouseEvent.target as HTMLVideoElement).pause();
        return null;
      }

      if ((mouseEvent.target as HTMLVideoElement).paused) {
        (mouseEvent.target as HTMLVideoElement).play();
        // setTimeout(() => {
        //   (mouseEvent.target as HTMLVideoElement).muted = false;
        // }, 0);
        // setPlayPauseButtonVisibility({ play: false, pause: true });
      } else {
        (mouseEvent.target as HTMLVideoElement).pause();
        // setPlayPauseButtonVisibility({ play: true, pause: false });
      }
    }
  };

  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);

  // if current playing video id in redux store is equal to current nft id of this component then
  // hide the play button and display pause button of current card component
  // because some other video is playing
  useEffect(() => {
    if (videoId && videoId !== nftId) {
      setPlayPauseButtonVisibility({ play: true, pause: false });
      videoElementRef.current?.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);

  const updateVideoTimeStamp = () => {
    setVideoPlayTime(videoElementRef.current!.currentTime);
  };

  let element;

  if (!fileMimeType || !nftId) return <></>;
  if (
    fileMimeType?.includes(ObjectOfFileMimeTypes.APPLICATION.PDF) ||
    fileMimeType === ObjectOfFileMimeTypes.APPLICATION.DOC ||
    fileMimeType?.includes(ObjectOfFileMimeTypes.APPLICATION.OCTETSTREAM) ||
    fileMimeType?.includes(ObjectOfFileMimeTypes.APPLICATION.DOCX)
  ) {
    element = (
      <div
        className="pdf w-100 h-100 text-center position-relative"
        style={{ background: "#2c2c2b" }}
        role={mediaUri ? "button" : ""}
        onClick={() => {
          if (!mediaUri) return null;
          window.open(`https://docs.google.com/gview?embedded=true&url=${mediaUri}`);
        }}>
        <FontAwesomeIcon
          icon={fileMimeType?.includes(ObjectOfFileMimeTypes.APPLICATION.PDF) ? faFilePdf : faFileWord}
          className="h2 c-tx-third flex-grow-1"
          style={
            detailPage
              ? {
                  fontSize: 120,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
                }
              : {
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
                }
          }
        />
        <p className="mb-n4 c-tx-third w-100" style={{ position: "absolute", bottom: 50, fontSize: 16 }}>
          {handleTheLanguageTranslation(label?.click_here_for_preview, "Click here for Preview")}
        </p>
      </div>
    );
  } else if (fileMimeType?.includes(ObjectOfFileMimeTypes.VIDEO)) {
    element = (
      <div className="position-relative h-100 video-itself">
        <span className="position-absolute bottom-0 end-0 badge-explorer-card">
          {videoDuration ? SecondsToMinutesAndSeconds(videoDuration - videoPlayTime) : SecondsToMinutesAndSeconds(videoPlayTime)}
        </span>

        {detailPage && (
          <>
            {" "}
            <FontAwesomeIcon
              icon={faPlayCircle}
              className="play-button position-absolute text-primary top-50 start-50 h2 opacity-75"
              style={{
                display: playPauseButtonVisibility.play ? "block" : "none",
                transform: "translate(-50%, -59%)"
              }}
            />
            <FontAwesomeIcon
              icon={faPauseCircle}
              className={`pause-button position-absolute text-secondary top-50 start-50 h2 bg-transparent ${videoLoading ? "pulse" : ""} `}
              style={{
                display: playPauseButtonVisibility.pause ? "block" : "none",
                transform: "translate(-50%, -59%)"
              }}
            />
          </>
        )}
        <video
          autoPlay={false}
          ref={videoElementRef}
          loop
          preload="none"
          title={artName}
          className="img-fluid shadow-lg rounded"
          style={{
            cursor: "pointer",
            objectFit: "cover",
            height: "100%",
            width: "100%"
          }}
          muted={false}
          onMouseEnter={(event) => {
            // if its a detail page then do not perform mouse enter operation
            if (detailPage) return null;
            handleVideoPlay(event);
          }}
          onMouseLeave={(event) => {
            // if its a detail page then do not perform mouse enter operation
            if (detailPage) return null;
            handleVideoPlay(event);
          }}
          onClick={(event) => {
            // if its a detail page then it will show play pause icons
            detailPage && handleVideoPlay(event, "detail");
          }}
          onLoad={() => {
            dispatch(setMediaLoading(false));
            setVideoLoading(false);
          }}
          onTimeUpdate={(e) => {
            updateVideoTimeStamp();
          }}
          onPlay={(e) => dispatch(setCurrentPlayingVideoId((e.target as HTMLVideoElement).id))}
          onPause={(e) => dispatch(removeCurrentPlayingVideoId())}
          poster={thumbnailUri}>
          <source src={`${mediaUri}`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  } else if (fileMimeType?.includes(ObjectOfFileMimeTypes.IMAGE)) {
    element = (
      <img
        src={error ? errorImage : thumbnailUri || mediaUri}
        className={`img-fluid ${error && "error-explore"}`}
        alt={artName}
        loading="lazy"
        title={artName}
        onLoad={() => dispatch(setMediaLoading(false))}
        onError={() => setError(true)}
      />
    );
  } else {
    element = <div className="img-fluid" />;
  }
  return element;
};

export default RenderRespectiveMediaTag;
