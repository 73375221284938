import { nftCentralRTQApiService } from "store";
import { INTERNATIONALLANGUAGESENUM } from "store/internationalization/type";
import { apiEndPoints } from "utils/constants";

const extendedApi = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    getLanguagesJson: build.query<IMultiLanguageLabelsJson, void>({
      query: () => ({ method: "get", url: apiEndPoints.admin.GetLabels }),
      providesTags: [{ type: "getLanguagesJson", id: "getLanguagesJson" }]
    })
  }),
  overrideExisting: false
});

export const { useGetLanguagesJsonQuery, useLazyGetLanguagesJsonQuery } = extendedApi;

export interface IMultiLanguageLabelsJson {
  status: number;
  message: string;
  data: {
    _id: string;
    [INTERNATIONALLANGUAGESENUM.ENGLISH]: any;
    [INTERNATIONALLANGUAGESENUM.GERMAN]: any;
    createdAt: string;
    updatedAt: string;
  };
}
