import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { INftListReturn } from "actions/app/get-nft-list";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { handleEmail } from "utils/functions/commonFunctions";
import UserImage from "../common/Image";
export interface IUserProfileCardPropsType {
  loading: boolean;
  isAPILoading: boolean;
  isLoggedIn: boolean;
  userNftsList: INftListReturn | undefined;
  checkIfRequestIsForCurrentUser: Function;
  toggleBioModal: Function;
}

const UserProfileDetailCard = ({ loading, userNftsList, isAPILoading, isLoggedIn, checkIfRequestIsForCurrentUser, toggleBioModal }: IUserProfileCardPropsType) => {
  return (
    <div className="profile-inner-wrapper">
      {/* <div className="menu">
        <p className="c-tx-forth">
          {!isAPILoading && isLoggedIn && !checkIfRequestIsForCurrentUser() && <FontAwesomeIcon icon={faEdit} className="text-primary ms-1" role={"button"} onClick={() => toggleBioModal()} />}
          {isAPILoading && <Skeleton height={20} width={20} circle />}
        </p>
      </div> */}
      <div className="img-wrapper image-loader overflow-hidden">
        <UserImage src={userNftsList?.user?.cropProfilePicture || userNftsList?.user?.cropProfilePicture} className="img-fluid rounded-circle" />
      </div>
      <div className="second-title-wrapper">
        <div className="capsual-block c-bg-primary">{/* <span className="text-capitalize text-white">Collection</span> */}</div>
        <h2 className="sub-title text-white f-700 mb-0 text-break">
          {userNftsList?.user?.name ? userNftsList?.user?.name : userNftsList?.user?.email && handleEmail(userNftsList?.user?.email)}
          {loading && <Skeleton width="100%" height={20} />}
        </h2>
        <h4 className="c-tx-primary f-700 ">
          <span className="text-break">{userNftsList?.user?.username && `@${userNftsList?.user?.username}`}</span>
          <span className="c-tx-primary ms-1">{userNftsList?.user?.isVerified === 1 && <FontAwesomeIcon icon={faCheckCircle} />}</span>
          {loading && <Skeleton width={100} height={20} />}
        </h4>

        {/* <>
          {userNftsList?.user && <h4 className="c-tx-secondary mb-0 cb-15 lh-16">Bio</h4>}
          {loading && <Skeleton width={60} height={10} />}
          <p className="c-tx-forth c-mb-30 text-break">
            {userNftsList?.user?.bio}
            {loading && <Skeleton width="100%" height={100} />}
          </p>
        </> */}
        {/* 
        {userNftsList?.user && isLoggedIn && !checkIfRequestIsForCurrentUser() && (
          <>
            <h4 className="c-tx-secondary mb-0 cb-15 lh-16">Wallet address </h4>
            {loading && <Skeleton width={60} height={10} />}
            <p className="c-tx-forth c-mb-30 text-break">
              {userNftsList?.user?.walletAddress || "Add your wallet address to see your NFTs"}
              {loading && <Skeleton width="100%" height={10} />}
            </p>
          </>
        )} */}

        {/* {userNftsList?.user?.links && (
          <>
            {" "}
            <h4 className="c-tx-secondary mb-0 cb-15 lh-16">Links</h4>
            <Link to={"/"} className="c-tx-primary p custom-link darlk ">
            </Link>
          </>
        )}
        {userNftsList?.user && (
          <>
            <h4 className="c-tx-secondary mb-0 cb-15 c-mt-30 lh-16">Joined</h4>
            <p className="c-tx-forth mb-0 lh-16">{getFormattedDateWithMonthName(userNftsList?.user?.createdAt || "")}</p>
          </>
        )} */}
        {loading && (
          <>
            <h4 className="c-tx-secondary mb-0 cb-15 c-mt-30 lh-16">
              <Skeleton width={150} height={10} />
            </h4>
            <p className="c-tx-forth mb-0 lh-16">
              <Skeleton width={60} height={10} />
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(UserProfileDetailCard);
