import { makeFullS3ObjectUrl } from "assets/images";
import GlobalHelmetProvider from "components/GlobalHelmetProvider";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
const UnderConstruction = makeFullS3ObjectUrl("under-maintanance.png");
const UnderMaintenancePage = () => {
  const [requestDemo, setRequestDemo] = useState(false);
  const navigate = useNavigate();
  const { underMaintenance } = useSelector((state: IRootReducer) => state.globalSettingReducer);
  // useEffect(() => {
  //   if (!underMaintenance) {
  //     navigate("/");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [underMaintenance]);
  return (
    <>
      <GlobalHelmetProvider title="Site under maintenance" thumbnail={UnderConstruction} description="Sorry, we're down for scheduled maintenance right now." />
      {/* <div className="header-wrapper ">
        <Navbar color="transparent" expand="md" light>
          <Link to="#" className="logo-text navbar-brand">
            <span>edeXa</span>
            <span className="f-600">NFTs</span>
          </Link>
        </Navbar>
      </div> */}

      <div className="section-margin-y mx-auto d-flex align-items-center justify-content-center flex-column under-maintanance-wrapper container">
        <Row className="align-items-center">
          <Col sm="12" className="text-center">
            {/* <h2 className="title-lr text-white f-700 text-center main-title">Under Maintenance</h2> */}
            <div className="image-wrapper mb-xl-5 mb-4">
              <img src={UnderConstruction} className="img-fluid" alt="Under Construction" />
            </div>
            <div className="helper-message text-white d-flex flex-column c-gap-20">
              <h3 className="mb-3">We are under maintenance</h3>
              <p>Will be back soon!</p>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-wrap">
              <div className="c-tx-forth">
                <a href="https://edexa.network/contact" target="_blank" rel="noreferrer">
                  Get in touch
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UnderMaintenancePage;
