import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const appRemoveNftsFromCollection = (params: INftRemoveFromCollectionRequest): Promise<INftRemoveFromCollectionReturn> => {
//   return HTTPService.put(apiEndPoints.app.removeNftsFromCollection, params);
// };

const removeNftsFromCollectionApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appRemoveNftsFromCollection: build.mutation<INftRemoveFromCollectionReturn, INftRemoveFromCollectionRequest>({
      query: (data) => ({
        url: apiEndPoints.app.removeNftsFromCollection,
        data: data,
        method: "put"
      })
    })
  })
});

export const { useAppRemoveNftsFromCollectionMutation } = removeNftsFromCollectionApiSlice;

interface INftRemoveFromCollectionRequest {
  id: string[];
  collectionId: string;
}

interface INftRemoveFromCollectionReturn {
  status: number;
  message: string;
}
