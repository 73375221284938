import { mixerIcon } from "assets/images/mixerIcon";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { handleTheLanguageTranslation } from "utils/functions/commonFunctions";

const CustomNFTIcon = ({ visibility = 0 }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);

  return visibility === 1 ? (
    <div className="favorite-ic-block" role={"button"} onClick={() => {}} title={handleTheLanguageTranslation(label?.custom_nft, "Custom NFT")} id="custom-nft">
      {mixerIcon}
      <Tooltip placement="top" isOpen={tooltipVisible} target="custom-nft" toggle={() => setTooltipVisible(!tooltipVisible)}>
        {handleTheLanguageTranslation(label?.custom_nft, "Custom NFT")}
      </Tooltip>
    </div>
  ) : (
    <></>
  );
};

export default CustomNFTIcon;
