import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

const addWalletCreditsApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    addWalletCredits: build.mutation<ICustomNft, IWalletCredits>({
      query: (data) => ({
        url: apiEndPoints.wallet.AddCredit,
        method: "post",
        data: data
      })
    })
  })
});

export const { useAddWalletCreditsMutation } = addWalletCreditsApiSlice;

export enum WALLET_PAYMENT_PLATFORM {
  IPHONE = "0",
  ANDROID = "1",
  WEB = "2"
}
interface IWalletCredits {
  //  "0" ( iphone ) or "1" ( android ), "2" ( web )
  type: WALLET_PAYMENT_PLATFORM;
  // _id from plan listing get type 3 or 4(type 3 for stripe and type 4 for paypal )
  planId: string;
  // token is required only if payment is through stripe
  token?: string;
  //  price = (must be greater than 0 and only 2 digits are acceptable in decimal )
  price: number;
}

export interface ICreateCustomNftResponse {}

export interface ICustomNft {
  status: number;
  message: string;
  count: number;
  data: ICreateCustomNftResponse;
}
