import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";
import HTTPService from "utils/httpService";

export const appGetUserProfile = (): Promise<IUserProfileOverview> => {
  return HTTPService.get(apiEndPoints.app.GetProfile);
};

const appGetUserProfileApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appGetUserProfile: build.query<IUserProfileOverview, void>({
      query: () => ({ url: apiEndPoints.app.GetProfile, method: "get" }),
      providesTags: [{ type: "appGetUserProfile", id: "appGetUserProfile" }]
    })
  })
});

export const { useAppGetUserProfileQuery, useLazyAppGetUserProfileQuery } = appGetUserProfileApiSlice;

export interface IUserProfileOverview {
  status: number;
  message: string;
  data: {
    _id: string;
    name: string;
    username: string;
    email: string;
    profilePicture?: string;
    cropProfilePicture?: string;
    bannerImage: string;
    createdAt: string;
    bio: string;
    id: string;
    walletAddress: string;
    // the balance of the user's wallet
    userWallet: number;
    links: {
      twitter: string;
      facebook: string;
      instagram: string;
      youtube: string;
      website: string;
    };
  };
}
