import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { IRootReducer } from "store/root-reducer";

const BannedNFTIcon = ({ isBanned }: any) => {
  const { isLoggedIn } = useSelector((state: IRootReducer) => state.auth);
  return isLoggedIn && isBanned ? (
    <div className="favorite-ic-block" role={"button"} onClick={() => {}} title="This NFTs is banned by the admin.">
      <FontAwesomeIcon icon={faEyeSlash} />
    </div>
  ) : (
    <></>
  );
};

export default BannedNFTIcon;
