export const SETLOADING = "SETLOADING";

export const SETUPLOAD = "SETUPLOAD";

export const SETCREATE = "SETCREATE";

export const MEDIALOADING = "MEDIALOADING";

export const TASKINPROGRESS = "TASKINPROGRESS";

export const TASKINPROGRESSALERT = "TASKINPROGRESSALERT";
