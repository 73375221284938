import { IGetGlobalSettingDataResponse } from "actions/app/get-global-setting";
import { localStorageKeys } from "utils/constants/constants";
import { getDecryptedLocalStorage } from "utils/functions/commonFunctions";
import { GlobalSettingType } from "./type";

const initialState: IGlobalSettingReducer = {
  requestDemo: getDecryptedLocalStorage(localStorageKeys.globalSetting)?.requestDemo,
  // if no storage found then it will be undefined and we are checking conditions in routing.tsx as per that
  underMaintenance: getDecryptedLocalStorage(localStorageKeys.globalSetting)?.underMaintenance,
  IsCustomNftDisabled: getDecryptedLocalStorage(localStorageKeys.globalSetting)?.customNft === 1 ? true : false,
  IsNftCollectionDisabled: getDecryptedLocalStorage(localStorageKeys.globalSetting)?.nftCollection === 1 ? true : false,
  _id: getDecryptedLocalStorage(localStorageKeys.globalSetting)?.underMaintenance || ""
};

const GlobalSettingReducer = (state = initialState, action: IAction) => {
  let newState = { ...state };

  const { type } = action;

  switch (type) {
    case GlobalSettingType.SET_GLOBAL_SETTING_OBJECT: {
      newState.requestDemo = action.payload.requestDemo === 1 ? true : false;
      newState.underMaintenance = action.payload.underMaintenance === 1 ? true : false;
      newState.IsCustomNftDisabled = action.payload.customNft === 1 ? true : false;
      newState.IsNftCollectionDisabled = action.payload.nftCollection === 1 ? true : false;
      newState._id = action.payload._id;
      break;
    }

    case GlobalSettingType.SET_GLOBAL_SETTING: {
      newState.requestDemo = action.payload.requestDemo === 1 ? true : false;
      break;
    }
  }

  return newState;
};

export { GlobalSettingReducer };

export interface IGlobalSettingReducer {
  requestDemo: undefined | boolean;
  underMaintenance: undefined | boolean;
  IsCustomNftDisabled: boolean;
  IsNftCollectionDisabled: boolean;
  _id: string;
}

export interface IAction {
  type: string;
  payload: IGetGlobalSettingDataResponse;
}
