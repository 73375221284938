import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const appCreateCollection = (data: FormData): Promise<ICollectionCreateResponse> => {
//   return HTTPService.post(apiEndPoints.app.createCollection, data);
// };
// export const appUpdateCollection = ({ data, collectionId }: { data: FormData; collectionId: string }): Promise<ICollectionCreateResponse> => {
//   return HTTPService.put(apiEndPoints.app.updateCollection + "/" + collectionId, data);
// };

const collectionApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    createCollection: build.mutation<ICollectionCreateResponse, FormData>({
      query: (data) => ({
        url: apiEndPoints.app.createCollection,
        method: "post",
        data: data
      })
    }),
    updateCollection: build.mutation<ICollectionCreateResponse, { data: FormData; collectionId: string }>({
      query: ({ data, collectionId }) => ({
        url: apiEndPoints.app.updateCollection + "/" + collectionId,
        method: "put",
        data: data
      })
    })
  })
});

export const { useCreateCollectionMutation, useUpdateCollectionMutation } = collectionApiSlice;

export interface ICollectionCreateRequest {
  logo: File;
  featured: File;
  banner: File;
  name: string;
  description: string;
  customUrl: string;
  categoryId: string;
  royalty: number;
  censorship: number;
  twitter: string;
  facebook: string;
  instagram: string;
  youtube: string;
  website: string;
}

export interface ICollectionCreateResponse {
  status: number;
  message: string;
}
