import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const getPricingPlanLIst = (): Promise<IPricingPlanListResult> => {
//   return HTTPService.get(apiEndPoints.PricingPlanList);
// };

export const pricingPlanApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    getPricingPlanLIst: build.query<IPricingPlanListResult, void>({
      query: () => ({ url: apiEndPoints.PricingPlanList, method: "get" })
    })
  })
});

export const { useGetPricingPlanLIstQuery, useLazyGetPricingPlanLIstQuery } = pricingPlanApiSlice;

export interface ISinglePricePlan {
  price: number;
  type: number;
  icon: string;
  status: string;
  minDiscount: number;
  _id: string;
  name: string;
  description: string;
  totalNFTs: number;
  minNFTs: number;
  maxNFTs: number;
  createdAt: Date;
  updatedAt: Date;
  id: string;
}
export interface IPricingPlanListResult {
  status: 200;
  message: string;
  data: ISinglePricePlan[];
}
