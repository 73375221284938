import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";
import HTTPService from "utils/httpService";

export const getTemplateListAPI = (params?: ITemplateListRequest): Promise<IGetTemplateListReturn> => {
  return HTTPService.get(apiEndPoints.app.getTemplateList, {
    params
  });
};

const appGetTemplateListlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    getTemplateListAPI: build.query<IGetTemplateListReturn, ITemplateListRequest>({
      query: (params) => ({
        url: apiEndPoints.app.getTemplateList,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "getTemplateListAPI", id: "getTemplateListAPI" }]
    })
  })
});

export const { useGetTemplateListAPIQuery, useLazyGetTemplateListAPIQuery } = appGetTemplateListlice;

export interface ITemplateListRequest {
  page?: number;
  limit?: number;
  search?: string;
  key?: string;
}

export interface ITemplateListData {
  description: string;
  filePath: string;
  id: string;
  title: string;
  visibility: number;
  _id: string;
}

export interface IGetTemplateListReturn {
  status: number;
  message: string;
  count: number;
  data: ITemplateListData[];
}
