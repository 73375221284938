import { COMPRESSED_FILE_URL, FILE_URL } from "./type";

export interface INormalFileAndCompressedFileReducer {
  orginalUrlOfFile: string;
  newUrlOfFile: string;
}
const initialState: INormalFileAndCompressedFileReducer = {
  orginalUrlOfFile: "",
  newUrlOfFile: ""
};

const normalFileAndCompressedFileReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case FILE_URL:
      return { ...state, orginalUrlOfFile: action.payload };

    case COMPRESSED_FILE_URL:
      return { ...state, newUrlOfFile: action.payload };

    default:
      return { ...state };
  }
};

export { normalFileAndCompressedFileReducer };
