import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";
import { paginationKeysObject } from "utils/constants/constants";

// export const getNftCategoryList = (params?: typeof paginationKeysObject): Promise<ICategoryListResult> => {
//   return HTTPService.get(apiEndPoints.categoryList, params);
// };

const nftCategoryApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    getNftCategoryList: build.query<ICategoryListResult, typeof paginationKeysObject | void>({
      query: (params?) => ({
        url: apiEndPoints.categoryList,
        params: params,
        method: "get"
      }),
      providesTags: [{ type: "getNftCategoryList", id: "getNftCategoryList" }]
    })
  })
});

export const { useGetNftCategoryListQuery, useLazyGetNftCategoryListQuery } = nftCategoryApiSlice;

export interface ISingleCategory {
  createdAt: string;
  highlight: boolean;
  name: string;
  nftCount: number;
  rank: number;
  _id: string;
}
export interface ICategoryListResult {
  status: number;
  message: string;
  count: number;
  totalNft: number;
  data: ISingleCategory[];
}
