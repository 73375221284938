import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Spinner } from 'reactstrap'
import { IRootReducer } from 'store/root-reducer'
import { handleTheLanguageTranslation } from 'utils/functions/commonFunctions'

/**
 * @remark
 * If this component do not receive enough data then button will not be rendered.
 *
 * @param {boolean} isLoading the data loading or not
 * @param {Function} loadMoreData function which will be called on button click
 * @param {number} dataCount of the whole data
 * @param {Array} listData list of data
 * @returns {HTMLButtonElement} Button with <button>Load More or spinner text</button>
 */

const GlobalLoadMoreButton = ({
  isLoading = false,
  loadMoreData = () => {},
  dataCount = 0,
  listData = [] as any[],
  classNames = ''
}) => {
  const label = useSelector(
    (state: IRootReducer) =>
      state.languagesJsonReducer.currentLanguage.json
  )
  return dataCount > listData.length ? (
    <Button
      className={`global-load-more-button custom-primary-outline  ${classNames}`}
      onClick={() => loadMoreData()}
    >
      {!isLoading ? (
        handleTheLanguageTranslation(label?.load_more, 'Load More')
      ) : (
        <div className="d-flex justify-content-center">
          <Spinner className="text-white" />
        </div>
      )}
    </Button>
  ) : (
    <>
      {listData.length > 0 && dataCount === listData?.length && (
        <>
          <p className="my-5 text-center c-tx-third w-100">
            No More Data found
          </p>
        </>
      )}
    </>
  )
}

export default React.memo(GlobalLoadMoreButton)
