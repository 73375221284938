import { MEDIALOADING, SETCREATE, SETLOADING, SETUPLOAD, TASKINPROGRESS, TASKINPROGRESSALERT } from "./type";

const initialState: ILoaderReducer = {
  isLoading: false,
  uploadNTF: false,
  createNTF: false,
  mediaLoading: true,
  taskInProgress: false,
  taskInProgressAlert: false
};

const loadingReducer = (state = initialState, action: { type: string; payload: boolean }) => {
  const newState = { ...state };
  switch (action.type) {
    case SETLOADING: {
      newState.isLoading = action.payload;
      break;
    }
    case SETUPLOAD: {
      newState.uploadNTF = action.payload;
      break;
    }
    case SETCREATE: {
      newState.createNTF = action.payload;
      break;
    }
    case MEDIALOADING:
      newState.mediaLoading = action.payload;
      break;
    case TASKINPROGRESS:
      newState.taskInProgress = action.payload;
      break;
    case TASKINPROGRESSALERT:
      newState.taskInProgressAlert = action.payload;
      break;
  }
  return newState;
};

export interface ILoaderReducer {
  isLoading: boolean;
  uploadNTF: boolean;
  createNTF: boolean;
  mediaLoading: boolean;
  taskInProgress: boolean;
  taskInProgressAlert: boolean;
}

export { loadingReducer };
