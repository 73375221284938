import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as Heartb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFavouriteUnFavoruite from "hooks/useFavouriteUnFavoruite";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFavouriteClick } from "store/favouriteClick/action";
import { IRootReducer } from "store/root-reducer";

const FavouriteIcon = ({ nftId = "", isFavourite = false, setLikeState }: { nftId: string | undefined; isFavourite: boolean | undefined; setLikeState?: any }) => {
  const { isLoggedIn } = useSelector((state: IRootReducer) => state.auth);
  const [favourite, toggleFavourite] = useFavouriteUnFavoruite(nftId, isFavourite);
  const dispatch = useDispatch();

  return isLoggedIn ? (
    <div
      className="favorite-ic-block"
      role={"button"}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (setLikeState) {
          setLikeState((current: any) => {
            if (current.isFavourite) {
              return {
                likes: current.likes - 1,
                isFavourite: !current.isFavourite
              };
            } else {
              return {
                likes: current.likes + 1,
                isFavourite: !current.isFavourite
              };
            }
          });
        } else {
          dispatch(setFavouriteClick());
        }
        toggleFavourite();
      }}>
      <h3 className={`mb-0 ${favourite ? "c-tx-primary" : ""}`}>{favourite === true ? <FontAwesomeIcon icon={Heartb} /> : <FontAwesomeIcon icon={faHeart} />}</h3>
    </div>
  ) : (
    <></>
  );
};

export default React.memo(FavouriteIcon);
