import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDeleteNftTemplateAPIMutation } from "actions/app/deleteNfttemplateAPI";
import { useLazyUpdateArchiveAPIQuery } from "actions/app/update-archive-templates";
import { makeFullS3ObjectUrl } from "assets/images";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from "reactstrap";
import { confirmationPopupAction, resetConfirmationPopupState } from "store/confimationPopup/action";
import { setLoading } from "store/loader/action";
import { IRootReducer } from "store/root-reducer";
import { fileSize } from "utils/constants/constants";
import { handleTheLanguageTranslation, toastSuccess } from "utils/functions/commonFunctions";
const tamplateImage = makeFullS3ObjectUrl("templateImage.png");

const CreateNFTListCard = ({ src, title, subTitle, id, getTemplate, ...rest }: any) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [imgLoad, setImgLoad] = useState(true);
  const [error, setError] = useState(false);

  const [deleteNftTemplateMutation] = useDeleteNftTemplateAPIMutation();
  const [updateArchiveQuery] = useLazyUpdateArchiveAPIQuery();

  function toggle() {
    setDropDownOpen(!dropDownOpen);
  }

  const updateArchiveAction = () => {
    if (id) {
      dispatch(setLoading(true));
      updateArchiveQuery({ id }).then((res) => {
        if ("data" in res && res.data && res.data.status === 200) {
          toastSuccess(res.data.message);
          getTemplate();
        }
      });
    }
  };

  // image can loaded that time loader is close
  const handleLoad = () => {
    setImgLoad(false);
  };

  const removeTemplateAction = () => {
    if (id) {
      dispatch(setLoading(true));
      deleteNftTemplateMutation({ id })
        .then((res) => {
          if ("data" in res && res.data.status === 200) {
            toastSuccess(res.data.message);
            getTemplate();
            dispatch(resetConfirmationPopupState());
          }
        })
        .catch((err) => dispatch(resetConfirmationPopupState()));
    }
  };

  const handleDeleteClick = () => {
    dispatch(
      confirmationPopupAction({
        isVisible: true,
        title: handleTheLanguageTranslation(label?.confirmation, "Confirmation"),
        paragraph: handleTheLanguageTranslation(label?.do_you_want_to_delete_this_template_permanently, "Do you want to Delete this template permanently?"),
        successButtonText: handleTheLanguageTranslation(label?.yes, "Yes"),
        cancelButtonText: handleTheLanguageTranslation(label?.no, "No"),
        actionFunction: removeTemplateAction
      })
    );
  };

  return (
    <div
      className={`upload-main-wrapper position-relative ${location?.pathname === "/archive-templates" ? "" : "cursor"}`}
      role={location?.pathname === "/archive-templates" ? "" : "button"}
      {...rest}>
      <div className="img-wrapper w-auto position-relative cypress-nft-upload w-100">
        {imgLoad && <Spinner className="text-center custom-img-loader" />}
        <img
          src={error ? tamplateImage : src ? src : tamplateImage}
          alt={title}
          className={`img-fluid ${location.pathname === "/create-nft" ? "" : "h-100"}`}
          onLoad={handleLoad}
          onError={() => {
            handleLoad();
            setError(true);
          }}
        />
      </div>
      <p id="test" className="c-tx-forth mb-0 text-truncate mw-100">
        {subTitle}
      </p>
      <div>
        <p className="c-tx-forth mb-0">{`${handleTheLanguageTranslation(label?.max_size, "Max size:")} ${fileSize["50mb"] / 1024 / 1024} MB!`}</p>
      </div>
      <h3 className="c-tx-fifth title f-700 text-truncate mw-100" title={title?.length > 19 ? title : null}>
        {title}
      </h3>
      <div className="menu-block">
        <Dropdown
          isOpen={dropDownOpen}
          toggle={(e: any) => {
            toggle();
          }}>
          {location?.pathname !== "/create-nft" && (
            <DropdownToggle
              onClick={(e) => {
                e.stopPropagation();
                toggle();
              }}
              className="custome-hover">
              <FontAwesomeIcon className="c-tx-forth" icon={faEllipsisV} />
            </DropdownToggle>
          )}
          <DropdownMenu>
            <DropdownItem
              onClick={(e) => {
                e.stopPropagation();
                updateArchiveAction();
              }}>
              {location.pathname === "/custom-nft" ? handleTheLanguageTranslation(label?.archive, "Archive") : handleTheLanguageTranslation(label?.unarchive, "Unarchive")}
            </DropdownItem>
            <DropdownItem divider style={{ margin: 0 }} />
            <DropdownItem
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick();
              }}>
              {handleTheLanguageTranslation(label?.delete, "Delete")}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

export default React.memo(CreateNFTListCard);
