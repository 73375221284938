export const mixerIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17.316" viewBox="0 0 18 17.316">
    <g id="Group_239" data-name="Group 239" transform="translate(-341 -341.342)">
      <path
        id="Path_320"
        data-name="Path 320"
        d="M341.4,344.927h3.167v1.4a.36.36,0,0,0,.36.36h2.333a.359.359,0,0,0,.36-.36V341.7a.36.36,0,0,0-.36-.36h-2.314a.36.36,0,0,0-.36.36v1.4H341.4a.4.4,0,0,0-.4.4v1.005a.406.406,0,0,0,.4.418Z"
        fill="#090909"
      />
      <path id="Path_321" data-name="Path 321" d="M358.619,343.106h-9.843v1.821H358.6a.4.4,0,0,0,.4-.4v-1.005a.38.38,0,0,0-.38-.417Z" fill="#090909" />
      <path id="Path_322" data-name="Path 322" d="M358.619,349.1h-3.546v1.821H358.6a.4.4,0,0,0,.4-.4v-1.005a.38.38,0,0,0-.38-.417Z" fill="#090909" />
      <path
        id="Path_323"
        data-name="Path 323"
        d="M341.4,350.92h9.463v1.4a.361.361,0,0,0,.361.361h2.333a.361.361,0,0,0,.36-.361V347.7a.361.361,0,0,0-.361-.361h-2.313a.361.361,0,0,0-.361.361v1.4H341.4a.4.4,0,0,0-.4.4V350.5a.405.405,0,0,0,.4.417Z"
        fill="#090909"
      />
      <path id="Path_324" data-name="Path 324" d="M358.619,355.092h-9.843v1.821H358.6a.4.4,0,0,0,.4-.4v-1.006a.39.39,0,0,0-.38-.417Z" fill="#090909" />
      <path
        id="Path_325"
        data-name="Path 325"
        d="M347.259,353.328h-2.314a.36.36,0,0,0-.36.361v1.4H341.4a.4.4,0,0,0-.4.4V356.5a.4.4,0,0,0,.4.4h3.168v1.4a.36.36,0,0,0,.36.36h2.333a.36.36,0,0,0,.36-.36v-4.609a.349.349,0,0,0-.36-.361Z"
        fill="#090909"
      />
    </g>
  </svg>
);
