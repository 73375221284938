import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { IPaymentFormikValues } from "components/payment/ChoosePaymentOptions";
import { FormikProps } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import { FormFeedback, FormGroup, Input } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { handleTheLanguageTranslation } from "utils/functions/commonFunctions";

export const StripePaymentCardUI = <T extends FormikProps<IPaymentFormikValues>>({ paymentFormik }: { paymentFormik: T }) => {
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  // if user is a investor
  const { values, setFieldValue, errors, touched } = paymentFormik || {};
  const [paymentCardErrors, setPaymentCardErrors] = useState({
    cardNumber: "",
    cardExpiry: "",
    cardCvv: ""
  });
  const inputStyle = {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "36px",
    padding: "0 20px",
    height: "63px !important",
    fontFamily: "Outfit, sans-serif"
  };

  return (
    <div>
      <FormGroup className="position-relative form-input-mb" id="cypress-iframe-card-number">
        <div className="form-control" style={{ paddingTop: 12 }}>
          <CardNumberElement
            onChange={(e) => {
              setPaymentCardErrors((prevValue) => ({
                ...prevValue,
                cardNumber: e.error?.message || ""
              }));
            }}
            options={{
              style: {
                base: inputStyle
              },
              showIcon: true,
              placeholder: handleTheLanguageTranslation(label?.card_number, "Card number"),
              classes: {
                base: "cypress-card-number"
              }
            }}
          />
        </div>
      </FormGroup>

      <div className="d-flex justify-content-between align-items-start">
        <FormGroup className="position-relative form-input-mb" style={{ width: "49%" }} id="cypress-iframe-card-expiry">
          <div className="form-control" style={{ paddingTop: 12 }}>
            <CardExpiryElement
              onChange={(e) => {
                setPaymentCardErrors((prevValue) => ({
                  ...prevValue,
                  cardExpiry: e.error?.message || ""
                }));
              }}
              options={{
                style: {
                  base: inputStyle
                },
                placeholder: handleTheLanguageTranslation(label?.mm_yy, "MM/YY"),
                classes: {
                  base: "cypress-card-expiry"
                }
              }}
            />
          </div>
          <FormFeedback
            style={{
              display: Boolean(paymentCardErrors.cardExpiry) ? "block" : "none"
            }}>
            {paymentCardErrors.cardExpiry}
          </FormFeedback>
        </FormGroup>

        <FormGroup className="position-relative form-input-mb" style={{ width: "49%" }} id="cypress-iframe-card-cvv">
          <div className="form-control" style={{ paddingTop: 12 }}>
            <CardCvcElement
              onChange={(e) => {
                setPaymentCardErrors((prevValue) => ({
                  ...prevValue,
                  cardCvv: e.error?.message || ""
                }));
              }}
              options={{
                style: {
                  base: inputStyle
                },
                placeholder: handleTheLanguageTranslation(label?.cvv, "CVV"),
                classes: {
                  base: "cypress-card-cvv"
                }
              }}
            />
          </div>
        </FormGroup>
      </div>
      <FormGroup className="position-relative form-input-mb">
        <Input
          id="cypress-carholder-name"
          name="nameOnCard"
          placeholder={handleTheLanguageTranslation(label?.name_on_card, "Name on Card")}
          type="text"
          onChange={(e) => {
            if (/^[a-zA-Z ]+$/g.test(e.target.value) || e.target.value === "") {
              setFieldValue("nameOnCard", e.target.value.replace(/  +/g, " "));
            }
          }}
          value={values.nameOnCard}
        />
        <FormFeedback
          style={{
            display: Boolean(touched?.nameOnCard) && Boolean(errors?.nameOnCard) ? "block" : "none"
          }}>
          {errors.nameOnCard && errors.nameOnCard}
        </FormFeedback>
      </FormGroup>
    </div>
  );
};
