import { useSendRequestDemoAPIMutation } from "actions/app/requestDemo";
import { makeFullS3ObjectUrl } from "assets/images";
import { CloseButtonSvg } from "assets/images/close-button";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Form, FormFeedback, FormGroup, Input, Modal, ModalBody, Spinner } from "reactstrap";
import { setRequestDemoModal } from "store/requestDemoModal/action";
import { IRootReducer } from "store/root-reducer";
import { handleTheLanguageTranslation, toggleClassNameToRoot } from "utils/functions/commonFunctions";
import * as Yup from "yup";
import "../../styles/components/DemoRequest.scss";
const Success = makeFullS3ObjectUrl("thank_uSuccess.svg");

const DemoRequestPopup = () => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state: IRootReducer) => state.requestDemoMdoalReducer);
  const label = useSelector((state: IRootReducer) => state.languagesJsonReducer.currentLanguage.json);
  const [renderState, setRenderState] = useState(2);
  const [requestDemoMutation] = useSendRequestDemoAPIMutation();

  useEffect(() => {
    if (!isOpen) {
      setRenderState(0);
    } else {
      setRenderState(1);
    }
  }, [isOpen]);

  const requestDemoForm = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      email: "",
      description: "",
      reference: "",
      others: ""
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .max(20, handleTheLanguageTranslation(label?.full_name_must, "Full name must be lesser than 20 characters"))
        .required(handleTheLanguageTranslation(label?.full_name_is_required, "Full name is required")),
      // eslint-disable-next-line
      phoneNumber: Yup.string()
        .max(20, handleTheLanguageTranslation(label?.phone_number_must, "Phone number must be lesser than 21 characters"))
        // eslint-disable-next-line no-useless-escape
        .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g, handleTheLanguageTranslation(label?.please_enter_valid_phone, "Please enter valid phone number"))
        .required(handleTheLanguageTranslation(label?.phone_number_is_required, "Phone number is required")),
      email: Yup.string()
        .email(handleTheLanguageTranslation(label?.please_provide_valid_email, "Please provide valid email"))
        .required(handleTheLanguageTranslation(label?.email_is_required, "Email is required"))
        .max(30, handleTheLanguageTranslation(label?.email_must, "Email must be lesser than 30 characters")),
      description: Yup.string()
        .required(handleTheLanguageTranslation(label?.description_is_required, "Description is required"))
        .max(179, handleTheLanguageTranslation(label?.description_must, "Description must be lesser than 180 characters"))
        .min(5, "Description must be of minimum 5 chracters"),
      reference: Yup.string().required("Reference is required"),
      others: Yup.string().when("reference", {
        is: (reference: any) => reference === "other_website",
        then: Yup.string().required("Others is required").max(101, "Full name must be lesser than 21 characters"),
        otherwise: Yup.string()
      })
    }),
    onSubmit: async (values) => {
      let data: any = {
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        description: values.description,
        reference: values.reference === "other_website" ? values.others : values.reference
      };
      requestDemoMutation(data).then((res) => {
        if ("data" in res && res.data && res.data.status === 200) {
          // show thank you component
          setRenderState(2);
          requestDemoForm.resetForm();
        }
      });
    }
  });

  const handlecategory = (value: string) => {
    const optionOfDropDown = options.find((data: any) => data.value === value);
    return optionOfDropDown;
  };

  const { values, setFieldValue, errors, touched, handleSubmit, handleChange } = requestDemoForm;

  const options = [
    { label: "Facebook", value: "facebook" },
    { label: "Google", value: "google" },
    { label: "Instagram", value: "instagram" },
    {
      label: handleTheLanguageTranslation(label?.other, "Other"),
      value: "other_website"
    }
  ];

  useEffect(() => {
    toggleClassNameToRoot(isOpen);
  }, [isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} centered className={`requestdemo-pop modal-features complete-modal ${renderState === 2 && "border-mk"}`}>
        <ModalBody className="text-center">
          <div
            className="close-btn"
            onClick={() => {
              dispatch(setRequestDemoModal(false));
              requestDemoForm.resetForm();
            }}>
            {CloseButtonSvg}
          </div>
          {renderState === 1 && (
            <Form onSubmit={handleSubmit}>
              <h2 className="c-tx-secondary f-700 title-lr mb-0">{handleTheLanguageTranslation(label?.request_for_a_demo, "Request for a Demo")}</h2>
              <div id="modal-inner-all-fields" className="content metaData-overFollow modal-inner-fields">
                <div className="meta-container">
                  <FormGroup className="position-relative form-input-mb input-container error-red">
                    <Input
                      placeholder={`${handleTheLanguageTranslation(label?.full_name, "Full name")} *`}
                      autoComplete="OFF"
                      invalid={touched.fullName ? (errors.fullName ? true : false) : false}
                      onChange={handleChange}
                      name="fullName"
                      maxLength={19}
                      className={`metaData-heigth c-tx-secondary f-700 request-demo-input${touched.fullName && errors.fullName && "red-border-requestDemo"}`}
                    />
                    {requestDemoForm.touched.fullName && requestDemoForm.errors.fullName && <FormFeedback className="error-msg">{requestDemoForm.errors.fullName}</FormFeedback>}
                  </FormGroup>
                  <FormGroup className="position-relative form-input-mb input-container">
                    <Input
                      type="text"
                      autoComplete="OFF"
                      invalid={touched.phoneNumber ? (errors.phoneNumber ? true : false) : false}
                      placeholder={`${handleTheLanguageTranslation(label?.phone_number, "Phone number")} *`}
                      name="phoneNumber"
                      maxLength={20}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className={`metaData-heigth c-tx-secondary f-700 request-demo-input ${touched.phoneNumber && errors.phoneNumber && "red-border-requestDemo"}`}
                      onChange={handleChange}
                    />
                    {requestDemoForm.touched.phoneNumber && requestDemoForm.errors.phoneNumber && <FormFeedback className="error-msg">{requestDemoForm.errors.phoneNumber}</FormFeedback>}
                  </FormGroup>
                </div>
                <div>
                  <FormGroup className="position-relative form-input-mb input-container">
                    <Input
                      type="text"
                      placeholder={`${handleTheLanguageTranslation(label?.email, "Email")} *`}
                      name="email"
                      maxLength={30}
                      invalid={touched.email ? (errors.email ? true : false) : false}
                      autoComplete="OFF"
                      className={`metaData-heigth c-tx-secondary f-700 ${touched.email && errors.email && "red-border-requestDemo"}`}
                      onChange={handleChange}
                    />
                    {requestDemoForm.touched.email && requestDemoForm.errors.email && <FormFeedback className="error-msg">{requestDemoForm.errors.email}</FormFeedback>}
                  </FormGroup>
                </div>
                <div>
                  <FormGroup className="position-relative form-input-mb input-container">
                    <Input
                      type="textarea"
                      autoComplete="OFF"
                      name="description"
                      placeholder={`${handleTheLanguageTranslation(label?.description, "Description")} *`}
                      rows="5"
                      maxLength={180}
                      className={`${touched.description && errors.description && "red-border-requestDemo"} c-tx-secondary f-700`}
                      invalid={touched.description ? (errors.description ? true : false) : false}
                      onChange={handleChange}
                    />
                    {requestDemoForm.touched.description && requestDemoForm.errors.description && <FormFeedback className="error-msg">{requestDemoForm.errors.description}</FormFeedback>}
                  </FormGroup>
                </div>
                <div>
                  <FormGroup className="max-category-menu">
                    <Select
                      className={`${touched.reference && errors.reference && "error-append"} text-custom cursor-select`}
                      placeholder={`${handleTheLanguageTranslation(label?.how_do_you_hear_from_us, "How do you hear from us?")} *`}
                      // @ts-ignore
                      menuPlacement="top"
                      options={options}
                      isSearchable={false}
                      classNamePrefix="demo-popup"
                      // @ts-ignore
                      value={handlecategory(values.reference)}
                      onChange={(e: any) => {
                        setFieldValue("reference", e.value);
                      }}
                    />
                    {touched.reference && errors.reference && <p className="error-msg mb-0">{errors?.reference && handleTheLanguageTranslation(label?.reference_is_required, errors.reference)}</p>}
                  </FormGroup>
                </div>

                {values?.reference === "other_website" ? (
                  <div>
                    <FormGroup className="position-relative form-input-mb input-container">
                      <Input
                        type="text"
                        placeholder={`${handleTheLanguageTranslation(label?.add_other_information, "Add other information")} *`}
                        value={values.others}
                        name="others"
                        maxLength={60}
                        autoComplete="OFF"
                        invalid={touched.others ? (errors.others ? true : false) : false}
                        className={`metaData-heigth c-tx-secondary f-700 request-demo-email-field  ${requestDemoForm.touched.others && requestDemoForm.errors.others && "red-border-requestDemo"}`}
                        onChange={handleChange}
                      />
                      <FormFeedback className="error-msg">{errors?.others && handleTheLanguageTranslation(label?.others_is_required, errors.others)}</FormFeedback>
                    </FormGroup>
                  </div>
                ) : null}
              </div>
              <div className="d-flex justify-content-end">
                <Button className="metaData-btn hover-stop request-demo-btns custom-primary" type="submit">
                  {handleTheLanguageTranslation(label?.send_request, "Send Request")}
                </Button>
              </div>
            </Form>
          )}

          {renderState === 2 && (
            <>
              <img src={Success} alt="success" className="mt-5" width={100} height={100} />
              <div className="text-center mt-4">
                <h2 className="c-tx-secondary f-700 title-lr mb-0">{handleTheLanguageTranslation(label?.thank_you, "Thank you")}</h2>
              </div>
              <div className="fs-6 c-tx-third mt-4">
                {handleTheLanguageTranslation(label?.welcome_home_p_7, "Great, We successfully received your request for the demo. Our admin will contact you soon.")}
              </div>
              <div className="c-tx-forth mt-5">
                {handleTheLanguageTranslation(label?.for_further_inquiries, "for further inquiries, please contact ")}
                <a href="mailto:helpdesk@edexa.com">helpdesk@edexa.com</a>
              </div>
            </>
          )}

          {renderState === 0 && (
            <div>
              <Spinner />
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default DemoRequestPopup;
