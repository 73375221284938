import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const UpdateCustomDraftNftAPI = (data: FormData): Promise<IGetDraftNFTReturn> => {
//   return HTTPService.put(apiEndPoints.app.updateCustomDraft, data);
// };

const appShiftNftApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    UpdateCustomDraftNftAPI: build.mutation<IGetDraftNFTReturn, FormData>({
      query: (data) => ({
        url: apiEndPoints.app.updateCustomDraft,
        data: data,
        method: "put"
      })
    })
  })
});

export const { useUpdateCustomDraftNftAPIMutation } = appShiftNftApiSlice;

export interface tags {
  trait_type: string;
  value: string;
}

export interface paramsUpdateDraft {
  id: string;
  title?: string;
  description?: string;
  category?: string;
  tags?: string[];
  attributes: tags[];
}
export interface getDraftNft {
  title: string;
  description: string;
  price: number;
  visibility: number;
  tags: string[];
  _id: string;
  createdBy: string;
  filePath: string;
  categoryId: string;
  fileType: string;
  id: string;
}

export interface IGetDraftNFTReturn {
  status: number;
  message: string;
  data: getDraftNft;
}
