import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const appExploreNFTs = (params: IExploreNftsRequest): Promise<IExploreNftsReturn> => {
//   return HTTPService.get(apiEndPoints.app.ExploreNfts, { params });
// };

const exploreNftsApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    appExploreNFTs: build.query<IExploreNftsReturn, IExploreNftsRequest>({
      query: (params) => ({
        url: apiEndPoints.app.ExploreNfts,
        method: "get",
        params: params
      }),
      providesTags: [{ type: "appExploreNFTs", id: "appExploreNFTs" }]
    })
  }),
  overrideExisting: false
});

export const { useAppExploreNFTsQuery, useLazyAppExploreNFTsQuery } = exploreNftsApiSlice;

export interface IExploreNftsRequest {
  page: number;
  limit: number;
  search: string;
}

export interface INFT {
  auction: number;
  createdBy: { _id: string; username: string; id: string };
  description: string;
  compressedFilePath: string;
  filePath: string;
  fileThumbPath: string;
  fileMimeType: string;
  gateway: number;
  isCustom: number;
  id: string;
  nftId: number;
  price: number;
  title: string;
  tokenId: number;
  isLiked: boolean;
  totalViews: number;
  totalLikes: number;
  createdAt: string;
  videoDuration: number;
  _id: string;
}

export interface IExploreNftsReturn {
  status: number;
  message: string;
  count: number;
  data: INFT[];
}
