import { ISLOGGEDIN, LOGOUT, ONUSERLOGOUTANDREDIRECT } from "./type";

export const onUserLogIN = () => {
  return { type: ISLOGGEDIN };
};

/**
 *
 * @returns Clear all the session storage and cookies. And user gets redirected to login page.
 */
export const onUserLogoutAndRedirect = () => {
  return { type: ONUSERLOGOUTANDREDIRECT };
};

/**
 *
 * @returns user will be logged out, all the cookies and session storage will be cleared
 */
export const onUserLogout = () => {
  return { type: LOGOUT };
};
