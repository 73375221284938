import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

// export const getSearchAPI = (params: ISearchParams): Promise<ISearchReturn> => {
//   return HTTPService.get(apiEndPoints.app.search, { params });
// };

const getSearchApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    getSearchApi: build.query<ISearchReturn, ISearchParams>({
      query: (params) => ({
        url: apiEndPoints.app.search,
        params: params,
        method: "get"
      }),
      providesTags: [{ type: "getSearchApi", id: "getSearchApi" }]
    })
  })
});

export const { useGetSearchApiQuery, useLazyGetSearchApiQuery } = getSearchApiSlice;

export interface ISearchParams {
  search: string;
}

export interface IsearchNfts {
  fileThumbPath: string;
  nftId: number;
  title: string;
  totalLikes: number;
  totalViews: number;
  username: string;
  _id: string;
}

export interface ISearchUsers {
  email: string;
  name: string;
  profilePicture?: string;
  cropProfilePicture?: string;
  username: string;
  _id: string;
}

export interface ISearchReturn {
  status: number;
  message: string;
  count: number;
  nfts: IsearchNfts[];
  users: [];
}
