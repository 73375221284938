import { nftCentralRTQApiService } from "store";
import { apiEndPoints } from "utils/constants";

const getWalletTransactionsApiSlice = nftCentralRTQApiService.injectEndpoints({
  endpoints: (build) => ({
    getWalletTransactions: build.query<ICustomNft, IWalletTransactionsQueryParams>({
      query: (data) => ({
        url: apiEndPoints.wallet.Transactions,
        method: "get",
        params: data
      })
    })
  })
});

export const { useGetWalletTransactionsQuery, useLazyGetWalletTransactionsQuery } = getWalletTransactionsApiSlice;

export enum TRANSACTION_TYPE {
  CREDIT = "credit",
  DEBIT = "debit"
}
interface IWalletTransactionsQueryParams {
  page: number;
  limit: number;
  search?: TRANSACTION_TYPE | string;
  type?: TRANSACTION_TYPE | string;
}

export interface ICreateCustomNftResponse {}

export interface IWalletTransaction {
  createdAt: string;
  planId: string;
  price: number;
  purchaseFrom: string;
  purchasePlanType: string;
  status: string;
  transactionId: string;
  type: TRANSACTION_TYPE;
  userId: string;
  _id: string;
}
export interface ICustomNft {
  status: number;
  message: string;
  data: {
    userWallet: number;
    count: number;
    transactionData: IWalletTransaction[];
  };
}
