import { IUser } from "actions/auth/user-info";
import { localStorageKeys } from "utils/constants/constants";
import { getDecryptedLocalStorage } from "utils/functions/commonFunctions";
import { DEVICEID, NOTIFICATION_ON, SET_IS_ACCESS, SET_WALLETADDRESS, USERDATA, USERIDANDNAME } from "./type";

const userData = getDecryptedLocalStorage(localStorageKeys.isLoggedIN);

const initialState: IUserDetailsReducer = {
  user: getDecryptedLocalStorage(localStorageKeys.isLoggedIN),
  deviceId: "",
  user_id: getDecryptedLocalStorage(localStorageKeys.user_id) || "",
  username: getDecryptedLocalStorage(localStorageKeys.username) || "",
  isAccess: (userData && userData.isAccess) || 0,
  walletAddress: getDecryptedLocalStorage(localStorageKeys.wallet) || ""
};

const userDetailsReducer = (
  state = initialState,
  action: {
    type: String;
    payload: any;
  }
) => {
  const newState = {
    ...state
  };
  switch (action.type) {
    case USERDATA:
      newState.user = action.payload;
      if (action.payload?.isAccess) {
        newState.isAccess = action.payload.isAccess;
      }
      break;
    case NOTIFICATION_ON:
      // newState.user.notification = action.payload;
      break;
    case DEVICEID:
      newState.deviceId = action.payload;
      break;
    case USERIDANDNAME:
      newState.user_id = action.payload.userid;
      newState.username = action.payload.username;
      break;
    case SET_IS_ACCESS:
      newState.isAccess = action.payload;
      break;
    case SET_WALLETADDRESS:
      newState.walletAddress = action.payload;
      break;
  }
  return newState;
};

export default userDetailsReducer;

export interface IUserDetailsReducer {
  user: IUser;
  deviceId: string;
  user_id: string;
  username: string;
  isAccess: number;
  walletAddress: string;
}
