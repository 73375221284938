import { makeFullS3ObjectUrl } from "assets/images";
import useThreeJsCubeAnimation from "hooks/useThreeJsCubeAnimation";
import React from "react";
import "../styles/components/CubeAnimation.scss";
import "../utils/cube.js";
const AnimationPlaceholder = makeFullS3ObjectUrl("animationDemo.png");

const CubeAnimation = () => {
  useThreeJsCubeAnimation();
  return (
    <React.Fragment>
      <div id="loading">
        <img src={AnimationPlaceholder} className="img-fluid" alt="nft animation" />
      </div>
      <div id="container" className="pe-none"></div>
    </React.Fragment>
  );
};

export default CubeAnimation;
