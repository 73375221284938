import { IFavouriteCountReducer } from "./reducer";
import { SETFAVOURITECLICK, SETFAVOURITECOUNT } from "./type";

export const setFavouriteCount = (payload: IFavouriteCountReducer) => {
  return { type: SETFAVOURITECOUNT, payload };
};

export const setFavouriteClick = () => {
  return { type: SETFAVOURITECLICK };
};
