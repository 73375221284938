import { SET_REUQEST_DEMO_MODAL } from "./type";

const initialState: IRequestDemoMdoalReducer = {
  isOpen: false
};

const RequestDemoMdoalReducer = (state = initialState, action: { type: string; payload: boolean }) => {
  let newState = { ...state };
  switch (action.type) {
    case SET_REUQEST_DEMO_MODAL:
      newState.isOpen = action.payload;
      break;
  }
  return newState;
};

export { RequestDemoMdoalReducer };

export interface IRequestDemoMdoalReducer {
  isOpen: boolean;
}
